import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import InfoIcon from '@mui/icons-material/Info'
import { Stack, SxProps, Typography } from '@mui/material'
import { ReactNode } from 'react'

type InformationType = 'important' | 'info' | 'complete'

interface Props {
  children: ReactNode
  sx?: SxProps
  type: InformationType
}

const getIconByType = (type: InformationType) => {
  switch (type) {
    case 'important':
      return <ErrorIcon color="error" sx={{ marginRight: 1 }}/>
    case 'info':
      return <InfoIcon color="info" sx={{ marginRight: 1 }}/>
    case 'complete':
      return <CheckOutlinedIcon color="success" sx={{ marginRight: 1 }}/>
    default:
      return <InfoIcon color="info" sx={{ marginRight: 1 }}/>
  }
}

const FormInformation = ({ children, sx, type }: Props) => <Stack direction="row" sx={{ ...sx }} alignItems="center">
  {getIconByType(type)}
  <Typography alignItems="center" display="flex" variant="body1">{children}</Typography>
</Stack>

export default FormInformation
