import LoginIcon from '@mui/icons-material/Login'
import { Stack, CircularProgress, Typography, Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useConfirmationData, useUserSession } from '../../hooks'

const successComponent = (goToLogin: () => void) => {
  return (
    <Box>
      <Typography variant='h3' textAlign="center">Your email has been confirmed</Typography>
      <Typography variant='body2' textAlign="center">You can now sign in using your credentials.</Typography>
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
        <Typography variant='body2' sx={{ mt: 2 }}>
          Click the button to go to login page
        </Typography>
        <Button
          endIcon={<LoginIcon />}
          onClick={goToLogin}
          variant='contained'
          sx={{ textTransform: 'none', mt: 3, mb: 10 }}
        >
          Go to login
        </Button>
      </Box>
    </Box>
  )
}

const failureComponent = (goToLogin: () => void) => {
  return (
    <Box>
      <Typography variant='h3'>Somehing went wrong</Typography>
      <Typography variant='body2' textAlign="center">Your email has not been confirmed. Please contact administration.</Typography>
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
        <Typography variant='body2' sx={{ mt: 2 }}>
          Click the button to go to login page
        </Typography>
        <Button
          onClick={goToLogin}
          variant='contained'
          sx={{ textTransform: 'none', mt: 3, mb: 10 }}
        >
          Go to login
        </Button>
      </Box>
    </Box>
  )
}

function EmailConfirmation () {
  const { userId, token } = useConfirmationData()
  const [isLoading, setIsLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(true)
  const { confirmEmail } = useUserSession()
  const navigate = useNavigate()

  const tryConfirmEmail = async () => {
    if (!userId || !token) {
      setIsSuccess(false)
      // TODO: display message and button to go to login to login
    } else {
      const result = await confirmEmail(userId, token)

      if (result.success) {
        setIsSuccess(true)
      } else {
        setIsSuccess(false)
      // TODO: display message and button to go to login to login
      }
    }

    setIsLoading(false)
  }

  const goToLogin = () => {
    navigate('/login')
  }

  useEffect(() => {
    tryConfirmEmail()
  }, [])

  const getAfterConfirmationComponent = () => {
    return isSuccess ? successComponent(goToLogin) : failureComponent(goToLogin)
  }

  return (
    <Stack direction="column" sx={{
      maxWidth: '560px',
      width: '70%',
      height: 'calc(100vh - 70px)',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      { isLoading ? <CircularProgress /> : getAfterConfirmationComponent() }
    </Stack>
  )
}

export default EmailConfirmation
