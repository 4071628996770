import { createQueryHook } from '../../createQueryHook'
import { AppConfigurationUserProfile } from '../../types'

interface Response {
  currentUser: AppConfigurationUserProfile
}

export default createQueryHook<undefined, Response>({
  getKey: () => ['abp/application-configuration?includeLocalizationResources=false'],
  getEndpoint: () => 'abp/application-configuration?includeLocalizationResources=false'
})
