import { createQueryHook } from '../../createQueryHook'

type Props = {
  id: number;
};

export default createQueryHook<Props, string>({
  getKey: ({ id }) => [`app/decision-letter/${id}`],
  getEndpoint: ({ id }) => `app/decision-letter/${id}`
})
