import { Tab, TabProps } from '@mui/material'
import { LinkProps } from 'react-router-dom'

const NavTab = (props: TabProps & LinkProps) => (
  <Tab
    sx={{
      color: 'text.primary',
      textTransform: 'uppercase',
      overflow: 'visible',

      '&.MuiTab-root': {
        fontSize: 'navLink.fontSize',
        letterSpacing: 1,
        fontWeight: 'navLink.fontWeight',
        p: 0,
        mx: 2,
        minWidth: 0,
        minHeight: 20
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        height: '2px',
        bgcolor: 'white',
        bottom: -5,
        left: 0,
        right: 0,
        transition: 'opacity .2s',
        opacity: 0
      },

      '&:hover::after': {
        opacity: 1
      }
    }}
    {...props}
  />
)

export default NavTab
