import { createMutationHook } from '../../createMutationHook'

export type Response = {
  result: number,
  description: string
};

export default createMutationHook<{
  userNameOrEmailAddress: string,
  password: string,
  rememberMe: boolean,
  tenanId: string,
}, Response>({
  httpMethod: 'POST',
  getEndpoint: () => 'account/login',
  getApiClientConfig: (payload) => {
    return {
      data: payload
    }
  }
})
