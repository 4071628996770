import { createMutationHook } from '../../createMutationHook'
import { ApplicationForm, FormSummary } from '../../types'

export default createMutationHook<ApplicationForm, FormSummary>({
  httpMethod: 'POST',
  getEndpoint: () => 'app/application-forms/validate-application-form',
  getApiClientConfig: (payload) => {
    return {
      data: payload
    }
  }
})
