import { createMutationHook } from '../../createMutationHook'
import { ChangePasswordPayload } from '../../types'

export default createMutationHook<ChangePasswordPayload, null>({
  httpMethod: 'POST',
  getEndpoint: () => 'account/my-profile/change-password',
  getApiClientConfig: (payload) => {
    return {
      data: payload
    }
  }
})
