import {
  QueryClient,
  QueryFunctionContext,
  QueryKey,
  UseQueryOptions,
  useQuery
} from '@tanstack/react-query'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { z } from 'zod'

import { api } from './api'
import { queryClient } from './queryClient'
import { HttpRequestMethods } from './types'

type DeepReadonly<T> = {
  readonly [K in keyof T]: T[K] extends Record<PropertyKey, unknown>
    ? DeepReadonly<T[K]>
    : T[K];
};

type CreateQueryHookProps<Props, ReturnType> = {
  /**
   * Function that returns a React Query key that typically starts with a unique query ID
   * @example
   * (props) => ['someQueryName', props.appId, props.language]
   */
  getKey: (props: Props) => QueryKey;
  /**
   * Function that returns the endpoint called by the API
   * @example
   * (props) => `/document/${props.documentId}`
   */
  getEndpoint: (props: Props) => string;
  /**
   * Optional function that returns an Axios config where you can set params, data, or any
   * other Axios config option
   * @example
   * (context, props) => ({
   *   params: {
   *     project_id: props.projectId,
   *     app_name: props.appId,
   *   }
   * })
   */
  getApiClientConfig?: (
    context: Omit<QueryFunctionContext, 'signal'>,
    props: Props,
  ) => AxiosRequestConfig;
  /**
   * Optional function that provides a queryClient and returns React Query options that are specific to this hook
   * @example
   * (queryClient) => ({
   *   onSettled: (_data, _err, { documentId, projectId, appId }) => {
   *     queryClient.invalidateQueries(
   *       useFetchDocument.getKey(documentId, projectId, appId)
   *     );
   *   },
   * }),
   */
  getQueryOptions?: (
    queryClient: QueryClient,
  ) => Omit<UseQueryOptions<DeepReadonly<ReturnType>, AxiosError>, 'queryKey'>;

  /**
   * Optional value that determines the HTTP method. Defaults to `"GET"`
   */
  httpMethod?: HttpRequestMethods;
  /**
   * Optional function that lets you defined a mock response for unfinished API endpoints
   */
  mockResponse?: ReturnType;

  validationSchema?: z.ZodTypeAny;
  isPaginated?: boolean;
};

/**
 * Create a beautiful React Query hook with minimal repetition.
 * The helper function takes two types:
 * @typedef HookProps The type for the props that will be passed into the hook
 * @typedef ReturnType The type of data that is returned by the API endpoint
 * @example
 * type FetchSomethingProps = { name: string, userId: number };
 * const useFetchSomething = createQueryHook<FetchSomethingProps, string>({
 *   getKey: (props) => ['something', props.name, props.userId],
 *   getEndpoint: (props) => `/something/${props.name}`,
 *   getApiClientConfig: (context, props) => ({ params: { user_id: props.userId }  }),
 *   getQueryOptions: () => ({
 *     onSuccess: () => { console.log("Hooray!") }
 *   }),
 *   httpMethod: "POST",
 *   excludeAuthToken: false
 * });
 * ...
 * const name = 'Owen';
 * const userId = 123;
 * const { data: something } = useFetchSomething({ name, userId });
 * ...
 * const fetchSomethingKey = useFetchSomething.getKey({ name, userId });
 * const fetchSomethingEndpoint = useFetchSomething.getEndpoint({ name, userId });
 * const fetchSomethingHttpMethod = useFetchSomething.httpMethod;
 * @returns Hook Function
 */
export function createQueryHook<
  Props extends void | {},
  ReturnType extends {} | unknown | null = unknown,
> ({
  getKey,
  getEndpoint,
  getApiClientConfig,
  getQueryOptions,
  httpMethod = 'GET',
  mockResponse,
  validationSchema,
  isPaginated
}: CreateQueryHookProps<Props, ReturnType>) {
  type QueryOptionsChild = {
    queryOptions?: UseQueryOptions<DeepReadonly<ReturnType>, AxiosError>;
  };

  type HookFnProps = [Props] extends [void]
    ? QueryOptionsChild | void
    : Props & QueryOptionsChild;

  const apiMethod = httpMethod.toLowerCase() as Lowercase<typeof httpMethod>

  const getQuery = (props: HookFnProps) => {
    const query: UseQueryOptions<DeepReadonly<ReturnType>, AxiosError> = {
      queryKey: getKey(props as Props),
      queryFn: async (context) => {
        const apiClientConfig = getApiClientConfig?.(context, props as Props)

        if (mockResponse) {
          return mockResponse
        }

        const response = await api({
          method: apiMethod,
          url: getEndpoint(props as Props),
          signal: context.signal,
          ...apiClientConfig
        })

        try {
          if (validationSchema) {
            if (isPaginated) {
              const dataWithHeaders = Object.assign(response.data, {
                _headers: response.headers
              })
              return dataWithHeaders
            }
            return validationSchema.parse(response.data)
          }
        } catch (e) {
          let errorKey = getKey(props as Props)

          if (typeof errorKey?.[0] === 'object') {
            errorKey = errorKey[0] as QueryKey
          }

          console.error(
            'Query Validation Error\nQueryKey:',
            errorKey,
            `\nError Details: ${e}`
          )
        }

        return response.data
      },
      ...getQueryOptions?.(queryClient),
      ...props?.queryOptions
    }

    return query
  }

  const useHookFn = (props: HookFnProps) => {
    return useQuery(getQuery(props))
  }

  const getters = {
    getKey,
    getEndpoint: (props: Props) => getEndpoint(props),
    getQuery,
    httpMethod,
    isPaginated
  }

  return Object.assign(useHookFn, getters)
}
