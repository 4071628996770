import { createMutationHook } from '../../createMutationHook'
import { Agent } from '../../types'
import { useAdminAgents } from '../queries'

type Props = {
  id: string;
}

export default createMutationHook<Props, Agent>({
  httpMethod: 'DELETE',
  getEndpoint: ({ id }) => `identity/users/${id}`,
  getQueryOptions: (queryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(useAdminAgents.getKey(undefined))
    }
  })
})
