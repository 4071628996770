/* eslint-disable */
import set from 'lodash.set'
import { ReactNode, useState, useEffect } from 'react'

import { useMyProfile, useUpdateMyProfile } from '../../services/hooks'
import UserDataContext from '../../contexts/UserDataContext/UserDataContext'
import { UserProfileData } from '../../services/types'
import { useSnackbar } from 'notistack'
import useChangePassword from '../../services/hooks/mutations/useChangePassword'
import { useUserSession } from '../../hooks'

type Props = {
  children: ReactNode
}

function UserDataProvider (props: Props) {
  const { children } = props
  const { refetch: refetchUserData, data: profileData, isLoading } = useMyProfile()
  const { isAuthenticated, isImpersonating } = useUserSession()
  const { mutateAsync: updateMyProfile } = useUpdateMyProfile()
  const { mutateAsync: changeMyPassword } = useChangePassword()
  const { enqueueSnackbar } = useSnackbar()

  const [user, setUser] = useState<UserProfileData | null>()

  const updateUserDataField = (value: any, path: string) => {
    setUser((data) => {
      if (data) {
        return { ...set(data, path, value) }
      } else {
        return undefined
      }
    })
  }

  useEffect(() => {
    if (!isLoading && profileData) {
      setUser(profileData)
    }
  }, [profileData])

  useEffect(() => {
    refetchUserData()
  }, [isImpersonating, isAuthenticated])

  const saveUserData = async () => {
    if (user) {
      try {
        delete user.concurrencyStamp

        await updateMyProfile(user)

        enqueueSnackbar('Account data saved successfully', { variant: 'success' })
      } catch (e: any) {

        enqueueSnackbar('Saving account data failed!', { variant: 'error' })
        enqueueSnackbar(`${e.response.data.error.details?.split('-')?.[1] || ''}`, { variant: 'error' })
      }
    }
  }

  const changePassword = async (currentPassword: string, newPassword: string) => {
    if (user) {
      try {
        await changeMyPassword({ currentPassword, newPassword })

        enqueueSnackbar('Password changed successfully', { variant: 'success' })
      } catch (e: any) {
        enqueueSnackbar('Changing password failed!', { variant: 'error' })
      }
    }
  }

  return (
    <UserDataContext.Provider value={{
      user: user as any,
      loadingUserData: isLoading,
      updateUserDataField,
      saveUserData,
      changePassword,
    }}>
      {children}
    </UserDataContext.Provider>
  )
}

export default UserDataProvider

