import { Select, InputLabel, SxProps, FormControl, FormHelperText, MenuItem, Checkbox, ListItemText, SelectChangeEvent } from '@mui/material'
import React from 'react'

import { MULTISELECT_SEPARATOR } from '../../utils/constants'

interface Props {
  label: string;
  value: string;
  options: string[]
  labelId: string
  name: string
  handleSelectChange: (value: string, name: string) => void

  required?: boolean
  multiple?: boolean
  placeholder?: string
  helperText?: string
  mb?: boolean
  mt?: boolean
  labelSx?: SxProps
  inputSx?: SxProps
  disabled?: boolean
  resetOnChange?: string[]
}

const BloomSelect = ({
  label,
  required = false,
  value,
  placeholder,
  name,
  handleSelectChange,
  options = [],
  helperText,
  labelId,
  mt,
  mb,
  multiple,
  inputSx,
  labelSx,
  disabled,
  resetOnChange
}: Props) => {
  const onSelectValueChange = (e: SelectChangeEvent<string | string[]>) => {
    handleSelectChange(Array.isArray(e.target.value) ? e.target.value.join(MULTISELECT_SEPARATOR) : e.target.value, name)

    if (resetOnChange && resetOnChange.length > 0) {
      resetOnChange.forEach(fieldName => handleSelectChange('', fieldName))
    }
  }

  return (
    <FormControl fullWidth>
      <InputLabel required={required} sx={{
        '& .MuiInputLabel-asterisk': {
          cursor: disabled ? 'not-allowed' : 'inherit',
          position: 'absolute',
          left: 0,
          top: '2.5px',
          fontSize: '16px',
          color: '#E54C17'
        },
        cursor: disabled ? 'not-allowed' : 'inherit',
        color: 'black !important',
        fontSize: '14px',
        mt: mt ? 3 : 0,
        paddingLeft: required ? '13px' : 0,
        mb: 0.8,

        ...labelSx
      }}>
        {label}
      </InputLabel>
      <Select
        sx={{
          '& .MuiSelect-select': {
            cursor: disabled ? 'not-allowed' : 'inherit'
          },
          '& .MuiInputBase-root': {
            minHeight: '34px',
            cursor: disabled ? 'not-allowed' : 'inherit',

            '& div': {
              cursor: disabled ? 'not-allowed' : 'inherit'
            },
            '& input': {
              cursor: disabled ? 'not-allowed' : 'inherit'
            }
          }
        }}
        size='small'
        multiple={multiple}
        displayEmpty
        required={required}
        placeholder={placeholder}
        labelId={labelId}
        disabled={disabled}
        value={multiple ? value.split(MULTISELECT_SEPARATOR) : (value || '')}
        renderValue={(selected) => Array.isArray(selected) ? selected.filter(s => !!s).join(' / ') : selected}
        onChange={onSelectValueChange}
      >
        {options.map(o => (
          <MenuItem key={o} value={o}>
            { multiple ? <Checkbox checked={value.split(MULTISELECT_SEPARATOR).indexOf(o) > -1} /> : null}
            <ListItemText primary={o} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText
        sx={{
          color: '#00000078',
          fontSize: '14px',
          mb: mb ? 3 : 0,
          cursor: disabled ? 'not-allowed' : 'inherit',
          ...inputSx
        }}
      >
        {helperText}
      </FormHelperText>
    </FormControl>
  )
}

export default BloomSelect
