/* eslint-disable react/display-name */

import { CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'
import { DataGrid, GridActionsCellItem, GridColDef, GridFilterModel, GridPaginationModel, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid'
import FileOpen from '@mui/icons-material/FileOpen'

import { useEffect, useState } from 'react'
import { Header } from '../../../components'
import { useCasExportItems } from '../../../services/hooks'
import { CasExport } from '../../../services/types'
import { CasShowDialog } from '../../../components/CasShowDialog'

const iconStyles = {
  width: '18px',
  height: '18px',
  minWidth: '18px',
  marginRight: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const getColumns = (onShowClick: (cas: CasExport) => void): GridColDef[] => {
  return [
    {
      field: 'applicantName',
      flex: 1,
      headerName: 'Name',
      sortable: false
    },
    {
      field: 'decisionStatus',
      flex: 1,
      headerName: 'Decision Status',
      sortable: false
    },
    {
      field: 'applicationStartDate',
      flex: 1,
      headerName: 'Start Date',
      sortable: false
    },
    {
      field: 'applicationStatus',
      flex: 1,
      headerName: 'Application Status',
      sortable: false
    },
    {
      field: 'applicantEmail',
      flex: 2,
      headerName: 'Email',
      sortable: false
    },
    {
      field: 'executionTimeSimple',
      flex: 1,
      headerName: 'Sync Time',
      sortable: false
    },
    {
      field: 'statusName',
      flex: 1,
      headerName: 'Status',
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, Date>) => (
        <span>
            {params.row.statusName}<br />
            {params.row.status === 1 && params.row.message ? <small style={{ color: 'red' }}>{params.row.message}</small> : null}
        </span>
      )
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 0.5,
      cellClassName: 'actions',
      sortable: false,
      getActions: ({ row }) => [
        <GridActionsCellItem
          key='edit'
          icon={<FileOpen color="primary" sx={iconStyles}/>}
          size="large"
          onClick={() => onShowClick(row)}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            justifyItems: 'center',
            alignContent: 'center'
          }}
        />
      ]
    }
  ]
}

const CasExportList = () => {
  const [gridState, setGridState] = useState<{ page: number, pageSize: number, search: string }>({ page: 1, pageSize: 10, search: '' })
  const { refetch, data, isLoading } = useCasExportItems({
    page: gridState.page || 1, pageSize: gridState.pageSize, search: gridState.search || ''
  })
  const [isCasShowOpen, setIsCasShowOpen] = useState(false)
  const [casToShow, setCasToShow] = useState<CasExport>()

  const onShowClick = async (cas: CasExport) => {
    setCasToShow(cas)
    setIsCasShowOpen(true)
  }

  const closeShowDialog = () => {
    setCasToShow(undefined)
    setIsCasShowOpen(false)
  }

  const onPageChange = (model: GridPaginationModel) => {
    console.log('newPage: ', model.page + 1)
    setGridState({ page: model.page + 1, pageSize: model.pageSize, search: gridState.search })
    refetch()
  }

  const onFilterChange = (model: GridFilterModel) => {
    console.log('search for: ', model.quickFilterValues)
    var search = model?.quickFilterValues && model.quickFilterValues.length > 0 ? model.quickFilterValues.join(' ') : ''
    setGridState({ ...gridState, search: search })
    refetch()
  }

  useEffect(() => {
    refetch()
  }, [])

  return (
    <Box sx={{ pl: 6, width: 'calc(100% - 225px)', position: 'relative' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
        <Header>CAS Export</Header>
      </Box>
      {
        data && !data.items
          ? <CircularProgress
              sx={{
                top: '200px',
                left: 0,
                right: 0,
                position: 'absolute',
                margin: '0 auto'
              }}
            />
          : <DataGrid
            rows={data?.items || []}
            rowCount={data?.totalCount || 0}
            columns={getColumns(onShowClick)}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            filterMode="server"
            onFilterModelChange={onFilterChange}
            loading={isLoading}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { placeholder: 'Search', debounceMs: 300 }
              }
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: gridState.pageSize
                }
              }
            }}
            pageSizeOptions={[10, 20, 40]}
            paginationMode="server"
            onPaginationModelChange={onPageChange}
            sx={{
              maxWidth: '100% !important',
              '& .MuiDataGrid-columnHeaders': {
                background: '#EC6605 !important'
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                color: 'white'
              },
              '& .MuiDataGrid-columnHeader--moving': {
                background: '#EC6605 !important'
              },
              '& .MuiDataGrid-cellContent': {
                paddingTop: 1,
                paddingBottom: 1
              },
              '& .MuiDataGrid-virtualScroller': {
                overflowX: 'hidden'
              },
              '& .MuiTablePagination-root': {
                color: 'black !important'
              },
              '& .MuiDataGrid-row:nth-child(odd)': {
                backgroundColor: '#F5F5F5 !important'
              }
            }}
          />
      }
      { isCasShowOpen ? <CasShowDialog casToShow={casToShow} isOpen={isCasShowOpen} closeDialog={closeShowDialog}/> : null}
    </Box>
  )
}

export default CasExportList
