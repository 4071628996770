import { createQueryHook } from '../../createQueryHook'
import { UnconditionalOfferForm } from '../../types'

type Props = {
  id: string;
};

export default createQueryHook<Props, UnconditionalOfferForm>({
  getKey: ({ id }) => [`app/unconditional-form/${id}`],
  getEndpoint: ({ id }) => `app/unconditional-form/${id}`
})
