import { Box, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { ApplicantUserPanel } from '../../../components/ApplicantDashboard'
import { useUserSession } from '../../../hooks'
import { AppViewMode } from '../../../providers/AuthProvider/AuthProvider'
import DashboardDataProvider from '../../../providers/DashboardDataProvider/DashboardDataProvider'

function Dashboard () {
  const { setViewMode } = useUserSession()

  useEffect(() => {
    setViewMode(AppViewMode.dashboard)
  }, [])

  return (
    <DashboardDataProvider>
      <Box sx={{ width: '100%', pt: 1 }}>
        <Typography
          sx={{
            mb: 2,
            position: 'relative',
            left: '0px',
            top: '0px',
            fontWeight: 700
          }}
          variant="body2"
          color="primary"
        >
          Dashboard
        </Typography>
        <Stack direction="row">
          <ApplicantUserPanel />
          <Outlet/>
        </Stack>
      </Box>
    </DashboardDataProvider>
  )
}

export default Dashboard
