import { createContext } from 'react'

import { DelegateUser } from '../../services/types'

export type AdminDataContext = {
  agents?: readonly DelegateUser[]
  isLoadingAgents: boolean

  // casItems?: readonly CasExport[]
  // isLoadingCas: boolean
}

const AdminContext = createContext({} as AdminDataContext)

export default AdminContext
