import { APIError } from '../contexts/AuthContext/AuthContext'

export enum CALLERS {
  'CreateAccount' = 'CreateAccount',
  'CreateAgentAccountFromAdmin' = 'CreateAgentAccountFromAdmin'
}

export const parseBEError = (error: APIError | undefined, callee: CALLERS) => {
  if (error && error.code === 'DuplicateUserName' && callee === CALLERS.CreateAccount) {
    return `Account registered with ${error.message} already exists`
  }
  if (error && error.code.includes('DuplicateUserName') && callee === CALLERS.CreateAgentAccountFromAdmin) {
    return `Account registered with ${error.data[0]} already exists`
  } else return 'Sorry, something went wrong.'
}
