/* eslint-disable */
import set from 'lodash.set'
import { ReactNode, useState, useEffect } from 'react'
import { CasSettings } from '../../services/types'
import { useSnackbar } from 'notistack'
import { CasSettingsContext } from '../../contexts'
import { useFetchCasSettings, useUpdateCasSettings } from '../../services/hooks'

type Props = {
  children: ReactNode
}

function CasSettingsProvider (props: Props) {
  const { children } = props
  const { refetch: refetchCasSettings, data: casSettingsData, isLoading } = useFetchCasSettings()
  const { mutateAsync: updateCasSettings } = useUpdateCasSettings()
  const { enqueueSnackbar } = useSnackbar()

  const [casSettings, setCasSettings] = useState<CasSettings | null>()

  const updateCasSettingsField = (value: any, path: string) => {
    setCasSettings((data) => {
      if (data) {
        return { ...set(data, path, value) }
      } else {
        return undefined
      }
    })
  }

  useEffect(() => {
    if (!isLoading && casSettingsData) {
      setCasSettings(casSettingsData)
    }
  }, [casSettingsData])

  // useEffect(() => {
  //   refetchUserData()
  // }, [isImpersonating, isAuthenticated])

  const saveCasSettings = async () => {
    if (casSettings) {
      try {

        await updateCasSettings(casSettings)

        enqueueSnackbar('CAS settings saved successfully', { variant: 'success' })
      } catch (e: any) {

        enqueueSnackbar('Saving CAS settings failed!', { variant: 'error' })
        enqueueSnackbar(`${e.response.data.error.details?.split('-')?.[1] || ''}`, { variant: 'error' })
      }
    }
  }

  return (
    <CasSettingsContext.Provider value={{
      casSettings: casSettings as any,
      loadingCasSettings: isLoading,
      updateCasSettingsField,
      saveCasSettings
    }}>
      {children}
    </CasSettingsContext.Provider>
  )
}

export default CasSettingsProvider

