import { createQueryHook } from '../../createQueryHook'
import { ReferenceForm } from '../../types'

type Props = {
  id: string;
  code: string;
};

export default createQueryHook<Props, ReferenceForm>({
  getKey: ({ id }) => [`app/reference-form/${id}/form`],
  getEndpoint: ({ id }) => `app/reference-form/${id}/form`,
  getApiClientConfig: (_, { code }) => ({
    params: {
      code
    }
  })
})
