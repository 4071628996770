/* eslint-disable */

import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { BloomSelect, Header, Paper, Text } from '../../../components'
import { YesNo } from '../../../utils/constants'
import { useConditionalOfferForm, useSubmitConditionalOfferForm } from '../../../services/hooks'
import { ConditionalOfferForm } from '../../../services/types'

const ConditionalOfferFormComponent = () => {
  const { search } = useLocation()
  const values = search.split('&')

  const referenceId = values[0]?.split('id=')[1] || ''

  const { data, isFetched, isLoading } = useConditionalOfferForm({ id: referenceId })
  const { mutateAsync: submitConditionalOfferForm  } = useSubmitConditionalOfferForm()

  const [formRequestStatus, setFormRequestStatus] = useState('success')
  const [_, setError] = useState<string>()
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<Omit<ConditionalOfferForm, 'id'>>({ mode: 'onChange' })

  const onSubmit: SubmitHandler<FieldValues> = async (fieldValues: FieldValues, e) => {
    try {
      const typed = fieldValues as ConditionalOfferForm

      e?.preventDefault()

      setFormRequestStatus('loading')

      await submitConditionalOfferForm({ form: { ...data, ...typed } })

      setFormRequestStatus('success')
      navigate('/applicant/dashboard')
    } catch (e) {
      setFormRequestStatus('failed')
      setError('Offer form submission failed. Try again or contact administration.')
    }
  }

  useEffect(() => {
    if (isFetched && !data) {
      navigate('/applicant/dashboard')
    }
  }, [isFetched, data])

  return (
    <Stack direction="column" sx={{ pl: 6, flex: 1 }}>
      <Header>Conditional Offer</Header>
      <Paper sx={{ pl: 6, width: '100%', position: 'relative' }}>
        <Typography variant="body1" color='_.dark' sx={{ position: 'absolute', right: '36px' }}>All fields with an * are required</Typography>
        {!data || isLoading ? <CircularProgress
            sx={{
              top: '200px',
              left: 0,
              right: 0,
              margin: '0 auto'
            }}
          /> :
        <form onSubmit={handleSubmit(onSubmit)}>
          <Header sx={{ mt: 2 }}>Accept or Withdraw</Header>
          <Text>
            State whether you wish to accept the offer of a place on the course, or whether you would like to withdraw your application.
          </Text>
          <br />
          <Controller
            control={control}
            name="offerAccepted"
            rules={{
              required: 'This value is required',
            }}
            render={({ field }) => (
              <BloomSelect
                required
                handleSelectChange={field.onChange}
                value={field.value}
                labelId="offerAccepted"
                name="offerAccepted"
                label="Conditional Offer Accepted"
                mb
                options={['Yes - I accept the conditional offer, subject to the Requirements/Conditions set out in the Self Service Centre', 'No, I want to withdraw my application']}
              />
            )}
          />
          <Header sx={{ mt: 1 }}>Terms and Conditions</Header>
          <Text>
            Your offer of a place on the course is subject to Bloomsbury Institute's Terms and Condtions.
            <br/>
            <br/>
            We draw your attention to term 10.1 of our Terms and Conditions that provides that if you fail to engage with your studies without good cause and due
            notice by the end of the sixth week of the advertised start date of your course, you will be deemed to have withdrawn from the course and your registration will be terminated.
            If you are withdrawn for failing to engage with your studies you will remain liable for the full tuition fee.
            <br/>
            <br/>
            Please select YES below to confirm you have read the Terms and Conditions and that you accept them.  If you have either not read the Terms and Conditions, or you do not accept them, select NO.
            If you select NO you will not be entitled to start the course.
            <br/>
          </Text>
          <br/>
          <Controller
            control={control}
            name="termsAndConditionsAccepted"
            rules={{
              required: 'This value is required',
            }}
            render={({ field }) => (
              <BloomSelect
                required
                handleSelectChange={field.onChange}
                value={field.value}
                labelId="termsAndConditionsAccepted"
                name="termsAndConditionsAccepted"
                label="I have read and accept the Terms and Conditions of Bloomsbury Institute"
                mb
                options={YesNo}
              />
            )}
          />
          <Button
            endIcon={<ArrowUpwardIcon/>}
            type="submit"
            variant='contained'
            sx={{ textTransform: 'none', mt: '16px', width: "fit-content" }}
            disabled={formRequestStatus === 'loading' || !isValid}
          >
            {formRequestStatus === 'loading' ? 'Loading...' : 'Submit form'}
          </Button>
        </form>
      }
      </Paper>
    </Stack>
  )
}

export default ConditionalOfferFormComponent
