/* eslint-disable */
import { ReactNode, useEffect, useState, } from 'react'
import { AgentContext } from '../../contexts'
import { useDelegatedUsers } from '../../services/hooks'
// import { useSnackbar } from 'notistack'

type Props = {
  children: ReactNode
}

function AgentProvider (props: Props) {
  const { children } = props
  // const { enqueueSnackbar } = useSnackbar()

  const [isImpersonating, setIsImpersonating] = useState<boolean>(false)
  const {
    isLoading: isLoadingDelegatedUsers,
    data: delegatedUsers,
    refetch
  } = useDelegatedUsers()

  useEffect(() => {
    if (!isImpersonating) {
      refetch()
    }
  }, [isImpersonating])

  return (
    <AgentContext.Provider value={{
      isImpersonating,
      setIsImpersonating,
      delegatedUsers: delegatedUsers?.items,
      isLoadingDelegatedUsers
    }}>
      {children}
    </AgentContext.Provider>
  )
}

export default AgentProvider

