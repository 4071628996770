import { createMutationHook } from '../../createMutationHook'
import { UserProfileData, UserProfileDataUpdatePayload } from '../../types'
import { useMyProfile } from '../queries'

export default createMutationHook<UserProfileDataUpdatePayload, Omit<UserProfileData, 'concurrencyStamp'>>({
  httpMethod: 'PUT',
  getEndpoint: () => 'account/my-profile',
  getApiClientConfig: (payload) => {
    return {
      data: payload
    }
  },
  getQueryOptions: (queryClient) => ({
    onSuccess: (_data) => {
      queryClient.invalidateQueries(useMyProfile.getKey(undefined))
    }
  })
})
