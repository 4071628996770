// import merge from 'lodash.merge'
import set from 'lodash.set'
import { FormEvent, ReactNode, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ApplicationFormContext } from '../../contexts'
import { useSnackbar, useUserSession } from '../../hooks'
import { useCreateApplicationForm, useFormConfiguration, useSaveApplicationForm, useSubmitApplicationForm, useUserDefaultApplicationForm, useValidateApplicationForm } from '../../services/hooks'
import { ApplicationForm, FieldConfiguration, FormSummary } from '../../services/types'
import { stepsNames, EMPTY_FORM_FIELD_CONFIGURATION } from '../../utils/constants'

type Props = {
  children: ReactNode
}

function ApplicationFormProvider ({ children }: Props) {
  const navigate = useNavigate()
  const { isAuthenticated, isImpersonating } = useUserSession()
  const [activeStep, setActiveStep] = useState(0)
  const [previousStep, setPreviousStep] = useState<number>(0)
  const {
    isSuccess: isSuccessDefaultForm,
    isLoading: isLoadingDefaultForm,
    data: defaultForm,
    refetch: refetchDefaultForm
  } = useUserDefaultApplicationForm({ queryOptions: { retry: false, refetchInterval: 9999999 } })

  const { mutateAsync: createApplicationForm } = useCreateApplicationForm()
  const { mutateAsync: saveApplicationForm } = useSaveApplicationForm()
  const { mutateAsync: submitApplicationForm } = useSubmitApplicationForm()
  const { mutateAsync: validateApplicationForm } = useValidateApplicationForm()

  const { enqueueSnackbar } = useSnackbar()

  const [form, setForm] = useState<ApplicationForm>()
  const [isFormLoading, setIsFormLoading] = useState(true)
  const [controlsDisabled, setControlsDisabled] = useState(false)
  const [formSummary, setFormSummary] = useState<FormSummary>()
  const [defaultFormFetchRetries, setDefaultFormFetchRetries] = useState(0)

  const { data: formConfig, refetch: refetchFormConfig } = useFormConfiguration({ queryOptions: { enabled: false } })

  const isFormSubmitted = useMemo(() => form?.status === 2, [form])

  useEffect(() => {
    if (isAuthenticated) {
      refetchDefaultForm()
      refetchFormConfig()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (isImpersonating && isAuthenticated) {
      refetchDefaultForm()
      refetchFormConfig()
    }
  }, [isImpersonating, isAuthenticated])

  useEffect(() => {
    if (typeof defaultForm !== 'string') {
      setForm(defaultForm)
    } else if (defaultFormFetchRetries <= 3) {
      // @ts-ignore
      refetchDefaultForm()
      setDefaultFormFetchRetries(retries => retries + 1)
    }
  }, [defaultForm])

  // create default form if it doesn't exist
  useEffect(() => {
    const createForm = async () => {
      if (isSuccessDefaultForm && !isLoadingDefaultForm && typeof defaultForm === 'string') {
        const newAppForm = await createApplicationForm({})

        setForm(newAppForm)
      }
    }

    if (isAuthenticated) {
      createForm()
    }
  }, [isAuthenticated, isSuccessDefaultForm, isLoadingDefaultForm, defaultForm])

  useEffect(() => {
    if (isAuthenticated && form && formConfig) {
      setIsFormLoading(false)
    }
  }, [isAuthenticated, form, formConfig])

  const canStepForward = activeStep < stepsNames.length - 2
  const IS_SUMMARY = activeStep === stepsNames.length - 1
  const isSaveable = activeStep !== 0 && !IS_SUMMARY

  const getStepConfig = (sectionName: string): FieldConfiguration[] => {
    if (!Array.isArray(formConfig)) {
      return []
    }

    return (formConfig || []).filter(field => {
      return field.sectionPath[0].toLowerCase() === sectionName.toLowerCase()
    })
  }

  const getFieldConfig = (name: string, providedSectionConfig?: FieldConfiguration[], sectionPath?: string[]): FieldConfiguration => {
    let sectionConfig = providedSectionConfig

    if (!sectionConfig && sectionPath) {
      if (!Array.isArray(formConfig)) {
        return EMPTY_FORM_FIELD_CONFIGURATION
      }

      sectionConfig = (formConfig || []).filter(field => {
        return field.sectionPath[0].toLowerCase() === sectionPath[0].toLowerCase()
      }) || []
    }

    if (!sectionConfig) {
      throw new Error('Section config missing. Please provide valid parameters.')
    }

    if (sectionPath && sectionPath[1]) {
      const subsectionConfig = sectionConfig.filter(field => field.sectionPath[1].toLowerCase() === sectionPath[1].toLowerCase()) || []

      return subsectionConfig.find(field => field.fieldName === name) || EMPTY_FORM_FIELD_CONFIGURATION
    }

    const fieldConfig = sectionConfig.find(field => field.fieldName.toLowerCase() === name.toLowerCase())

    return fieldConfig || EMPTY_FORM_FIELD_CONFIGURATION
  }

  const saveForm = async (hideMessage = false, dontShowSummary = false) => {
    setControlsDisabled(true)

    if (form) {
      try {
        const result = await saveApplicationForm(form)

        if (!IS_SUMMARY) {
          if (!hideMessage) {
            enqueueSnackbar('Form data saved successfully!', { variant: 'success' })
          }

          if (!dontShowSummary) {
            setFormSummary(result)
            setActiveStep(stepsNames.length - 1)
            setPreviousStep(activeStep) // so we can always go back to where we were
          }
        }
      } catch (e) {
        console.log(e)

        enqueueSnackbar('Oops! Something went wrong when saving your data.', { variant: 'error' })
      }
    }

    setControlsDisabled(false)

    return false
  }

  const validateForm = async () => {
    try {
      if (form) {
        const result = await validateApplicationForm(form)

        if (!IS_SUMMARY) {
          setFormSummary(result)
          setPreviousStep(activeStep) // so we can always go back to where we were
          setActiveStep(stepsNames.length - 1)
        }
      }
    } catch (e) {
      console.log(e)

      enqueueSnackbar('Oops! Something went wrong.', { variant: 'error' })
    }
  }

  const submitForm = async (e: FormEvent) => {
    let submitSuccess = true

    if (form) {
      try {
        await submitApplicationForm(form)

        enqueueSnackbar('Form submitted successfully', { variant: 'success' })
      } catch (e: any) {
        submitSuccess = false
        enqueueSnackbar('Form has not been submitted!', { variant: 'error' })
      }
    }

    if (submitSuccess) {
      navigate('/applicant/dashboard')
    } else {
      await validateForm()
    }

    e.preventDefault()
  }

  const stepBack = () => {
    if (activeStep !== 0) {
      setActiveStep(step => IS_SUMMARY ? previousStep : step - 1)

      setTimeout(() => scrollTo({ left: 0, top: 0, behavior: 'smooth' }), 200)
    }
  }

  const stepForward = () => {
    if (canStepForward) {
      setPreviousStep(activeStep)
      setActiveStep(step => step + 1)

      if (!isFormSubmitted) {
        saveForm(true, true)
      }

      setTimeout(() => scrollTo({ left: 0, top: 0, behavior: 'smooth' }), 200)
    }
  }

  const goToStep = (index: number) => {
    setActiveStep(index)
  }

  // local update, no BE call here
  const updateFormField = (value: any, path: string) => {
    setForm((form) => {
      if (form) {
        return { ...set(form, path, value) }
      } else {
        return undefined
      }
    })
  }

  return (
    <ApplicationFormContext.Provider value={{
      activeStep,
      stepBack,
      stepForward,
      goToStep,
      canStepForward,
      isSaveable,
      getFieldConfig,
      getStepConfig,
      form,
      updateFormField,
      submitForm,
      saveForm,
      isFormLoading,
      formSummary,
      isFormSubmitted,
      isSummary: IS_SUMMARY,
      controlsDisabled
    }}>
      {children}
    </ApplicationFormContext.Provider>
  )
}

export default ApplicationFormProvider
