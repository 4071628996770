/* eslint-disable */
import { ReactNode } from 'react'
import { useAdminAgents } from '../../services/hooks'
import { AdminContext } from '../../contexts/AdminContext'

type Props = {
  children: ReactNode
}

function AdminProvider (props: Props) {
  const { children } = props

  const {
    isLoading: isLoadingAgents,
    data: agents,
  } = useAdminAgents()

  // const {
  //   isLoading: isLoadingCas,
  //   data: casItems,
  // } = useCasExportItems()

  return (
    <AdminContext.Provider value={{
      agents: agents?.items,
      isLoadingAgents,
      // casItems: casItems?.items,
      // isLoadingCas
    }}>
      {children}
    </AdminContext.Provider>
  )
}

export default AdminProvider

