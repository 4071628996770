import { QueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: (query) => {
        // Do not refetch if the last result returned a FORBIDDEN error
        if (
          (query.state.error as AxiosError)?.response?.status ===
          StatusCodes.FORBIDDEN
        ) {
          return false
        }
        return true
      }
    }
  }
})

export { queryClient }
