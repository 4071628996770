import { createMutationHook } from '../../createMutationHook'
import { useDashboardData, useMyProfile, useUserDefaultApplicationForm } from '../queries'

export type Response = any

export default createMutationHook<{
  userId: string,
  accessToken: string,
}, Response>({
  httpMethod: 'POST',
  getEndpoint: () => '../connect/token',
  getApiClientConfig: (payload) => {
    return {
      data: {
        UserDelegationId: payload.userId,
        access_token: payload.accessToken,
        grant_type: 'Impersonation',
        client_id: process.env.REACT_APP_OIDC_CONFIG_CLIENT_ID,
        scope: 'offline_access BloomInternational'
      }
    }
  },
  getQueryOptions: (queryClient) => ({
    onSuccess: (_data) => {
      queryClient.invalidateQueries(useMyProfile.getKey(undefined))
      queryClient.invalidateQueries(useDashboardData.getKey(undefined))
      queryClient.invalidateQueries(useUserDefaultApplicationForm.getKey(undefined))
    }
  })
})
