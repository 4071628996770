import HomeIcon from '@mui/icons-material/Home'
import MrSmiley from '@mui/icons-material/InsertEmoticon'
import CloudUpload from '@mui/icons-material/CloudUpload'
import CloudSync from '@mui/icons-material/CloudSync'
// import MailIcon from '@mui/icons-material/Mail'
// import NotificationsIcon from '@mui/icons-material/Notifications'
import { Box, Stack, Typography } from '@mui/material'
import { PathMatch, useMatch, useNavigate } from 'react-router-dom'

// import { useDashboardData } from '../../../hooks'
import { UserDataProvider } from '../../../providers'
import { useMyProfile } from '../../../services/hooks'
import { Paper } from '../../Paper'

const iconStyles = {
  width: '18px',
  height: '18px',
  minWidth: '18px',
  marginRight: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const rowStyles = { mb: 2, alignItems: 'center', cursor: 'pointer' }

const getItemColor = (match: PathMatch | null, activeIf: string | undefined) => {
  const matched = match?.params['*']

  return matched === activeIf ? 'primary' : 'inherit'
}

const UserPanel = () => {
  const { data: profileData } = useMyProfile()
  // const { data: dashboardData } = useDashboardData()

  const navigate = useNavigate()
  const match = useMatch('/admin/dashboard/*')

  const onMenuItemClick = (path: string) => {
    navigate(path)
  }

  return (
    <UserDataProvider>
      <Paper sx={{ minWidth: '223px', width: '223px', height: '500px', mt: 8, justifyContent: 'space-between' }}>
        <Box>
          <Box onClick={() => onMenuItemClick('/admin/dashboard/my-profile')}>
            <Stack direction="row" sx={rowStyles}>
              <MrSmiley color={getItemColor(match, 'my-profile')} sx={iconStyles}/>
              <Typography color={getItemColor(match, 'my-profile')} variant="body1" fontWeight="bold"
                sx={{
                  position: 'relative',
                  top: '1.4px',
                  width: '180px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                {profileData?.name || ''}{' '}{profileData?.surname || ''}
              </Typography>
            </Stack>
          </Box>
          <Box onClick={() => onMenuItemClick('/admin/dashboard')}>
            <Stack direction="row" sx={rowStyles}>
              <HomeIcon color={getItemColor(match, '')} sx={iconStyles}/>
              <Typography variant="body1" color={getItemColor(match, '')} fontWeight="bold" sx={{ position: 'relative', top: '1px' }}>
                Home
              </Typography>
            </Stack>
          </Box>
          <Box onClick={() => onMenuItemClick('/admin/dashboard/cas')}>
            <Stack direction="row" sx={rowStyles}>
              <CloudUpload color={getItemColor(match, 'cas')} sx={iconStyles}/>
              <Typography variant="body1" color={getItemColor(match, 'cas')} fontWeight="bold" sx={{ position: 'relative', top: '1px' }}>
                CAS Export
              </Typography>
            </Stack>
          </Box>
          <Box onClick={() => onMenuItemClick('/admin/dashboard/cas-settings')}>
            <Stack direction="row" sx={rowStyles}>
              <CloudSync color={getItemColor(match, 'cas-settings')} sx={iconStyles}/>
              <Typography variant="body1" color={getItemColor(match, 'cas-settings')} fontWeight="bold" sx={{ position: 'relative', top: '1px' }}>
                CAS Settings
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Paper>
    </UserDataProvider>
  )
}

export default UserPanel
