import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { CasExport } from '../../services/types'
import ReactJson from 'react-json-view'

interface Props {
  isOpen: boolean
  closeDialog: () => void
  casToShow: CasExport | undefined
}

const CasShowDialog = ({ isOpen, closeDialog, casToShow }: Props) => {
  return (
    <Dialog fullWidth maxWidth='md' open={isOpen} onClose={closeDialog} sx={{ '.MuiPaper-root': { backgroundColor: '#fff' } }}>
      <DialogTitle sx={{ pt: 3, pb: 2 }}>CAS payload</DialogTitle>
      <DialogContent>
        {casToShow?.data
          ? <ReactJson src={JSON.parse(casToShow?.data)} displayDataTypes={false} displayObjectSize={false} enableClipboard={false} />
          : <Typography>No content available</Typography>}
      </DialogContent>
      <DialogActions sx={{ pt: 3, pb: 3 }}>
        <Button variant='contained' onClick={closeDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CasShowDialog
