import { useContext } from 'react'

import { AdminContext } from '../../contexts'

function useAdminData () {
  const data = useContext(AdminContext)

  return data
}

export default useAdminData
