import { APIError } from '../../../contexts/AuthContext/AuthContext'
import { createMutationHook } from '../../createMutationHook'
import { User } from '../../types'
import { useAdminAgents } from '../queries'

export default createMutationHook<User, User | { error?: APIError }>({
  httpMethod: 'PUT',
  getEndpoint: ({ id }) => `identity/users/${id}`,
  getApiClientConfig: (payload) => {
    return {
      data: payload
    }
  },
  getQueryOptions: (queryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(useAdminAgents.getKey(undefined))
    }
  })
})
