import { AuthUser } from '../contexts'

type Params = {
  user?: AuthUser
  permissions?: string[]
  roles?: string[]
}

export function validateUserPermissions ({
  user,
  permissions,
  roles
}: Params) {
  let hasAllPermissions = true
  let hasAllRoles = true

  if (permissions?.length) {
    const userPermissions = user?.permissions || []

    hasAllPermissions = permissions.every(permission => userPermissions.includes(permission))
  }

  if (roles?.length) {
    const userRoles = user?.roles || []

    hasAllRoles = roles.every(role => userRoles.includes(role))
  }

  return { hasAllPermissions, hasAllRoles }
}
