import { CircularProgress, Stack } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useDashboardData, useUserSession } from '../../hooks'
import { DashboardDataProvider } from '../../providers/DashboardDataProvider'
import { ROLES } from '../../utils/constants'

function RolesBalancer () {
  const { authUser, isImpersonating } = useUserSession()
  const { isLoading, applicationFormSubmitted, data } = useDashboardData()
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoading || !data) {
      return
    }

    if (authUser?.roles.includes(ROLES.Applicant)) {
      if (isImpersonating) {
        return navigate('/applicant/dashboard')
      }
      if (data && applicationFormSubmitted) {
        return navigate('/applicant/dashboard')
      } else if (!applicationFormSubmitted) {
        return navigate('/applicant/application-form')
      }
    } else if (authUser?.roles.includes(ROLES.Agent)) {
      navigate('/agent/dashboard')
    } else if (authUser?.roles.includes(ROLES.Admin)) {
      navigate('/admin/dashboard')
    }
  }, [isImpersonating, authUser, data, isLoading])

  return (
    <Stack direction="column" sx={{
      maxWidth: '560px',
      width: '70%',
      height: 'calc(100vh - 70px)',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <CircularProgress />
    </Stack>
  )
}

const Wrapped = () => {
  return <DashboardDataProvider>
    <RolesBalancer />
  </DashboardDataProvider>
}

export default Wrapped
