import { createMutationHook } from '../../createMutationHook'

export type Response = any

export default createMutationHook<{
  userId: string,
  resetToken: string,
  password: string
}, Response>({
  httpMethod: 'POST',
  getEndpoint: () => 'account/reset-password',
  getApiClientConfig: (payload) => {
    return {
      data: payload
    }
  }
})
