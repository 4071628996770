import { Box, Typography } from '@mui/material'

const Logo = () => {
  return <Box sx={{ width: 'fit-content', borderLeft: '3px solid #EC6605', pl: 1.5, pr: 1.5 }}>
    <Typography textTransform="uppercase" sx={{ letterSpacing: '1.7px', lineHeight: '20px', fontSize: '18px', fontWeight: 700 }}>Bloomsbury</Typography>
    <Typography textTransform="uppercase" sx={{ letterSpacing: '1.7px', lineHeight: '20px', fontSize: '18px', fontWeight: 700 }}>Institute</Typography>
    <Typography textTransform="uppercase" sx={{ letterSpacing: '1.7px', lineHeight: '14px', fontSize: '10px', fontWeight: 500 }}>London</Typography>
  </Box>
}

export default Logo
