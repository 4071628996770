import { createQueryHook } from '../../createQueryHook'
import { User } from '../../types'

type Props = {
  id: string;
};

export default createQueryHook<Props, User>({
  getKey: ({ id }) => [`identity/users/${id}`],
  getEndpoint: ({ id }) => `identity/users/${id}`
})
