import { SxProps, Typography } from '@mui/material'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  sx?: SxProps
}

const Text = ({ children, sx }: Props) => <Typography variant="body1" color='text.primary' sx={{ ...sx }}>{children}</Typography>

export default Text
