import EditIcon from '@mui/icons-material/Edit'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import SyncLockIcon from '@mui/icons-material/SyncLock'
import { TextField, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form'

import { useCreateAgent, useUpdateUser } from '../../services/hooks'
import { Agent } from '../../services/types'
import { CALLERS, parseBEError } from '../../utils/api'
import { EMAIL_VALIDATION_PATTERN, PASS_VALIDATION_PATTERN } from '../../utils/validations'
import { PasswordInputEndAdornment } from '../PasswordInputEndAdornment'

type FormData = {
  name: string
  surname: string
  email: string
  password: string
  passwordConfirmation: string
  radiusAgentName: string
};

interface Props {
  isOpen: boolean
  closeDialog: () => void
  agentToEdit?: Agent
}

const AddEditAgentDialog = ({ isOpen, closeDialog, agentToEdit }: Props) => {
  const { mutateAsync: createAgent } = useCreateAgent()
  const { mutateAsync: updateAgent } = useUpdateUser()
  const [createAccountStatus, setCreateAccountStatus] = React.useState('success')
  const {
    control,
    handleSubmit,
    formState: { isDirty, errors },
    clearErrors,
    watch,
    setValue,
    reset
  } = useForm<FormData>({ mode: 'onChange' })
  const { enqueueSnackbar } = useSnackbar()
  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  React.useEffect(() => {
    if (agentToEdit) {
      setValue('name', agentToEdit.name)
      setValue('surname', agentToEdit.surname)
      setValue('email', agentToEdit.email)
      setValue('radiusAgentName', agentToEdit.extraProperties.RadiusAgentName)
    }
  }, [agentToEdit])

  const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues, e) => {
    try {
      const typed = data as FormData

      e?.preventDefault()

      setCreateAccountStatus('loading')

      // @ts-ignore
      delete typed.passwordConfirmation
      const radiusAgentName = typed.radiusAgentName
      // @ts-ignore
      delete typed.radiusAgentName

      const result = agentToEdit
        ? await updateAgent({ ...agentToEdit, ...typed, userName: typed.email, id: agentToEdit.id, extraProperties: { RadiusAgentName: radiusAgentName } })
        : await createAgent({ ...typed, ...{ shouldChangePasswordOnNextLogin: false, isActive: true, userName: typed.email, roleNames: ['Agent'], extraProperties: { RadiusAgentName: radiusAgentName } } })

      // @ts-ignore
      if (result?.id) {
        setCreateAccountStatus('success')
        enqueueSnackbar(`Account ${agentToEdit ? 'edited' : 'created'} successfully!`, { variant: 'success' })
        closeDialog()
      } else {
        setCreateAccountStatus('failed')
        enqueueSnackbar(`Account ${agentToEdit ? 'edition' : 'creation'} failed!`, { variant: 'error' })
      }
    } catch (e: any) {
      const readableError = e.response.data.error ? parseBEError(e.response.data.error, CALLERS.CreateAgentAccountFromAdmin) : ''

      setCreateAccountStatus('failed')
      enqueueSnackbar(readableError || `Account ${agentToEdit ? 'edition' : 'creation'} failed!`, { variant: 'error' })
    }
  }

  const onClose = () => {
    reset()
    clearErrors()

    closeDialog()
  }

  return (
    <Dialog fullWidth maxWidth='md' open={isOpen} onClose={onClose}>
      <DialogTitle sx={{ pt: 3, pb: 2 }}>{agentToEdit ? 'Edit ' : 'Create '}Agent Account</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            control={control}
            name="email"
            defaultValue=""
            rules={{
              required: 'Email address is required',
              minLength: {
                value: 3,
                message: 'Minimum length is 3'
              },
              maxLength: {
                value: 100,
                message: 'Max length is 100'
              },
              pattern: {
                value: EMAIL_VALIDATION_PATTERN,
                message: 'Entered value does not match email format'
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                helperText={errors.email?.message}
                error={!!error}
                variant='outlined'
                placeholder='Email'
                type="email"
                sx={{ mb: 3 }}
                // size='small'
                disabled={createAccountStatus === 'loading'}
                id="email"
              />
            )}
          />
          <Controller
            control={control}
            name="name"
            defaultValue=""
            rules={{
              required: 'First name is required',
              minLength: {
                value: 3,
                message: 'Minimum length is 3'
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                helperText={errors.name?.message}
                error={!!error}
                variant='outlined'
                placeholder='First Name'
                type="text"
                id="firstName"
                sx={{ mb: 3 }}
                disabled={createAccountStatus === 'loading'}
              />
            )}
          />
          <Controller
            control={control}
            name="surname"
            defaultValue=""
            rules={{
              required: 'Last name is required',
              minLength: {
                value: 3,
                message: 'Minimum length is 3'
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                helperText={errors.surname?.message}
                error={!!error}
                variant='outlined'
                placeholder='Last Name'
                type="text"
                id="lastName"
                sx={{ mb: 3 }}
                disabled={createAccountStatus === 'loading'}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            defaultValue=""
            rules={{
              required: agentToEdit ? false : 'Password is required',
              minLength: {
                value: 8,
                message: 'Minimum length is 8'
              },
              pattern: {
                value: PASS_VALIDATION_PATTERN,
                message: 'Password has to include small letter, capital letter, digit and special character'
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                helperText={errors.password?.message}
                error={!!error}
                variant='outlined'
                placeholder='Password (at least 8 characters, case sensitive)'
                id="password"
                sx={{ mb: 3 }}
                disabled={createAccountStatus === 'loading'}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: <PasswordInputEndAdornment
                      handleClickShowPassword={handleClickShowPassword}
                      showPassword={showPassword}
                    />
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="passwordConfirmation"
            defaultValue=""
            rules={{
              required: agentToEdit ? false : 'Password confirmation is required',
              minLength: {
                value: 8,
                message: 'Minimum length is 8'
              },
              validate: (val: string) => {
                if (watch('password') !== val) {
                  return 'Your passwords do no match'
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                sx={{ mb: 3 }}
                variant='outlined'
                placeholder='Confirm password'
                id="passwordConfirmation"
                helperText={errors.passwordConfirmation?.message}
                error={!!error}
                disabled={createAccountStatus === 'loading'}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: <PasswordInputEndAdornment
                      handleClickShowPassword={handleClickShowPassword}
                      showPassword={showPassword}
                    />
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="radiusAgentName"
            defaultValue=""
            rules={{
              required: 'Radius agent name is required'
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                helperText={errors.name?.message}
                error={!!error}
                variant='outlined'
                placeholder='Radius Agent Name'
                type="text"
                id="radiusAgentName"
                sx={{ mb: 3 }}
                disabled={createAccountStatus === 'loading'}
              />
            )}
          />
          {errors.root?.message && <Typography color="error">{errors.root?.message}</Typography>}
        </DialogContent>
        <DialogActions sx={{ pt: 0, pb: 3 }}>
          <Button onClick={() => closeDialog()}>Cancel</Button>
          <Button
            endIcon={createAccountStatus === 'loading' ? <SyncLockIcon /> : agentToEdit ? <EditIcon /> : <PersonAddIcon />}
            type="submit"
            fullWidth
            variant='contained'
            sx={{ textTransform: 'none', width: 'fit-content', alignSelf: 'flex-end' }}
            disabled={createAccountStatus === 'loading' || !isDirty}
          >
            {createAccountStatus === 'loading' ? 'Loading...' : agentToEdit ? 'Edit agent' : 'Create Agent'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddEditAgentDialog
