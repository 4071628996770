import SuccessIcon from '@mui/icons-material/CheckCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import InfoIcon from '@mui/icons-material/Info'
import { Box, IconButton, Slide, SxProps } from '@mui/material'
import { SnackbarProvider as NotiSnackbarProvider, SnackbarKey, SnackbarProviderProps, useSnackbar } from 'notistack'
import { useRef } from 'react'

const sharedIconProps: SxProps = {
  mr: 2,
  height: '24px',
  width: '24px'
}

const SnackbarProvider = (props: SnackbarProviderProps) => {
  const notistackRef = useRef<any>()

  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <Box
      height="100%"
      sx={{
        '& .notistack-MuiContent': {
          border: '1px solid',
          borderRadius: '2px',
          flexWrap: 'nowrap',
          fontSize: '16px'
        },
        '& .notistack-MuiContent-success': {
          backgroundColor: '##3AA93E',
          borderColor: '#3AA93E'
        },
        '& .notistack-MuiContent-error': {
          backgroundColor: '#E54C17',
          borderColor: '#E54C17'
        },
        '& .notistack-MuiContent-warning': {
          backgroundColor: '#F6BE00',
          borderColor: '#F6BE00'
        },
        '& .notistack-MuiContent-info': {
          backgroundColor: '#1890FF',
          borderColor: '#1890FF'
        }
      }}
    >
      <NotiSnackbarProvider
        ref={notistackRef}
        variant="success"
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top'
        }}
        autoHideDuration={5000}
        iconVariant={{
          success: <SuccessIcon sx={{ ...sharedIconProps }} />,
          error: <ErrorIcon sx={{ ...sharedIconProps }} />,
          info: <InfoIcon sx={{ ...sharedIconProps }} />,
          warning: <ErrorIcon sx={{ ...sharedIconProps }} />
        }}
        TransitionComponent={Slide}
        action={(key: SnackbarKey) => (
          <IconButton
            onClick={onClickDismiss(key)}
            sx={{
              p: 1
            }}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )}
        {...props}
      />
    </Box>
  )
}

export { SnackbarProvider, useSnackbar }
export type SnackbarKeyType = SnackbarKey;
