import { TextField, InputLabel, SxProps, Stack, Typography, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FieldError } from 'react-hook-form'

interface Props {
  label: string
  required?: boolean
  value: string
  placeholder?: string
  name: string
  helperText?: string
  handleChange: (value: string, name: string) => void
  mb?: boolean
  mt?: boolean
  labelSx?: SxProps
  inputSx?: SxProps
  multiline?: boolean
  disabled?: boolean
  maxLength?: number
  error?: FieldError
}

const Input = ({
  label,
  required = false,
  value,
  placeholder,
  handleChange,
  name,
  helperText,
  mt,
  mb,
  inputSx,
  labelSx,
  multiline,
  disabled,
  maxLength,
  error
}: Props) => {
  const [localValue, setLocalValue] = useState(value || '')

  useEffect(() => {
    if (value && localValue !== value) { // reload local value on mount
      setLocalValue(value)
    }
  }, [])

  return <Box sx={{ mb: mb ? 3 : 0, cursor: disabled ? 'not-allowed' : 'inherit' }}>
    <Stack direction="row" justifyContent="space-between">
      <InputLabel required={required} sx={{
        '& .MuiInputLabel-asterisk': {
          position: 'absolute',
          left: 0,
          top: '2.5px',
          fontSize: '16px',
          color: '#E54C17'
        },
        color: 'black',
        overflow: 'visible',
        textOverflow: 'unset',
        whiteSpace: 'pre-wrap',
        fontSize: '14px',
        mt: mt ? 3 : 0,
        paddingLeft: required ? '13px' : 0,
        mb: 1.5,
        ...labelSx
      }}>
        {label}
      </InputLabel>
      {maxLength !== undefined && !disabled
        ? <InputLabel sx={{
          color: '#00000078',
          overflow: 'visible',
          textOverflow: 'unset',
          whiteSpace: 'pre-wrap',
          fontSize: '14px',
          mt: mt ? 3 : 0,
          paddingLeft: required ? '13px' : 0,
          mb: 1.5,
          cursor: disabled ? 'not-allowed' : 'inherit',
          ...labelSx
        }}>
        {`(chars left: ${maxLength - localValue.length})`}
      </InputLabel>
        : null}
    </Stack>
    <TextField
      fullWidth
      variant='outlined'
      required={required}
      value={value}
      placeholder={placeholder}
      type="text"
      size='small'
      name={name}
      inputProps={{ maxLength }}
      id={name}
      disabled={disabled}
      error={!!error}
      multiline={multiline}
      maxRows={multiline ? 5 : undefined}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        setLocalValue(value)
        handleChange(value, name)
      }}
      // onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
      //   const { name } = e.target

      //   handleChange(localValue, name)
      // }}
      helperText={helperText}
      sx={{
        cursor: disabled ? 'not-allowed' : 'inherit',

        '& .MuiInputBase-root': {
          height: multiline ? '200px' : undefined,
          padding: multiline ? '0' : 'initial',
          cursor: disabled ? 'not-allowed' : 'inherit',

          '& input': {
            cursor: disabled ? 'not-allowed' : 'inherit'
          },

          '& textarea': {
            cursor: disabled ? 'not-allowed' : 'inherit',
            height: '95% !important',
            overflow: multiline ? 'auto !important' : 'initial',
            paddingLeft: '16px'
          }
        },
        '& .MuiFormHelperText-root': {
          cursor: disabled ? 'not-allowed' : 'inherit',
          color: '#00000078',
          fontSize: '14px'
        },

        ...inputSx
      }}
    />
    <Typography variant="body2" color="#E54C17">{error?.message}</Typography>
  </Box>
}

export default Input
