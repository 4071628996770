
import SyncIcon from '@mui/icons-material/Sync'
import { Box, Typography, Button } from '@mui/material'
import { Link } from 'react-router-dom'

import Popup from '../Popup/Popup'

type Props = {
  header?: string;
  message: string;
};

const ErrorDocument = ({ header = 'Document Error', message }: Props) => {
  return (
    <Box
      position="fixed"
      p={2}
      top={200}
      left="50%"
      sx={{
        transform: 'translateX(-50%)'
      }}
    >
      <Popup header={header}>
        <Typography
          variant="body2"
          sx={{
            mb: 4
          }}
        >
          {message}
        </Typography>
        <Box textAlign="center">
          <Button endIcon={<SyncIcon />} component={Link} to="/" variant="contained" size="small">
            Reload
          </Button>
        </Box>
      </Popup>
    </Box>
  )
}

export default ErrorDocument
