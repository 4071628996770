import { useContext } from 'react'

import { CasSettingsContext } from '../../contexts'

function useCasSettings () {
  const data = useContext(CasSettingsContext)

  return data
}

export default useCasSettings
