import { createContext } from 'react'

import { DashboardConfiguration } from '../../services/types'

export type DashboardDataContextData = {
  isLoading: boolean
  data: DashboardConfiguration | undefined
  applicationFormSubmitted: boolean
}

const DashboardDataContext = createContext({} as DashboardDataContextData)

export default DashboardDataContext
