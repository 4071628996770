import { createQueryHook } from '../../createQueryHook'
import { ConditionalOfferForm } from '../../types'

type Props = {
  id: string;
};

export default createQueryHook<Props, ConditionalOfferForm>({
  getKey: ({ id }) => [`app/conditional-form/${id}`],
  getEndpoint: ({ id }) => `app/conditional-form/${id}`
})
