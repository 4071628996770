import { createMutationHook } from '../../createMutationHook'
import { CasSettings } from '../../types'
import { useFetchCasSettings } from '../queries'

export default createMutationHook<CasSettings, any>({
  httpMethod: 'POST',
  getEndpoint: () => 'cas-settings',
  getApiClientConfig: (payload) => {
    return {
      data: payload
    }
  },
  getQueryOptions: (queryClient) => ({
    onSuccess: (_data) => {
      queryClient.invalidateQueries(useFetchCasSettings.getKey(undefined))
    }
  })
})
