/* eslint-disable */

import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { BloomInput, BloomSelect, Header, Paper, Text } from '../../../components'
import { YesNo } from '../../../utils/constants'
import { useEnrolmentForm, useSubmitEnrolmentForm } from '../../../services/hooks'
import { EnrolmentForm } from '../../../services/types'
import { EMAIL_VALIDATION_PATTERN } from '../../../utils/validations'
import { useApplicationForm } from '../../../hooks'

const getCountriesList = (getStepConfig: any, getFieldConfig: any) => {
  const FIELDS_CONFIG_SECTION_NAME = 'Nationality Details'
  const FORM_SECTION_ID = 'nationalityDetails'
  const stepConfig = getStepConfig(FIELDS_CONFIG_SECTION_NAME)
  return getFieldConfig('CountryOfBirth', stepConfig).possibleValues
}

const EnrolmentFormComponent = () => {
  const { search } = useLocation()
  const values = search.split('&')

  const referenceId = values[0]?.split('id=')[1] || ''

  const { data, isFetched, isLoading } = useEnrolmentForm({ id: referenceId })
  const { mutateAsync: submitEnrolmentForm  } = useSubmitEnrolmentForm()
  const { getStepConfig, getFieldConfig } = useApplicationForm()
  const [formRequestStatus, setFormRequestStatus] = useState('success')
  const [error, setError] = useState<string>()
  const navigate = useNavigate()

  const countries = getCountriesList(getStepConfig, getFieldConfig)

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<EnrolmentForm>({ mode: 'onChange' })

  const onSubmit: SubmitHandler<FieldValues> = async (fieldValues: FieldValues, e) => {
    try {
      const typed = fieldValues as EnrolmentForm

      e?.preventDefault()

      setFormRequestStatus('loading')

      await submitEnrolmentForm({ form: { ...data, ...typed } })

      setFormRequestStatus('success')
      navigate('/applicant/dashboard')
    } catch (e) {
      setFormRequestStatus('failed')
      setError('Offer form submission failed. Try again or contact administration.')
    }
  }

  useEffect(() => {
    if (isFetched && !data) {
      navigate('/applicant/dashboard')
    }
  }, [isFetched, data])

  return (
          <Stack direction="column" sx={{ pl: 6, flex: 1 }}>
            <Header>Enrolment Form</Header>
            <Paper sx={{ pl: 6, width: '100%', position: 'relative' }}>
              <Typography variant="body1" color='error.dark' sx={{ position: 'absolute', right: '36px' }}>All fields with an * are required</Typography>
              {!data ? <CircularProgress
                  sx={{
                    top: '200px',
                    left: 0,
                    right: 0,
                    margin: '0 auto'
                  }}
                /> :
              <form onSubmit={handleSubmit(onSubmit)}>
                <Header sx={{ mt: 1 }}>Additional Information</Header>
                <Text>
                  Please provide details of an emergency contact person (i.e. your next of kin).  We would only contact this person in an emergency situation.  Your details will not otherwise be provided to this person without your express permission, and under no circumstances will we discuss your performance on the course with this person.
                </Text>
                <br/>
                <Controller
                  control={control}
                  name="additionalInformation.familyHigherEducation"
                  rules={{
                    required: 'This value is required',
                  }}
                  render={({ field }) => (
                    <BloomSelect
                      required
                      handleSelectChange={field.onChange}
                      value={field.value}
                      labelId="additionalInformation.familyHigherEducation"
                      name="additionalInformation.familyHigherEducation"
                      label="Family Members who have entered Higher Education"
                      helperText='Do any of your parents (natural parents, adoptive parents, step-parents or guardians who have brought you up) have any higher education qualification, such as a degree, diploma or certificate of higher education?'
                      mb
                      options={[
                        'Yes',
                        'No',
                        'Don\'t know',
                        'Prefer not to say',
                      ]}
                    />
                  )}
                />
                <Header sx={{ mt: 2 }}>Emergency Contact (Next of Kin)</Header>
                <Controller
                  control={control}
                  name="additionalInformation.emergencyFirstName"
                  rules={{
                    required: 'First name details is required',
                    maxLength: {
                      value: 30,
                      message: 'Max length is 30'
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <BloomInput
                      required
                      maxLength={30}
                      handleChange={field.onChange}
                      label='First Name'
                      value={field.value}
                      name="additionalInformation.emergencyFirstName"
                      error={error || errors.additionalInformation?.emergencyFirstName}
                      mb
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="additionalInformation.emergencyLastName"
                  rules={{
                    required: 'Last name details is required',
                    maxLength: {
                      value: 30,
                      message: 'Max length is 30'
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <BloomInput
                      required
                      maxLength={30}
                      handleChange={field.onChange}
                      label='Last Name'
                      value={field.value}
                      name="additionalInformation.emergencyLastName"
                      error={error || errors.additionalInformation?.emergencyLastName}
                      mb
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="additionalInformation.emergencyHouseNumber"
                  rules={{
                    maxLength: {
                      value: 30,
                      message: 'Max length is 30'
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <BloomInput
                      maxLength={30}
                      handleChange={field.onChange}
                      label='House Name/Number'
                      value={field.value}
                      name="additionalInformation.emergencyHouseNumber"
                      error={error || errors.additionalInformation?.emergencyHouseNumber}
                      mb
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="additionalInformation.emergencyStreetName"
                  rules={{
                    maxLength: {
                      value: 30,
                      message: 'Max length is 30'
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <BloomInput
                      maxLength={30}
                      handleChange={field.onChange}
                      label='Street'
                      value={field.value}
                      name="additionalInformation.emergencyStreetName"
                      error={error || errors.additionalInformation?.emergencyStreetName}
                      mb
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="additionalInformation.emergencyTownOrCity"
                  rules={{
                    maxLength: {
                      value: 30,
                      message: 'Max length is 30'
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <BloomInput
                      maxLength={30}
                      handleChange={field.onChange}
                      label='Town/City'
                      value={field.value}
                      name="additionalInformation.emergencyTownOrCity"
                      error={error || errors.additionalInformation?.emergencyTownOrCity}
                      mb
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="additionalInformation.emergencyPostCode"
                  rules={{
                    maxLength: {
                      value: 30,
                      message: 'Max length is 30'
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <BloomInput
                      maxLength={30}
                      handleChange={field.onChange}
                      label='Postal Code (UK Only)'
                      value={field.value}
                      name="additionalInformation.emergencyPostCode"
                      error={error || errors.additionalInformation?.emergencyPostCode}
                      mb
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="additionalInformation.emergencyCountry"
                  rules={{
                    required: 'This value is required',
                  }}
                  render={({ field }) => (
                    <BloomSelect
                      required
                      handleSelectChange={field.onChange}
                      value={field.value}
                      labelId="additionalInformation.emergencyCountry"
                      name="additionalInformation.emergencyCountry"
                      label="Country"
                      mb
                      options={countries}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="additionalInformation.emergencyEmail"
                  rules={{
                    required: "Email is required",
                    maxLength: {
                      value: 50,
                      message: 'Max length is 50'
                    },
                    pattern: {
                      value: EMAIL_VALIDATION_PATTERN,
                      message: 'Entered value does not match email format'
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <BloomInput
                      required
                      maxLength={50}
                      handleChange={field.onChange}
                      label='Email'
                      value={field.value}
                      name="additionalInformation.emergencyEmail"
                      error={error || errors.additionalInformation?.emergencyEmail}
                      mb
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="additionalInformation.emergencyMobile"
                  rules={{
                    required: "Mobile phone is required",
                    maxLength: {
                      value: 40,
                      message: 'Max length is 40'
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <BloomInput
                      required
                      maxLength={40}
                      handleChange={field.onChange}
                      label='Phone/Mobile'
                      value={field.value}
                      name="additionalInformation.emergencyMobile"
                      error={error || errors.additionalInformation?.emergencyMobile}
                      mb
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="additionalInformation.emergencyRelationshipToApplicant"
                  rules={{
                    required: "Relationship value is required",
                    maxLength: {
                      value: 30,
                      message: 'Max length is 30'
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <BloomInput
                      required
                      maxLength={30}
                      handleChange={field.onChange}
                      label='Relationship to You'
                      value={field.value}
                      name="additionalInformation.emergencyRelationshipToApplicant"
                      error={error || errors.additionalInformation?.emergencyRelationshipToApplicant}
                      mb
                    />
                  )}
                />
                <Header sx={{ mt: 2 }}>Enrolment</Header>
                <Text>
                  State whether you wish to enroll on the course.  If you state that you wish to enroll on the course, we will then issue your CAS.
                </Text>
                <br/>
                <Controller
                  control={control}
                  name="offerAccepted"
                  rules={{
                    required: 'This value is required',
                  }}
                  render={({ field }) => (
                    <BloomSelect
                      required
                      handleSelectChange={field.onChange}
                      value={field.value}
                      labelId="offerAccepted"
                      name="offerAccepted"
                      label="Enrolment Accepted"
                      mb
                      options={[
                        'Yes, I would like to enrol and be issued with my CAS',
                        'No, I do not wish to enrol and would like to be withdrawn from the course ',
                      ]}
                    />
                  )}
                />
                <Header sx={{ mt: 1 }}>Terms and Conditions</Header>
                <Text>
                  Your offer of a place on the course is subject to Bloomsbury Institute's Terms and Condtions.
                  <br/>
                  <br/>
                  We draw your attention to term 10.1 of our Terms and Conditions that provides that if you fail to engage with your studies without good cause and due
                  notice by the end of the sixth week of the advertised start date of your course, you will be deemed to have withdrawn from the course and your registration will be terminated.
                  If you are withdrawn for failing to engage with your studies you will remain liable for the full tuition fee.
                  <br/>
                  <br/>
                  Please select YES below to confirm you have read the Terms and Conditions and that you accept them.  If you have either not read the Terms and Conditions, or you do not accept them, select NO.
                  If you select NO you will not be entitled to start the course.
                  <br/>
                </Text>
                <br/>
                <Controller
                  control={control}
                  name="termsAndConditionsAccepted"
                  rules={{
                    required: 'This value is required',
                  }}
                  render={({ field }) => {
                    return <BloomSelect
                      required
                      handleSelectChange={field.onChange}
                      value={field.value}
                      labelId="termsAndConditionsAccepted"
                      name="termsAndConditionsAccepted"
                      label="I have read and accept the Terms and Conditions of Bloomsbury Institute"
                      mb
                      options={YesNo}
                    />
                  }}
                />
                <Button
                  endIcon={<ArrowUpwardIcon/>}
                  type="submit"
                  variant='contained'
                  sx={{ textTransform: 'none', mt: '16px', width: "fit-content" }}
                  disabled={formRequestStatus === 'loading' || !isValid}
                >
                  {formRequestStatus === 'loading' ? 'Loading...' : 'Submit form'}
                </Button>
              </form>
            }
            </Paper>
          </Stack>
  )
}

export default EnrolmentFormComponent
