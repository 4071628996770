import { useContext } from 'react'

import { UserDataContext } from '../../contexts'

function useUserData () {
  const data = useContext(UserDataContext)

  return data
}

export default useUserData
