export const convertSelectOptionToFormKey = (text: string, replaceIf?: string[][]): string => {
  const key = text.replaceAll('-', '').replaceAll(' ', '')

  if (!replaceIf) {
    return key
  }

  const replacePair = replaceIf.find(replacePair => replacePair[0] === key)

  return replacePair ? replacePair[1] : key
}

export const decapitalizeFirstLetter = (text: string) => {
  return text
    .split('')
    .map((char, index) => index === 0 ? char.toLowerCase() : char)
    .join('')
}
