import { Box, Stack, Typography } from '@mui/material'

import { useApplicationForm } from '../../../hooks/useApplicationForm'
import { stepsNames } from '../../../utils/constants'
import { Paper } from '../../Paper'

const ProgressIndicator = () => {
  const { activeStep, goToStep } = useApplicationForm()

  const blacklist = ['Summary']

  return (
    <Paper sx={{ width: '223px', mt: 8, height: 'fit-content' }}>
      {stepsNames.filter(step => !blacklist.includes(step)).map((step, index) =>
        <Stack onClick={() => goToStep(index)} direction="row" sx={{ mb: 1.5, alignItems: 'center', cursor: 'pointer' }} key={step}>
          <Box
            sx={{
              width: '32px',
              height: '32px',
              minWidth: '32px',
              borderRadius: '100%',
              border: activeStep === index ? 'none' : '1px solid rgba(0, 0, 0, 0.25)',
              background: activeStep === index ? '#1890FF' : 'transparent',
              color: activeStep === index ? 'white' : '#00000073',
              marginRight: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {index + 1}
          </Box>
          <Typography variant="body1" sx={{ color: activeStep === index ? 'inherit' : '#00000073' }}>
            {step}
          </Typography>
        </Stack>
      )}
    </Paper>
  )
}

export default ProgressIndicator
