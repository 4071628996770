import LoginIcon from '@mui/icons-material/Login'
import { Stack, Typography, Box, Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function EmailConfirmation () {
  const navigate = useNavigate()

  const goToLogin = () => {
    navigate('/login')
  }

  return (
    <Stack direction="column" sx={{
      maxWidth: '560px',
      width: '70%',
      height: 'calc(100vh - 70px)',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Box>
        <Typography variant='h3' textAlign="center">Registration successful</Typography>
        <Typography variant='body1' textAlign="center" sx={{ mt: 2 }} textTransform="uppercase">Please check your email account.</Typography>
        <Typography variant='body1' textAlign="center" textTransform="uppercase">We require you to verify your email before you can login.</Typography>
        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <Typography variant='body2' sx={{ mt: 2 }}>
            Click the button to go to login page
          </Typography>
          <Button
            endIcon={<LoginIcon />}
            onClick={goToLogin}
            variant='contained'
            sx={{ textTransform: 'none', mt: 3, mb: 10 }}
          >
            Go to login
          </Button>
        </Box>
      </Box>
    </Stack>
  )
}

export default EmailConfirmation
