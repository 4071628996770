import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box, Stack, Typography } from '@mui/material'

import { Text, Header, FormInformation } from '../../../components'
import { useApplicationForm } from '../../../hooks/useApplicationForm'

const Summary = () => {
  const { formSummary } = useApplicationForm()

  if (!formSummary) {
    return null
  }

  return (
    <Stack>
      <Header>Form Summary</Header>
      <Text>Your form was successfully saved. See below for a list of the form sections and their status accordingly. Please note that in order for a section to be saved, each and every required field in that section must be completed.</Text>
      <br/>
      {
        formSummary.sections.map(section => {
          return <FormInformation sx={{ mb: 1.5, mt: 1.5, alignItems: 'center', lineHeight: 1 }} key={section.verboseMessage} type={section.message.toLowerCase().includes('complete') ? 'complete' : 'important'}>
            <Box sx={{ flexWrap: 'wrap', width: '100%', display: 'flex' }}>
            {section.sectionPath.map((pathPart, index) => (
              <Box key={pathPart + index} sx={{ display: 'flex', flex: 0, flexDirection: 'row', minWidth: 'fit-content' }}>
                <Typography variant='body1'>{pathPart}</Typography>
                <KeyboardArrowRightIcon/>
              </Box>
            ))}
            <Typography variant='body1' sx={{ display: 'flex', flex: 1, flexDirection: 'row', minWidth: 'fit-content' }}>{section.message}</Typography>
            </Box>
          </FormInformation>
        })
      }
    </Stack>
  )
}

export default Summary
