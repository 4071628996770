import { Box, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'

import { ApplicationFormControlBar, ApplicationProgressIndicator, ApplicationFormStep } from '../../../components'
import { useUserSession } from '../../../hooks'
import { AppViewMode } from '../../../providers/AuthProvider/AuthProvider'

const ApplicationForm = () => {
  const { setViewMode } = useUserSession()

  useEffect(() => {
    setViewMode(AppViewMode.form)
  }, [])

  return (
      <Box sx={{ width: '100%', pt: 1 }}>
        <Typography
          sx={{
            mb: 2,
            position: 'relative',
            left: '0px',
            top: '0px',
            fontWeight: 700
          }}
          variant="body2"
          color="primary"
        >
          Application Form
        </Typography>
        <Stack direction="row">
          <ApplicationProgressIndicator />
          <Stack direction="column" sx={{ pl: 8, flex: 1, maxWidth: 'calc(100% - 222px)' }}>
            <ApplicationFormControlBar />
            <ApplicationFormStep />
            <ApplicationFormControlBar sx={{ mt: 4, mb: 4 }}/>
          </Stack>
        </Stack>
      </Box>
  )
}

export default ApplicationForm
