import { SxProps, Typography } from '@mui/material'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  sx?: SxProps
}

const SectionHeader = ({ children, sx }: Props) => <Typography variant="h6" color='text.primary' mt={2.5} mb={1} sx={{ ...sx }}>{children}</Typography>

export default SectionHeader
