import { createMutationHook } from '../../createMutationHook'

export type Response = any

export default createMutationHook<{
  emailAddress: string,
}, Response>({
  httpMethod: 'POST',
  getEndpoint: () => 'app/account-extensions/reset-password',
  getApiClientConfig: (payload) => {
    return {
      data: payload
    }
  }
})
