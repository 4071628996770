import { createMutationHook } from '../../createMutationHook'
import { ReferenceForm } from '../../types'

interface Payload {
  form: ReferenceForm
  code: string
}

export default createMutationHook<Payload, { success: boolean }>({
  httpMethod: 'POST',
  getEndpoint: ({ code }) => `app/reference-form/submit-form/${code}`,
  getApiClientConfig: (payload) => {
    return {
      data: payload.form
    }
  }
})
