/* eslint-disable react/display-name */

import SuccessIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import FileUploadIcon from '@mui/icons-material/FileUpload'
// import SwapVertIcon from '@mui/icons-material/SwapVert'
import { Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
// @ts-ignore
import Files from 'react-files'

import { Header } from '../../../components'
import { useApplicationForm } from '../../../hooks/useApplicationForm'
import { useDownloadRequiredDocument, useUploadRequiredDocument } from '../../../services/hooks'
import useRequiredDocuments from '../../../services/hooks/queries/useRequiredDocuments'
import { parseDate } from '../../../utils/date'
import { base64ToArrayBuffer, saveByteArray, ALLOWED_FILE_TYPES } from '../../../utils/file'

const uploadContainerStyles = {
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  cursor: 'pointer',
  justifyItems: 'flex-start',
  width: '120px',

  '&  > div': {
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: '13px'
  }
}

const getColumns = (onUploadFilesChange: any, onDownloadFileClick: any, handleError: any): GridColDef[] => {
  return [
    {
      field: 'name',
      flex: 1,
      headerName: 'Document name',
      editable: true
    },
    {
      field: 'instruction',
      flex: 1,
      headerName: 'Instructions',
      editable: true
    },
    {
      field: 'deadline',
      flex: 1,
      headerName: 'Deadline',
      renderCell: (params) => (
        <Typography variant="body2">{parseDate(params.row.deadline)}</Typography>
      )
    },
    {
      field: 'status',
      flex: 1,
      headerName: 'Status',
      sortable: false,
      renderCell: (params) => (
        <Stack direction="row">
          { !params.row.documentName ? <ErrorIcon color="error" sx={{ mr: 1 }} /> : <SuccessIcon color="success" sx={{ mr: 1 }} /> }
          { !params.row.documentName ? <Typography variant="body2" color="error.main" sx={{ position: 'relative', top: '2px' }}>Not uploaded</Typography> : <Typography variant="body2" color="success.main" sx={{ position: 'relative', top: '2px' }}>Uploaded</Typography> }
        </Stack>
      )
    },
    {
      field: 'documentName',
      flex: 1,
      headerName: 'Document',
      sortable: false,
      renderCell: (params) => {
        return params.row.documentName
          ? <Stack direction="column" sx={{ maxWidth: '100%' }}>
            <Typography variant="body2" fontWeight="bold" sx={{ wordWrap: 'break-word', mb: 0.5 }}>{params.row.documentName}</Typography>
            <Typography variant="body1" fontSize="12px">Added on {parseDate(params.row.addedOn)}</Typography>
          </Stack>
          : <></>
      }
    },
    {
      field: '-',
      flex: 1,
      headerName: '',
      sortable: false,
      renderCell: (params) => {
        return params.row.documentName
          ? <Stack direction="column" sx={{ width: '100%' }}>
              <Box onClick={() => onDownloadFileClick(params.row.id, params.row.documentName)} sx={{ display: 'flex', flex: 1, flexDirection: 'row', cursor: 'pointer', justifyItems: 'flex-start', width: '120px', mb: 1, paddingLeft: '13px' }}>
                <FileDownloadIcon sx={{ mr: 1 }}/>
                <Typography variant="body1" fontSize="12px">Download file</Typography>
              </Box>
            </Stack>
          : <Box sx={uploadContainerStyles}>
              <Files
                onChange={(files: File[]) => onUploadFilesChange(files, params.row.id)}
                onError={handleError}
                accepts={ALLOWED_FILE_TYPES}
                multiple={false}
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <FileUploadIcon sx={{ mr: 1 }}/>
                <Typography variant="body1" fontSize="12px">Upload file</Typography>
              </Files>
            </Box>
      }
    }
  ]
}

const RequiredDocuments = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { form } = useApplicationForm()
  const { mutateAsync: uploadDocument } = useUploadRequiredDocument()
  const { mutateAsync: downloadDocument } = useDownloadRequiredDocument()
  const { refetch, data } = useRequiredDocuments({
    applicationId: form?.id.toString() || ''
  })

  useEffect(() => {
    if (form && form.id) {
      refetch()
    }
  },
  [form?.id])

  const onUploadFilesChange = async (files: File[], requirementId: number) => {
    try {
      const file = files[0]

      await uploadDocument({ requirementId, file, applicationId: form?.id.toString() || '' })

      refetch()
      enqueueSnackbar('File uploaded successfully!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong.', { variant: 'error' })
    }
  }

  const onDownloadFileClick = async (requirementId: number, documentName: string) => {
    try {
      const response = await downloadDocument({ requirementId })

      const sampleArr = base64ToArrayBuffer(response.content)
      saveByteArray(documentName, sampleArr)

      enqueueSnackbar('File downloaded successfully!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong.', { variant: 'error' })
      // TODO: go through all catches or calls to api and add snackbars
    }
  }

  const handleError = (error: any, file: File) => {
    console.log(file)
    console.log('error code ' + error.code + ': ' + error.message)
  }

  return (
    <Box sx={{ pl: 6, width: '100%', position: 'relative' }}>
      <Header>Required Documents</Header>
      <DataGrid
        rows={data || []}
        columns={getColumns(onUploadFilesChange, onDownloadFileClick, handleError)}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20
            }
          }
        }}
        pageSizeOptions={[20, 40, 100]}
        disableRowSelectionOnClick
        sx={{
          maxWidth: '100% !important',
          '& .MuiDataGrid-columnHeaders': {
            background: '#EC6605 !important'
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: 'white'
          },
          '& .MuiDataGrid-columnHeader--moving': {
            background: '#EC6605 !important'
          },
          '& .MuiDataGrid-cellContent': {
            paddingTop: 1,
            paddingBottom: 1
          },
          '& .MuiDataGrid-virtualScroller': {
            overflowX: 'hidden'
          },
          '& .MuiTablePagination-root': {
            color: 'black !important'
          },
          '& .MuiDataGrid-row:nth-child(odd)': {
            backgroundColor: '#F5F5F5 !important'
          }
        }}
      />
    </Box>
  )
}

export default RequiredDocuments
