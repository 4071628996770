import { Box, Typography } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  header: string;
  children: ReactNode;
};

const Popup = ({ header, children }: Props) => {
  return (
    <Box
      bgcolor="#E7E7E7"
      border="1px solid white"
      py={5.5}
      px={10}
      textAlign="center"
      boxShadow="0px 2px 10px 8px rgba(0, 0, 0, 0.25), 0px 1px 3px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)"
      borderRadius={1}
    >
      <Typography
        variant="h4"
        textTransform="uppercase"
        sx={{
          marginBottom: 4
        }}
      >
        {header}
      </Typography>
      {children}
    </Box>
  )
}

export default Popup
