import { createContext, Dispatch, SetStateAction } from 'react'

import { AppViewMode } from '../../providers/AuthProvider/AuthProvider'

export type AuthUser = {
  email: string
  permissions: string[]
  roles: string[]
}

export type SignInCredentials = {
  emailAddress: string
  password: string
}

export type RegisterCredentials = {
  userName: string
  emailAddress: string
  password: string
  firstName: string
  lastName:string
}

type RegisterResultData = {
  extraProperties: Record<any, string>
  id: string,
  creationTime: string,
  creatorId: string, // '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  lastModificationTime: string,
  lastModifierId: string, // '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  tenantId: string, // '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  userName: string,
  email: string,
  name: string,
  surname: string,
  emailConfirmed: true,
  phoneNumber: string,
  phoneNumberConfirmed: true,
  supportTwoFactor: true,
  twoFactorEnabled: true,
  isActive: true,
  lockoutEnabled: true,
  isLockedOut: true,
  lockoutEnd: string,
  shouldChangePasswordOnNextLogin: true,
  concurrencyStamp: string,
  roleNames: [
    string
  ],
  accessFailedCount: number,
  lastPasswordChangeTime: string
}

export type APIError = {
  code: string
  message: string
  details: any
  data: any
  validationErrors: any
}

export type RegisterResult = {
  success: boolean
  data?: RegisterResultData
  error?: APIError
}

export type SignInResult = { success: boolean, message?: string }

export type AuthContextData = {
  signIn: (token: string) => void
  signOut: () => void
  forgotPassword: (emailAddress: string) => Promise<any>
  resetPassword: (userId: string, resetToken: string, password: string) => Promise<any>
  registerWithProfile: (credentials: RegisterCredentials) => Promise<RegisterResult>
  confirmEmail: (userId: string, token: string) => Promise<{ success: boolean }>
  authUser?: AuthUser
  signInRedirect: (args?: any) => Promise<void>
  isAuthenticated: boolean
  loadingUserData: boolean
  viewMode: AppViewMode
  setViewMode: Dispatch<SetStateAction<AppViewMode>>
  stopImpersonation: () => void
  impersonate: (userToImpersonateId: string) => void
  isImpersonating: boolean
}

const AuthContext = createContext({} as AuthContextData)

export default AuthContext
