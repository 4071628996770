import React from 'react'

import { Text, Header, BloomInput, BloomSelect } from '../../../components'
import { useApplicationForm } from '../../../hooks/useApplicationForm'
import { YesNo } from '../../../utils/constants'

const FIELDS_CONFIG_SECTION_NAME = 'Reference'
const FORM_SECTION_ID = 'referenceDetails'

function Reference () {
  const { getStepConfig, getFieldConfig, form, updateFormField, isFormSubmitted } = useApplicationForm()

  const stepConfig = getStepConfig(FIELDS_CONFIG_SECTION_NAME)

  if (!stepConfig) {
    throw new Error('Form configuration for this step seems to be missing :(')
  }

  const referenceDetails = form && form[FORM_SECTION_ID]

  if (!referenceDetails) {
    return null
  }

  return (
    <>
      <Header>Letter of Recommendation</Header>
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        /* @ts-ignore */
        value={referenceDetails.letterOfRecommendation}
        labelId={`${FORM_SECTION_ID}.letterOfRecommendation`}
        name={`${FORM_SECTION_ID}.letterOfRecommendation`}
        label="Do you have a letter of recommendation?"
        mb
        options={YesNo}
      />
      {/* @ts-ignore */}
      {referenceDetails.letterOfRecommendation === YesNo[1]
        ? <>
            <Header>Reference details - General</Header>
            <Text sx={{ mb: 4 }}>
              Please provide details of an academic or professional referee. Your referee will receive an email from us asking them to complete a short form.<br/><br/>
              You must use a genuine email address. <br/><br/>
              Your referee should not be related to you.<br/><br/>
              Once you submit your application, if you prefer you can upload a Letter of Recommendation from your school,
              college, university or employer.  If you upload a Letter of Recommendation we will not need to wait
              for the reference from your referee.
            </Text>
            <BloomSelect
              disabled={isFormSubmitted}
              required
              handleSelectChange={updateFormField}
              value={referenceDetails.title}
              labelId={`${FORM_SECTION_ID}.title`}
              name={`${FORM_SECTION_ID}.title`}
              label="Title"
              mb
              options={getFieldConfig('title', stepConfig).possibleValues}
            />
            <BloomInput
              maxLength={getFieldConfig('firstName', stepConfig).maxLength}
              disabled={isFormSubmitted}
              handleChange={updateFormField}
              label='First name'
              value={referenceDetails.firstName}
              name={`${FORM_SECTION_ID}.firstName`}
              mb
              required
            />
            <BloomInput
              maxLength={getFieldConfig('lastName', stepConfig).maxLength}
              disabled={isFormSubmitted}
              handleChange={updateFormField}
              label='Last Name'
              value={referenceDetails.lastName}
              name={`${FORM_SECTION_ID}.lastName`}
              mb
              required
            />
            <BloomInput
              maxLength={getFieldConfig('email', stepConfig).maxLength}
              disabled={isFormSubmitted}
              handleChange={updateFormField}
              label='Email'
              value={referenceDetails.email}
              name={`${FORM_SECTION_ID}.email`}
              mb
              required
            />
            <BloomInput
              maxLength={getFieldConfig('phone', stepConfig).maxLength}
              disabled={isFormSubmitted}
              handleChange={updateFormField}
              label='Phone'
              value={referenceDetails.phone}
              name={`${FORM_SECTION_ID}.phone`}
              mb
            />
            <BloomInput
              maxLength={getFieldConfig('occupation', stepConfig).maxLength}
              disabled={isFormSubmitted}
              required
              handleChange={updateFormField}
              label='Position/Occupation'
              value={referenceDetails.occupation}
              name={`${FORM_SECTION_ID}.occupation`}
              mb
            />
            <BloomSelect
              disabled={isFormSubmitted}
              required
              handleSelectChange={updateFormField}
              value={referenceDetails.relationship}
              labelId={`${FORM_SECTION_ID}.relationship`}
              name={`${FORM_SECTION_ID}.relationship`}
              label="How does your referee know you?"
              mb
              options={getFieldConfig('relationship', stepConfig).possibleValues}
            />
            <BloomInput
              maxLength={getFieldConfig('relationshipDetails', stepConfig).maxLength}
              disabled={isFormSubmitted}
              handleChange={updateFormField}
              label='If you stated "Other" in the above box, please specify how your referee knows you'
              multiline
              value={referenceDetails.relationshipDetails}
              name={`${FORM_SECTION_ID}.relationshipDetails`}
              mb
            />
          </>
          /* @ts-ignore */
        : referenceDetails.letterOfRecommendation === YesNo[0] ? <Text sx={{ mt: 2, width: '100%', textAlign: 'center' }}>Please move to next form step.</Text> : null
      }
    </>
  )
}

export default Reference
