import SaveIcon from '@mui/icons-material/Save'
import { Box, Button, Stack, TextField, Typography, Link } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm, SubmitHandler, FieldValues, Controller } from 'react-hook-form'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

import { PasswordInputEndAdornment } from '../../components'
import { useUserSession } from '../../hooks'
import { CALLERS, parseBEError } from '../../utils/api'
import { EMAIL_VALIDATION_PATTERN, PASS_VALIDATION_PATTERN } from '../../utils/validations'

type FormData = {
  emailAddress: string
  firstName: string
  lastName: string
  password: string
  passwordConfirmation: string
};

function Register () {
  const {
    control,
    handleSubmit,
    formState: { isDirty, errors, isValid },
    // getValues,
    watch
  } = useForm<FormData>({ mode: 'onChange' })
  const navigate = useNavigate()
  const [registerRequestStatus, setRegisterRequestStatus] = useState('success')
  const [showPassword, setShowPassword] = useState(false)
  const { registerWithProfile } = useUserSession() // signIn
  const [error, setError] = useState<string>()

  const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues, e) => {
    const typed = data as FormData

    e?.preventDefault()

    setRegisterRequestStatus('loading')

    const result = await registerWithProfile({ ...typed, userName: typed.emailAddress })

    if (result.success) {
      setRegisterRequestStatus('success')
      navigate('/registration-success')
    } else {
      setRegisterRequestStatus('failed')
      setError(parseBEError(result.error, CALLERS.CreateAccount))
    }
  }

  useEffect(() => {
    // clean the function to fix memory leak
    return () => setRegisterRequestStatus('success')
  }, [])

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  return (
    <Stack direction="column" sx={{ maxWidth: '650px', width: '50%' }}>
      <Box sx={{ mt: 5 }}>
        {
          error &&
            <Box sx={{
              padding: 0.5,
              border: '1px solid red'
            }}>
              <Typography sx={{ width: '100%', textAlign: 'center' }} color="error">{error}</Typography>
            </Box>
        }
        <Typography variant='h1'>Create Account</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="emailAddress"
            defaultValue=""
            rules={{
              required: 'Email address is required',
              minLength: {
                value: 3,
                message: 'Minimum length is 3'
              },
              maxLength: {
                value: 100,
                message: 'Max length is 100'
              },
              pattern: {
                value: EMAIL_VALIDATION_PATTERN,
                message: 'Entered value does not match email format'
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                helperText={errors.emailAddress?.message}
                error={!!error}
                variant='outlined'
                placeholder='Email'
                type="email"
                id="email"
                disabled={registerRequestStatus === 'loading'}
                sx={{ mb: '24px !important' }}
              />
            )}
          />
          <Controller
            control={control}
            name="firstName"
            defaultValue=""
            rules={{
              required: 'First name is required',
              minLength: {
                value: 3,
                message: 'Minimum length is 3'
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                helperText={errors.firstName?.message}
                error={!!error}
                variant='outlined'
                placeholder='First Name'
                type="text"
                id="firstName"
                disabled={registerRequestStatus === 'loading'}
                sx={{ mb: '24px !important' }}
              />
            )}
          />
          <Controller
            control={control}
            name="lastName"
            defaultValue=""
            rules={{
              required: 'Last name is required',
              minLength: {
                value: 3,
                message: 'Minimum length is 3'
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                helperText={errors.lastName?.message}
                error={!!error}
                variant='outlined'
                placeholder='Last Name'
                type="text"
                id="lastName"
                disabled={registerRequestStatus === 'loading'}
                sx={{ mb: '8px !important' }}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            defaultValue=""
            rules={{
              required: 'Password is required',
              minLength: {
                value: 8,
                message: 'Minimum length is 8'
              },
              pattern: {
                value: PASS_VALIDATION_PATTERN,
                message: 'Password has to include small letter, capital letter, digit and special character'
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                sx={{ mt: 2, mb: 3 }}
                fullWidth
                helperText={errors.password?.message}
                error={!!error}
                variant='outlined'
                placeholder='Password (at least 8 characters, case sensitive)'
                id="password"
                disabled={registerRequestStatus === 'loading'}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: <PasswordInputEndAdornment
                      handleClickShowPassword={handleClickShowPassword}
                      showPassword={showPassword}
                    />
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="passwordConfirmation"
            defaultValue=""
            rules={{
              required: 'Password confirmation is required',
              minLength: {
                value: 8,
                message: 'Minimum length is 8'
              },
              validate: (val: string) => {
                if (watch('password') !== val) {
                  return 'Your passwords do no match'
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                sx={{ mb: 3 }}
                fullWidth
                variant='outlined'
                placeholder='Confirm password'
                id="passwordConfirmation"
                helperText={errors.passwordConfirmation?.message}
                error={!!error}
                disabled={registerRequestStatus === 'loading'}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: <PasswordInputEndAdornment
                      handleClickShowPassword={handleClickShowPassword}
                      showPassword={showPassword}
                    />
                }}
              />
            )}
          />
          <Button
            endIcon={<SaveIcon />}
            type="submit"
            fullWidth
            variant='contained'
            sx={{ textTransform: 'none', mt: '16px' }}
            disabled={registerRequestStatus === 'loading' || !isDirty || !isValid}
          >
            {registerRequestStatus === 'loading' ? 'Loading...' : 'Create Account'}
          </Button>
        </form>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, alignItems: 'center' }}>
          <Typography variant='body2' sx={{ mr: 1 }}>Already have an account?</Typography>
          <Link to="/login" component={RouterLink}>Sign In</Link>
        </Box>
      </Box>
    </Stack>
  )
}

export default Register
