import { useContext } from 'react'

import { ApplicationFormContext } from '../../contexts'

function useApplicationForm () {
  const data = useContext(ApplicationFormContext)

  return data
}

export default useApplicationForm
