
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import SaveIcon from '@mui/icons-material/Save'
import { Button, Stack, SxProps } from '@mui/material'

import { useApplicationForm } from '../../../hooks/useApplicationForm'

interface Props {
  sx?: SxProps
}

const ControlBar = ({ sx }: Props) => {
  const { controlsDisabled, activeStep, stepBack, stepForward, canStepForward, isSaveable, saveForm, submitForm, isFormSubmitted, isSummary } = useApplicationForm()
  const IS_DECLARATION = activeStep === 8

  return (
    <Stack sx={{ width: '100%', ...sx }} direction="row" justifyContent="space-between">
      <Button startIcon={<ArrowBackIcon />} size='small' variant="contained" disabled={activeStep < 1 || isSummary} onClick={stepBack}>Previous</Button>

      <Stack direction="row">
        { !IS_DECLARATION && <Button endIcon={<SaveIcon />} size='small' variant="outlined" color="info" disabled={controlsDisabled || !isSaveable || isFormSubmitted} onClick={saveForm}>Save</Button>}
        <Button endIcon={<ArrowForwardIcon />} sx={{ ml: 2 }} size='small' variant="contained" color="info" disabled={controlsDisabled || !canStepForward} onClick={stepForward}>Next</Button>
        {IS_DECLARATION && <Button endIcon={<ArrowUpwardIcon/>} sx={{ ml: 2 }} size='small' variant="contained" disabled={controlsDisabled || isFormSubmitted} onClick={submitForm}>Submit</Button>}
      </Stack>
    </Stack>
  )
}

export default ControlBar
