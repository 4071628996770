import DashboardIcon from '@mui/icons-material/Dashboard'
import ListAltIcon from '@mui/icons-material/ListAlt'
import LogoutIcon from '@mui/icons-material/Logout'
import ImpersonationIcon from '@mui/icons-material/TransferWithinAStation'
import { Box, Tabs, Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import { AuthUser } from '../../contexts'
import { useUserSession } from '../../hooks'
import { AppViewMode } from '../../providers/AuthProvider/AuthProvider'
import { ROLES } from '../../utils/constants'
import { decapitalizeFirstLetter } from '../../utils/string'
import { Logo } from '../Logo'
import { NavTab } from '../NavTab'

const shouldHideNavbar = () => {
  const shouldBeHiddenIf = ['email-confirmation', 'reference-form']

  return shouldBeHiddenIf.some(location => window.location.href.includes(location))
}

const getRoleNamespace = (authUser?: AuthUser) => {
  if (!authUser) {
    return
  }

  if (authUser?.roles.includes(ROLES.Applicant)) {
    return decapitalizeFirstLetter(ROLES.Applicant)
  } else if (authUser?.roles.includes(ROLES.Agent)) {
    return decapitalizeFirstLetter(ROLES.Agent)
  } else if (authUser?.roles.includes(ROLES.Admin)) {
    return decapitalizeFirstLetter(ROLES.Admin)
  }
}

function NavBar () {
  const navigate = useNavigate()
  const { isAuthenticated, signOut, viewMode, setViewMode, authUser, loadingUserData, isImpersonating, stopImpersonation } = useUserSession() // ,signInRedirect

  const roleNamespace = getRoleNamespace(authUser)

  const onChangeViewMode = () => {
    const nextViewMode = viewMode === AppViewMode.form ? AppViewMode.dashboard : AppViewMode.form

    setViewMode(nextViewMode)

    if (!roleNamespace) {
      throw new Error("User namespace can't be recognized. Make sure you are logged in and have role assigned to your user.")
    }
    navigate(`${roleNamespace}/${nextViewMode}`)
  }

  if (loadingUserData) {
    return null
  }

  return (
    <Box
      display="flex"
      flex="1"
      justifyContent="flex-start"
      minWidth={0}
      alignSelf="flex-start"
      height="90px"
      width="100%"
      alignItems="center"
    >
      <>
      {
        !isAuthenticated &&
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              display: 'flex',
              flex: 1,
              minHeight: '25px',
              justifyContent: 'space-between'
            }}
          >
                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                  <Logo />
                  {
                    shouldHideNavbar()
                      ? null
                      : <>
                          <NavTab
                            LinkComponent={Link}
                            value="login"
                            label="Sign in"
                            // onClick={() => {
                            //   signInRedirect()
                            // }}
                            to={'/login'}
                          />
                          <NavTab
                            LinkComponent={Link}
                            value="register"
                            label="Register"
                            to={'/register'}
                          />
                        </>
                  }
                </Box>
          </Tabs>
        }
        {isAuthenticated && (
          <Box sx={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex', width: '100%', flex: '1' }}>
            <Logo />
            <Box sx={{ alignItems: 'center', justifySelf: 'flex-end', display: 'flex' }}>
              { isImpersonating ? <Button endIcon={<ImpersonationIcon />} sx={{ ml: 2 }} size='small' variant="outlined" onClick={stopImpersonation}>Stop Impersonation</Button> : null }
              {
                roleNamespace === 'applicant'
                  ? <Button
                    sx={{ ml: 2 }}
                    size='small'
                    variant="contained"
                    endIcon={viewMode === AppViewMode.dashboard ? <ListAltIcon /> : <DashboardIcon />}
                    onClick={onChangeViewMode}
                  >
                    Go to {viewMode === AppViewMode.dashboard ? 'application form' : 'dashboard'}
                  </Button>
                  : null }
              <Button endIcon={<LogoutIcon />} sx={{ ml: 2 }} size='small' variant="outlined" onClick={signOut}>Logout</Button>
            </Box>
          </Box>
        )}
      </>
    </Box>
  )
}

export default NavBar
