import { createContext } from 'react'

import { CasSettings } from '../../services/types'

export type CasSettingsContextData = {
  casSettings?: CasSettings
  loadingCasSettings: boolean
  updateCasSettingsField: (value: any, path: string) => void // local update, no BE call here
  saveCasSettings: () => Promise<void>
}

const CasSettingsContext = createContext({} as CasSettingsContextData)

export default CasSettingsContext
