import LockResetIcon from '@mui/icons-material/LockReset'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import React, { useEffect, useState } from 'react'
import { useForm, SubmitHandler, FieldValues, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom' //

import { PasswordInputEndAdornment } from '../../components'
import { useConfirmationData, useUserSession } from '../../hooks'
import { PASS_VALIDATION_PATTERN } from '../../utils/validations'

type FormData = {
  password: string
  passwordConfirmation: string
};

function ResetPassword () {
  const {
    control,
    handleSubmit,
    formState: { isDirty, errors },
    // getValues,
    setError,
    watch
  } = useForm<FormData>({ mode: 'onChange' })
  const navigate = useNavigate()
  const { userId, token } = useConfirmationData()
  const [resetRequestStatus, setResetRequestStatus] = useState('success')
  const [showPassword, setShowPassword] = useState(false)
  const { resetPassword } = useUserSession()

  const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues, e) => {
    try {
      const typed = data as FormData

      e?.preventDefault()

      setResetRequestStatus('loading')

      await resetPassword(userId, token, typed.password)

      setResetRequestStatus('success')
      navigate('/login')
    } catch (e: any) {
      const error = e as AxiosError

      setResetRequestStatus('failed')
      setError('root', { message: error.message }) // TODO: manage errors here and on every page before login
    }
  }

  useEffect(() => {
    // clean the function to fix memory leak
    return () => setResetRequestStatus('success')
  }, [])

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  return (
    <Stack direction="column" sx={{ maxWidth: '650px', width: '50%' }}>
      <Box sx={{ mt: 10 }}>
        <Typography variant='h1'>Reset Password</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="password"
            defaultValue=""
            rules={{
              required: 'Password is required',
              minLength: {
                value: 8,
                message: 'Minimum length is 8'
              },
              pattern: {
                value: PASS_VALIDATION_PATTERN,
                message: 'Password has to include small letter, capital letter, digit and special character'
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                sx={{ mt: 2, mb: 3 }}
                fullWidth
                helperText={errors.password?.message}
                error={!!error}
                variant='outlined'
                placeholder='Password (at least 8 characters, case sensitive)'
                id="password"
                disabled={resetRequestStatus === 'loading'}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: <PasswordInputEndAdornment
                      handleClickShowPassword={handleClickShowPassword}
                      showPassword={showPassword}
                    />
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="passwordConfirmation"
            defaultValue=""
            rules={{
              required: 'Password confirmation is required',
              minLength: {
                value: 8,
                message: 'Minimum length is 8'
              },
              validate: (val: string) => {
                if (watch('password') !== val) {
                  return 'Your passwords do no match'
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                sx={{ mb: 3 }}
                fullWidth
                variant='outlined'
                placeholder='Confirm password'
                id="passwordConfirmation"
                helperText={errors.passwordConfirmation?.message}
                error={!!error}
                disabled={resetRequestStatus === 'loading'}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: <PasswordInputEndAdornment
                      handleClickShowPassword={handleClickShowPassword}
                      showPassword={showPassword}
                    />
                }}
              />
            )}
          />
          {errors.root?.message && <Typography color="error">{errors.root?.message}</Typography>}
          <Button
            endIcon={<LockResetIcon />}
            type="submit"
            fullWidth
            variant='contained'
            sx={{ textTransform: 'none', mt: '16px' }}
            disabled={resetRequestStatus === 'loading' || !isDirty}
          >
            {resetRequestStatus === 'loading' ? 'Loading...' : 'Reset password'}
          </Button>
        </form>
      </Box>
    </Stack>
  )
}

export default ResetPassword
