import LoginIcon from '@mui/icons-material/Login'
import { Box, Button, Stack, TextField, Typography, Link } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm, SubmitHandler, FieldValues, Controller } from 'react-hook-form'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

import { useUserSession } from '../../hooks'
import { EMAIL_VALIDATION_PATTERN } from '../../utils/validations'

type FormData = {
  email: string
};

function ForgotPassword () {
  const [forgotRequestStatus, setForgotRequestStatus] = useState('untouched')
  const {
    control,
    handleSubmit,
    formState: { errors: { email: emailError }, isDirty, isValid },
    watch
  } = useForm<FormData>({ defaultValues: { email: '' }, mode: 'onChange' })
  const { forgotPassword } = useUserSession()
  const navigate = useNavigate()
  const [error, setError] = useState<string>()

  const email = watch('email')

  const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => {
    setForgotRequestStatus('loading')

    const result = await forgotPassword(data.email)

    if (result.success) {
      setForgotRequestStatus('success')
      navigate('/password-forgot-success')
    } else {
      setForgotRequestStatus('failed')
      setError('Sorry, something went wrong.')
    }
  }

  useEffect(() => {
    // clean the function to fix memory leak
    return () => setForgotRequestStatus('success')
  }, [])

  return (
    <Stack direction="column" sx={{ maxWidth: '650px', width: '50%' }}>
      {forgotRequestStatus === 'success'
        ? <Box sx={{ mt: 5, textAlgin: 'center', alignItems: 'center' }}>
          <Typography variant='h1' textAlign="center">The link has been sent</Typography>
          <Typography variant='body2' textAlign="center">To reset your password, please enter your email address and submit. Can’t find the link? Check spam folder.</Typography>
          <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
            <Typography variant='body2' sx={{ mt: 10 }}>
              Haven’t received the link?
            </Typography>
            <Button
              endIcon={<LoginIcon />}
              type="submit"
              variant='contained'
              sx={{ textTransform: 'none', mt: '16px', mb: 5 }}
            >
              Click to send it again
            </Button>
            <Link to="/login" component={RouterLink}>Login Page</Link>
          </Box>
        </Box>
        : <Box sx={{ mt: 5 }}>
          {
            error &&
              <Box sx={{
                padding: 0.5,
                border: '1px solid red'
              }}>
                <Typography sx={{ width: '100%', textAlign: 'center' }} color="error">{error}</Typography>
              </Box>
          }
          <Typography variant='h1'>Forgot Password?</Typography>
          <Typography variant='body2'>Enter your email and we’ll send you a link to reset your password.</Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Controller
                control={control}
                name="email"
                defaultValue=""
                rules={{
                  required: 'Email address is required',
                  minLength: {
                    value: 3,
                    message: 'Minimum length is 3'
                  },
                  maxLength: {
                    value: 100,
                    message: 'Max length is 100'
                  },
                  pattern: {
                    value: EMAIL_VALIDATION_PATTERN,
                    message: 'Entered value does not match email format'
                  }
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{ mt: 7, mb: 13.5 }}
                    helperText={emailError?.message}
                    error={!!error}
                    variant='outlined'
                    placeholder='Email'
                    type="email"
                    id="email"
                    disabled={forgotRequestStatus === 'loading'}
                  />
                )}
              />
            </Box>
            <Button
              type="submit"
              fullWidth
              variant='contained'
              sx={{ textTransform: 'none', mt: '16px' }}
              disabled={forgotRequestStatus === 'loading' || !!emailError || !email || !isDirty || !isValid}
            >
              {forgotRequestStatus === 'loading' ? 'Loading...' : 'Send Link'}
            </Button>
          </form>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
            <Link to="/login" component={RouterLink}>Login Page</Link>
          </Box>
        </Box>
      }
    </Stack>
  )
}

export default ForgotPassword
