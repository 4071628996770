import { FormEvent, createContext } from 'react'

import { ApplicationForm, FieldConfiguration, FormSummary } from '../../services/types'

export type ApplicationFormContextData = {
  activeStep: number
  stepBack: () => void
  stepForward: () => void
  goToStep: (index: number) => void
  canStepForward: boolean;
  isSaveable: boolean;
  getFieldConfig: (name: string, providedSectionConfig?: FieldConfiguration[], sectionPath?: string[]) => FieldConfiguration
  getStepConfig: (sectionName: string) => FieldConfiguration[]
  form: ApplicationForm | undefined
  updateFormField: (value: any, path: string) => void // local update, no BE call here
  submitForm: (e: FormEvent) => Promise<void>
  saveForm: () => Promise<boolean>
  isFormLoading: boolean
  formSummary: FormSummary | undefined
  isFormSubmitted: boolean
  isSummary: boolean
  controlsDisabled: boolean
}

const ApplicationFormContext = createContext({} as ApplicationFormContextData)

export default ApplicationFormContext
