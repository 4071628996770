import { CircularProgress } from '@mui/material'
import { ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Navigate } from 'react-router-dom'

import { ErrorDocument } from '../../components'
import { useUserSession } from '../../hooks'
import { validateUserPermissions } from '../../utils/validateUserPermissions'

type Props = {
  permissions?: string[]
  roles?: string[]
  redirectTo?: string
  children: ReactNode
}

function PrivateRoute (props: Props) {
  const { redirectTo = '/login', children, permissions, roles } = props
  const { isAuthenticated, loadingUserData, authUser: user } = useUserSession()
  const { hasAllPermissions, hasAllRoles } = validateUserPermissions({ user, permissions, roles })

  if (loadingUserData) {
    return <CircularProgress sx={{ position: 'absolute', top: '50%' }}/>
  }

  if (!isAuthenticated) {
    return <Navigate to={redirectTo} />
  }

  if (!hasAllPermissions || !hasAllRoles) {
    return <Navigate to="/" />
  }

  return <ErrorBoundary
    fallback={
      <ErrorDocument
        header="Oops, it s an Error!"
        message="Something went wrong loading this page. Please try again later."
      />
    }
  >
    {children}
  </ErrorBoundary>
}

export default PrivateRoute
