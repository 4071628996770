import { AxiosRequestConfig } from 'axios'

import { createMutationHook } from '../../createMutationHook'
import { useRequiredDocuments } from '../queries'

export default createMutationHook<{ requirementId: number, applicationId: string, file: File }, any>({
  httpMethod: 'POST',
  getEndpoint: ({ requirementId }) => `app/requirements/upload-requirement?requirementId=${requirementId}`,
  // @ts-ignore
  getApiClientConfig: (payload): AxiosRequestConfig<any> => {
    const formData = new FormData()
    formData.append('file', payload.file)

    return {
      data: formData
    }
  },
  getQueryOptions: (queryClient) => ({
    onSuccess: (_data, { applicationId }) => {
      queryClient.invalidateQueries(useRequiredDocuments.getKey({ applicationId }))
    }
  })
})
