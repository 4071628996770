import { Stack, SxProps } from '@mui/material'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  sx?: SxProps
  direction?: 'column' | 'row' | 'row-reverse' | 'column-reverse'
}

const Paper = ({ children, sx, direction }: Props) => {
  return (
    <Stack direction={direction || 'column'} sx={{
      boxShadow: '0px 4px 8px 0px #86868640;',
      border: '1px solid #E7E7E7',
      borderRadius: '12px',
      padding: '24px',
      ...sx
    }}>
      {children}
    </Stack>
  )
}

export default Paper
