import React from 'react'

import { Text, Header, BloomInput, BloomSelect, BloomDatePicker } from '../../../components'
import { useApplicationForm } from '../../../hooks/useApplicationForm'
import { YesNo } from '../../../utils/constants'

const FIELDS_CONFIG_SECTION_NAME = 'Your Details'

const FORM_SECTION_ID = 'personalDetails'

function YourDetails () {
  const { getStepConfig, getFieldConfig, form, updateFormField, isFormSubmitted } = useApplicationForm()

  const stepConfig = getStepConfig(FIELDS_CONFIG_SECTION_NAME)

  if (!stepConfig) {
    throw new Error('Form configuration for this step seems to be missing :(')
  }

  const studentReferralConfig = getFieldConfig('ApplicationSource', stepConfig)
  const countries = getFieldConfig('contactDetails.country', stepConfig).possibleValues

  const personalDetails = form && form[FORM_SECTION_ID]

  if (!personalDetails) {
    return null
  }

  return (
    <>
      <Header>Personal Details</Header>
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        value={personalDetails.title}
        labelId={`${FORM_SECTION_ID}.title`}
        name={`${FORM_SECTION_ID}.title`}
        label="Title"
        helperText='As applicable'
        mb
        options={getFieldConfig('title', stepConfig).possibleValues}
      />
      <BloomInput
        maxLength={getFieldConfig('firstName', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='First name(s)'
        helperText='As shown on Photo ID or Passport (this must be accurate as it will be used when we check qualifications and when we issue the CAS)'
        name={`${FORM_SECTION_ID}.firstName`}
        value={personalDetails.firstName}
        mb
        required
      />
      <BloomInput
        maxLength={getFieldConfig('lastName', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='Surname (i.e. Family name)'
        helperText='As shown on Photo ID or Passport (this must be accurate as it will be used when we check qualifications and when we issue the CAS)'
        name={`${FORM_SECTION_ID}.lastName`}
        value={personalDetails.lastName}
        mb
        required
      />
      <BloomInput
        maxLength={getFieldConfig('previousSurname', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='Previous surname at 16th birthday'
        helperText='If applicable'
        name={`${FORM_SECTION_ID}.previousSurname`}
        value={personalDetails.previousSurname}
        mb
      />
      <BloomInput
        maxLength={getFieldConfig('preferredName', stepConfig).maxLength}
        disabled={isFormSubmitted}
        required
        handleChange={updateFormField}
        label='What is your preferred first name?'
        helperText='The name you prefer to be called'
        name={`${FORM_SECTION_ID}.preferredName`}
        value={personalDetails.preferredName}
        mb
      />
      <BloomDatePicker
        disabled={isFormSubmitted}
        label='Date of birth'
        handleChange={updateFormField}
        name={`${FORM_SECTION_ID}.birthDate`}
        value={personalDetails.birthDate}
        helperText='dd/mm/yyyy'
        required
        mb
      />
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        name={`${FORM_SECTION_ID}.sex`}
        value={personalDetails.sex}
        labelId={`${FORM_SECTION_ID}.sex`}
        label="What is your sex?"
        mb
        helperText='Please use the sex recorded on one of your legal documents such as a birth certificate or passport'
        options={getFieldConfig('sex', stepConfig).possibleValues}
      />
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        value={personalDetails.applicationSource}
        name={`${FORM_SECTION_ID}.applicationSource`}
        labelId={`${FORM_SECTION_ID}.applicationSource`}
        label="Do you have an agent, or have you been referred by a student?"
        mb
        helperText='If you have not applied through an agent, or you have not been referred by a student, select "Direct Applicant".'
        options={studentReferralConfig.possibleValues}
      />
      {
        form![FORM_SECTION_ID].applicationSource === studentReferralConfig.possibleValues[0] && <>
          <Header sx={{ mt: 2 }}>Agent</Header>
          <BloomInput
            maxLength={getFieldConfig('agentName', stepConfig).maxLength}
            disabled={isFormSubmitted}
            required
            handleChange={updateFormField}
            label='Name of your agent'
            name={`${FORM_SECTION_ID}.agentName`}
            value={personalDetails.agentName}
            mb
          />
          <BloomSelect
            disabled={isFormSubmitted}
            required
            handleSelectChange={updateFormField}
            name={`${FORM_SECTION_ID}.agentToActOnYourBehalf`}
            value={personalDetails.agentToActOnYourBehalf}
            labelId={`${FORM_SECTION_ID}.agentToActOnYourBehalf`}
            label="Do you want your agent to act or speak on your behalf? If you select 'Yes', then your agent will be provided with access to your application and your data"
            mb
            options={YesNo}
          />
        </>
      }
      {
        form![FORM_SECTION_ID].applicationSource === studentReferralConfig.possibleValues[1] && <>
          <Header sx={{ mt: 2 }}>Student referral details</Header>
          <BloomInput
            maxLength={getFieldConfig('studentReferral', stepConfig).maxLength}
            disabled={isFormSubmitted}
            required
            handleChange={updateFormField}
            label={'Please enter the student\'s name and student number (LON number)'}
            value={personalDetails.studentReferral}
            name={`${FORM_SECTION_ID}.studentReferral`}
            mb
          />
        </>
      }
      <Header sx={{ mt: 2 }}>Current or previous study at Bloomsbury Institute</Header>
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        name={`${FORM_SECTION_ID}.currentOrPreviousStudent`}
        value={personalDetails.currentOrPreviousStudent}
        labelId={`${FORM_SECTION_ID}.currentOrPreviousStudent`}
        label="Are you, or have you previously been, a student at Bloomsbury Institute?"
        mb
        options={YesNo}
      />
      {
        form![FORM_SECTION_ID].currentOrPreviousStudent === YesNo[0] && <>
          <Header sx={{ mt: 2 }}>Details of current or previous study at Bloomsbury Institute</Header>
          <BloomInput
            maxLength={getFieldConfig('detailsOfCurrentOrPreviousStudy', stepConfig).maxLength}
            disabled={isFormSubmitted}
            handleChange={updateFormField}
            label='Please enter your current/previous student number (LON number)'
            name={`${FORM_SECTION_ID}.detailsOfCurrentOrPreviousStudy`}
            value={personalDetails.detailsOfCurrentOrPreviousStudy}
            mb
          />
        </>
      }
      <Header>Contact Details (Permanent Home Address)</Header>
      <Text sx={{ mb: 4 }}>Please enter your permanent residence details here.</Text>
      <BloomInput
        maxLength={getFieldConfig('contactDetails.houseNumber', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='House Name/Number'
        name={`${FORM_SECTION_ID}.contactDetails.houseNumber`}
        value={personalDetails.contactDetails.houseNumber}
        mb
        required
      />
      <BloomInput
        maxLength={getFieldConfig('contactDetails.streetName', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='Street'
        name={`${FORM_SECTION_ID}.contactDetails.streetName`}
        value={personalDetails.contactDetails.streetName}
        mb
        required
      />
      <BloomInput
        maxLength={getFieldConfig('contactDetails.townOrCity', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='Town/City'
        name={`${FORM_SECTION_ID}.contactDetails.townOrCity`}
        value={personalDetails.contactDetails.townOrCity}
        mb
        required
      />
      <BloomInput
        maxLength={getFieldConfig('contactDetails.postCode', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='Post Code'
        name={`${FORM_SECTION_ID}.contactDetails.postCode`}
        value={personalDetails.contactDetails.postCode}
        mb
      />
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        name={`${FORM_SECTION_ID}.contactDetails.country`}
        value={personalDetails.contactDetails.country}
        labelId={`${FORM_SECTION_ID}.contactDetails.country`}
        label="Country"
        mb
        options={countries}
      />
      <BloomInput
        maxLength={getFieldConfig('contactDetails.email', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='Email'
        name={`${FORM_SECTION_ID}.contactDetails.email`}
        value={personalDetails.contactDetails.email}
        mb
        required
      />
      <BloomInput
        maxLength={getFieldConfig('contactDetails.mobile', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='Mobile'
        name={`${FORM_SECTION_ID}.contactDetails.mobile`}
        value={personalDetails.contactDetails.mobile}
        mb
        required
      />
      <BloomInput
        maxLength={getFieldConfig('contactDetails.telephone', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='Phone (telephone)'
        name={`${FORM_SECTION_ID}.contactDetails.telephone`}
        value={personalDetails.contactDetails.telephone}
        mb
      />
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        name={`${FORM_SECTION_ID}.knownUkAddress`}
        value={personalDetails.knownUkAddress}
        labelId={`${FORM_SECTION_ID}.knownUkAddress`}
        label="Do you know the address of where you will be living in the UK?"
        mb
        options={YesNo}
      />
      {
        form![FORM_SECTION_ID].knownUkAddress === YesNo[0] && <>
          <Header>Address in the UK</Header>
            <BloomInput
              maxLength={getFieldConfig('ukAddress.houseNumber', stepConfig).maxLength}
              disabled={isFormSubmitted}
              handleChange={updateFormField}
              label='House Name/Number'
              name={`${FORM_SECTION_ID}.ukAddress.houseNumber`}
              value={personalDetails.ukAddress.houseNumber}
              mb
              required
            />
            <BloomInput
              maxLength={getFieldConfig('ukAddress.streetName', stepConfig).maxLength}
              disabled={isFormSubmitted}
              handleChange={updateFormField}
              label='Street'
              name={`${FORM_SECTION_ID}.ukAddress.streetName`}
              value={personalDetails.ukAddress.streetName}
              mb
              required
            />
            <BloomInput
              maxLength={getFieldConfig('ukAddress.townOrCity', stepConfig).maxLength}
              disabled={isFormSubmitted}
              handleChange={updateFormField}
              label='Town/City'
              name={`${FORM_SECTION_ID}.ukAddress.townOrCity`}
              value={personalDetails.ukAddress.townOrCity}
              mb
              required
            />
            <BloomInput
              maxLength={getFieldConfig('ukAddress.postCode', stepConfig).maxLength}
              disabled={isFormSubmitted}
              required
              handleChange={updateFormField}
              label='Post Code'
              name={`${FORM_SECTION_ID}.ukAddress.postCode`}
              value={personalDetails.ukAddress.postCode}
              mb
            />
            <BloomSelect
              disabled={isFormSubmitted}
              required
              handleSelectChange={updateFormField}
              label="Country"
              name={`${FORM_SECTION_ID}.ukAddress.country`}
              value={personalDetails.ukAddress.country}
              labelId={`${FORM_SECTION_ID}.ukAddress.country`}
              mb
              options={countries}
            />
            <Header>Accommodation Type</Header>
            <BloomSelect
              disabled={isFormSubmitted}
              required
              handleSelectChange={updateFormField}
              name={`${FORM_SECTION_ID}.accommodationType`}
              value={personalDetails.accommodationType}
              labelId={`${FORM_SECTION_ID}.accommodationType`}
              label="Which of the following best describes your accommodation in the UK?"
              mb
              options={getFieldConfig('accommodationType', stepConfig).possibleValues}
            />
        </>
      }
    </>
  )
}

export default YourDetails
