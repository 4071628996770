import React from 'react'

import { Text, Header } from '../../../components'
import { useApplicationForm } from '../../../hooks/useApplicationForm'
import { BloomSelect } from '../../BloomSelect'

const FIELDS_CONFIG_SECTION_NAME = 'Equal Opportunities' // typo in BE, has to stay like that
const FORM_SECTION_ID = 'equalOportunities'

function EqualOpportunities () {
  const { getStepConfig, getFieldConfig, form, updateFormField, isFormSubmitted } = useApplicationForm()

  const stepConfig = getStepConfig(FIELDS_CONFIG_SECTION_NAME)

  if (!stepConfig) {
    throw new Error('Form configuration for this step seems to be missing :(')
  }

  const equalOpportunities = form && form[FORM_SECTION_ID]

  if (!equalOpportunities) {
    return null
  }

  return (
    <>
      <Header>Equal Opportunities Monitoring Form</Header>
      <Text sx={{ mb: 4 }}>
        We are committed to a policy of equal opportunities and are obliged to return equal opportunities
        data to the Higher Education Statistics Agency (HESA) annually. The information in this section is
        used solely for the purpose of monitoring and forms no part of the selection procedure.
        <br/><strong>The Monitoring Form is anonymous and the information is separated from the Application
        Form and will not be made available to any member of staff involved in evaluating your
        application.</strong>
      </Text>
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        value={equalOpportunities.lastTypeOfSchoolOrCollege}
        labelId={`${FORM_SECTION_ID}.lastTypeOfSchoolOrCollege`}
        name={`${FORM_SECTION_ID}.lastTypeOfSchoolOrCollege`}
        label="What was the last type of school or college you attended prior to starting the course?"
        mb
        options={getFieldConfig('LastTypeOfSchoolOrCollege', stepConfig).possibleValues}
      />
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        value={equalOpportunities.ethnicity}
        labelId={`${FORM_SECTION_ID}.ethnicity`}
        name={`${FORM_SECTION_ID}.ethnicity`}
        label="What is your ethnicity or ethnic group?"
        mb
        options={getFieldConfig('ethnicity', stepConfig).possibleValues}
      />
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        value={equalOpportunities.countryOfResidence}
        labelId={`${FORM_SECTION_ID}.countryOfResidence`}
        name={`${FORM_SECTION_ID}.countryOfResidence`}
        label="What is your normal country of residence prior to starting your course?"
        mb
        options={getFieldConfig('CountryOfResidence', stepConfig).possibleValues}
      />
    </>
  )
}

export default EqualOpportunities
