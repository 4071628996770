import {
  CssBaseline,
  ThemeOptions,
  ThemeProvider,
  createTheme
} from '@mui/material'
import { ReactNode } from 'react'

const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightMedium = 500
const fontWeightBold = 700
// const fontWeightExtraBold = 800

const formErrorColor = '#FF7241'

const themeOptions: ThemeOptions = {
  spacing: 8,
  palette: {
    mode: 'light',
    error: {
      light: '#F58586',
      main: '#E54C17',
      dark: '#C82B35'
    },
    success: {
      light: '#20D29F',
      main: '#3AA93E',
      dark: '#166A61'
    },
    warning: {
      light: '#EAD773',
      main: '#F6BE00',
      dark: '#F49C5B'
    },
    info: {
      light: '#1890FF',
      main: '#1890FF',
      dark: '#1890FF'
    },
    primary: {
      main: '#EC6605'
    },
    secondary: {
      main: '#A6E2F6',
      dark: '#0167B8'
    },
    text: {
      disabled: '#BFBFBF',
      primary: '#5D6267',
      secondary: '#222226'
    },
    background: {
      paper: '#E7E7E7'
    }
  },
  typography: (palette) => ({
    fontFamily: 'inter, sans-serif',
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
    h1: {
      fontWeight: fontWeightMedium,
      fontSize: '2.5rem',
      lineHeight: 2.1875,
      letterSpacing: '2.2px'
    },
    h2: {
      fontWeight: fontWeightMedium,
      fontSize: '2.35rem',
      lineHeight: 2.1875,
      letterSpacing: '1.6px'
    },
    h3: {
      fontWeight: fontWeightMedium,
      fontSize: '2rem',
      lineHeight: 1.5,
      letterSpacing: '1px'
    },
    h4: {
      fontWeight: fontWeightMedium,
      fontSize: '1.875rem',
      lineHeight: 2.16,
      letterSpacing: '0.6px'
    },
    h5: {
      fontWeight: fontWeightMedium,
      fontSize: '1.725rem',
      lineHeight: 2.16,
      letterSpacing: '0.4px'
    },
    h6: {
      fontWeight: fontWeightMedium,
      fontSize: '1.6rem',
      lineHeight: 2.16,
      letterSpacing: '0.2px'
    },
    body1: {
      fontWeight: fontWeightRegular,
      fontSize: '1rem',
      lineHeight: 1.67
    },
    body2: {
      fontWeight: fontWeightRegular,
      fontSize: '.75rem',
      lineHeight: 1.67
    },
    button: {
      fontSize: '1rem',
      fontWeight: fontWeightMedium,
      letterSpacing: '1.4px',
      lineHeight: 2
    },
    caption: {
      fontWeight: fontWeightRegular,
      fontSize: '0.625rem',
      lineHeight: 1.2,
      color: palette.text.secondary
    },
    // Custom styles
    header: {
      fontWeight: fontWeightMedium,
      fontSize: '0.625rem',
      letterSpacing: '1px',
      lineHeight: 1.5
    },
    cardBody: {
      fontSize: '0.875rem',
      letterSpacing: '0.2px',
      lineHeight: 1.57
    },
    cardBodySmall: {
      fontSize: '0.75rem',
      lineHeight: 1.5
    },
    navLink: {
      fontWeight: fontWeightMedium,
      fontSize: '0.825rem',
      letterSpacing: '1.3px'
    },
    sliderTitle: {
      fontWeight: fontWeightMedium,
      fontSize: '0.625rem',
      letterSpacing: '0.6px',
      lineHeight: 1.4
    },
    notification: {
      fontSize: '0.875rem',
      letterSpacing: '0.3px',
      lineHeight: 1.42
    }
  }),
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        root: () => ({
          textOverflow: 'unset',
          overflow: 'visible',
          whiteSpace: 'pre-wrap'
        })
      }
    },
    MuiDataGrid: {
      defaultProps: {
        disableColumnMenu: true,
        disableRowSelectionOnClick: true,
        getRowHeight: () => 'auto'
      },
      styleOverrides: {
        root: ({ theme: rawTheme }: any) => ({
          border: 'none',

          '& .MuiDataGrid-row': {
            border: '1px solid transparent',
            marginBottom: '2px',
            width: 'calc(100% - 2px)',
            transition: 'border 0.25s',
            fontSize: '0.875rem',
            // '&:hover': {
            //   backgroundColor: '#2A4E6C'
            // },
            '& .MuiDataGrid-cell': {
              border: 'none',
              outline: 'none',
              '&:focus': {
                outline: 'none'
              }
            }
          },
          '& .MuiDataGrid-row, .MuiDataGrid-cell': {
            minHeight: '56px !important'
          },
          '& .MuiDataGrid-columnHeader': {
            color: rawTheme.palette.secondary.main,
            '&:focus, &:focus-within': {
              outline: 'none'
            },
            // background: '#2A4358',
            fontSize: '14px'
          },
          '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
            // paddingLeft: "20px",
            // paddingRight: "20px",
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: fontWeightBold
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none !important'
          },
          '& .MuiDataGrid-columnHeaders': {
            border: 'none'
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            // flex: "0 0 auto",

            '& .MuiDataGrid-sortIcon': {
              // color: '#A6E2F6',
              transform: 'scale(0.9)'
            }
          },
          '& .MuiDataGrid-overlay': {
            background: 'transparent'
          },
          '& .MuiTablePagination-root': {
            color: rawTheme.palette.secondary.main
          },
          '& .actions': {
            position: 'relative'
          },

          '& .MuiDataGrid-footerContainer': {
            borderTop: '0px'
          },

          '& .MuiTablePagination-selectLabel, & .MuiTablePagination-select, & .MuiTablePagination-displayedRows':
            {
              fontSize: '12px'
            },

          '& .MuiTablePagination-selectLabel': {
            marginTop: '20px',
            marginBottom: '20px'
          },

          '& .MuiTablePagination-toolbar .MuiInputBase-root': {
            marginRight: '8px',
            marginLeft: 0
          },

          '& div.MuiTablePagination-actions': {
            margin: 0
          },

          '& .MuiDataGrid-actionsCell': {
            position: 'absolute',
            right: 0,
            opacity: 0,
            transition: 'opacity 0.1s, transform 0.3s',
            transform: 'translateX(50%)',
            height: '100%',
            gridGap: '1px',
            '& button': {
              // backgroundColor: '#A6BFD5',
              borderRadius: 0,
              width: '104px',
              height: '100%'

              // '&:hover': {
              //   backgroundColor: '#0F2537',

              //   '& svg': {
              //     color: '#A6E2F6'
              //   },

              //   '&::after': {
              //     color: '#A6E2F6'
              //   }
              // }
            }
          },

          '& .MuiSelect-icon': {
            transform: 'scale(0.75)'
          },
          '& .MuiDataGrid-row:hover': {
            '.actions .MuiDataGrid-actionsCell': {
              visibility: 'visible',
              opacity: 1,
              transform: 'translateX(0%)'
            }
          },

          '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
            // backgroundColor: 'rgba(0, 0, 0, .8)'
          }
        })
      }
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
        variant: 'filled'
      },
      styleOverrides: {
        root: ({ theme: rawTheme }: any) => ({
          color: rawTheme.palette.primary.main,
          transform: 'none',
          position: 'relative',
          textOverflow: 'unset',
          overflow: 'visible',
          whiteSpace: 'pre-wrap',
          ...rawTheme.typography.header
        })
      }
    },

    MuiButton: {
      defaultProps: {
        color: 'primary'
      },
      styleOverrides: {
        root: ({ theme: rawTheme }: any) => ({
          ...rawTheme.typography.button,
          borderRadius: '4px',
          padding: '8px 16px',
          textTransform: 'none',

          '&:hover': {
            // color: '#C3CACF',
            // backgroundColor: '#384D5F',
            // border: '1px solid #C3CACF'
          }
        }),

        contained: {
          border: '1px solid transparent',

          '&:hover': {
            border: '1px solid transparent'
          }
        },

        text: {
          border: '1px solid transparent'
        },

        sizeSmall: {
          padding: '2px 10px',
          fontSize: '12px'
        },
        sizeMedium: {
          padding: '4px 12px',
          fontSize: '14px'
        },
        sizeLarge: {
          padding: '4px 14px',
          fontSize: '16px'
        }
      }
    },

    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        displayEmpty: true
      },
      styleOverrides: {
        select: {
          boxSizing: 'border-box',
          borderRadius: '2px',

          '&.MuiInputBase-inputSizeSmall': {
            paddingTop: '5px',
            paddingBottom: '5px'
          },
          '&.MuiInput-underline': {
            borderColor: 'red'
          }
        },
        icon: {
          fontSize: '16px',
          right: '9px'
        }
      }
    },

    MuiChip: {
      styleOverrides: {
        root: ({ theme: rawTheme }: any) => ({
          borderRadius: '2px',
          fontSize: '0.625rem',
          fontWeight: rawTheme.typography.fontWeightBold,
          padding: rawTheme.spacing(0.5),
          // paddingRight/left adjusts for including close icon
          paddingRight: rawTheme.spacing(0.25),
          paddingLeft: rawTheme.spacing(1),
          height: 'auto',
          color: rawTheme.palette.text.dark
        }),

        label: {
          paddingLeft: 0,
          paddingRight: 10
        }
      }
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          background:
            'linear-gradient(90deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.08) 100%)'
        },
        vertical: {
          background:
            'linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.08) 100%)'
        }
      }
    },

    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme: rawTheme }: any) => ({
          marginBottom: rawTheme.spacing(1),
          textOverflow: 'unset',
          overflow: 'visible',
          whiteSpace: 'pre-wrap'
        })
      }
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 30
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          '& .MuiTab-root': {
            fontSize: 16,
            fontWeight: fontWeightRegular,

            '&.Mui-selected': {
              fontWeight: fontWeightBold
            }
          }
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: ({ theme: rawTheme }: any) => ({
          background: rawTheme.palette.background.card
        })
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          minHeight: '34px',
          borderRadius: '0px',
          border: 'solid 1px transparent',
          // backgroundColor: '#0F2537',
          '&.MuiFilledInput-root': {
            paddingTop: '0 !important'
          },
          '&:hover:not(.Mui-disabled)': {
            // backgroundColor: '#2A4E6C'
          },
          '&.Mui-focused': {
            // borderColor: '#A6BFD5',
            // backgroundColor: '#0F2537'
          },
          '&::before': {
            borderColor: 'transparent'
          },
          '&.Mui-error': {
            borderColor: formErrorColor
          }
        },
        input: {
          paddingTop: '10px',
          paddingBottom: '10px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
          boxSizing: 'border-box',
          fontSize: '16px',
          lineHeight: 1.5,
          minHeight: '34px',

          '&.Mui-error': {
            color: formErrorColor
          },

          '&.MuiFilledInput-root:hover::before, &.MuiFilledInput-root::after': {
            // borderColor: '#A6BFD5 !important',
            borderWidth: '1px'
          },
          '&.Mui-error::after': {
            borderColor: `${formErrorColor} !important`
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #D9D9D9'
          }
        },
        input: {
          paddingLeft: '8px',
          paddingRight: '8px'
          // '&::placeholder': {
          //   color: '#7AABD2'
          // }
        },

        inputSizeSmall: {
          '&.MuiInputBase-inputSizeSmall': {
            paddingTop: '6px',
            paddingBottom: '6px'
          },

          borderColor: formErrorColor
        },

        multiline: {
          paddingTop: '0px !important',
          paddingBottom: '0px !important'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: '6px',
          marginLeft: '0px',
          fontSize: '10px',
          letterSpacing: 'normal',
          borderRadius: '4px',
          textTransform: 'none',

          '&.MuiFormHelperText-root.Mui-error': {
            color: formErrorColor
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          alignItems: 'baseline',
          paddingTop: '50px'
        },
        paper: {
          border: '1px solid white'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme: rawTheme }: any) => ({
          padding: rawTheme.spacing(8, 10, 4)
        })
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme: rawTheme }: any) => ({
          padding: rawTheme.spacing(2, 10),
          paddingTop: '16px !important'
        })
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme: rawTheme }: any) => ({
          padding: rawTheme.spacing(4, 10, 8)
        })
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme: rawTheme }: any) => ({
          backgroundColor: '#0B1D38',
          fontSize: rawTheme.typography.pxToRem(12),
          lineHeight: 1.16,
          fontWeight: rawTheme.typography.fontWeightBold,
          fontStyle: 'italic',
          padding: rawTheme.spacing(1.25)
        })
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&$selected, &$selected:hover': {
            // backgroundColor: "rgba(255, 255, 255, .08)",
            backgroundColor: 'yellow'
          }
        },
        button: {
          borderRadius: '2px'
        },
        gutters: ({ theme: rawTheme }: any) => ({
          paddingLeft: rawTheme.spacing(1),
          paddingRight: rawTheme.spacing(1)
        })
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          // border: '1px solid #2A4E6C',
          padding: 0
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          padding: '12px',

          '&:hover, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected.Mui-focusVisible':
            {
              // backgroundColor: '#2A4358',
              // color: '#A6E2F6'
            }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme: rawTheme }: any) => ({
          fontSize: '1rem',
          lineHeight: 2,
          color: rawTheme.palette.info.main,
          textDecorationColor: rawTheme.palette.info.main,
          textUnderlineOffset: 4
        })
      },
      defaultProps: {
        underline: 'always'
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        // @ts-ignore
        colorSuccess: {
          backgroundColor: 'rgba(255, 255, 255, .25)'
        },
        colorSecondary: {
          backgroundColor: 'black'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: ({ theme: rawTheme }: any) => ({
          fontWeight: rawTheme.typography.fontWeightBold
        }),

        stickyHeader: ({ theme: rawTheme }: any) => ({
          backgroundColor: rawTheme.palette.background.card,
          color: rawTheme.palette.primary.main
        })
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme: rawTheme }: any) => ({
          borderColor: rawTheme.palette.secondary.main,
          color: rawTheme.palette.secondary.main,

          '&.Mui-selected': {
            color: rawTheme.palette.secondary.main,
            backgroundColor: rawTheme.palette.background.darkPanel,
            borderLeftColor: `${rawTheme.palette.secondary.main} !important`,

            '& $label': {
              opacity: 1
            }
          }
        })
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: ({ theme: rawTheme }: any) => ({
          background: rawTheme.palette.background.card
        }),
        listbox: ({ theme: rawTheme }: any) => ({
          fontFamily: rawTheme.typography.fontFamily,
          fontStyle: 'Normal',
          fontSize: rawTheme.typography.pxToRem(14),
          fontWeight: fontWeightMedium,
          lineHeight: 1.43,
          letterSpacing: 'normal',
          textTransform: 'none'
        }),
        groupLabel: ({ theme: rawTheme }: any) => ({
          // backgroundColor: '#444',
          ...rawTheme.typography.body2,
          fontWeight: rawTheme.typography.fontWeightBold,
          lineHeight: '36px'
        })
      }
    },

    Special: {
      borderBottomColor: 'red'
    },

    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true
        },
        margin: 'normal',
        variant: 'filled',
        autoComplete: 'off'
      },
      styleOverrides: {
        root: {
          marginTop: 0,

          '&& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: formErrorColor // Solid underline on focus
          },

          '& legend': {
            display: 'none'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
          backgroundImage: 'unset'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          "#ds-app-main > div[id*='messages'] *::-webkit-scrollbar": {
            width: '.7em'
          },
          "#ds-app-main > div[id*='messages'] *::-webkit-scrollbar-thumb": {
            backgroundColor: 'white'
          },
          "#ds-app-main > div[id*='messages'] *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: 'white'
            },
          "#ds-app-main > div[id*='messages'] *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: 'white'
            },
          "#ds-app-main > div[id*='messages'] *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: 'white',
              cursor: 'pointer'
            },
          // scrollbarWidth for Firefox
          scrollbarWidth: 'thin'
        }
      }
    }
  }
}
export const theme = createTheme(themeOptions)

type ProviderProps = {
  children: ReactNode;
};

const BloomsburyThemeProvider = ({ children }: ProviderProps) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
)

export default BloomsburyThemeProvider
