import { Box, CircularProgress, Typography } from '@mui/material'

import { useApplicationForm } from '../../../hooks/useApplicationForm'
import { Paper } from '../../Paper'
import {
  AdditionalInformation,
  ApplicationFormSummary,
  ApplicationFormWelcome,
  Course,
  Declaration,
  Education,
  EqualOpportunities,
  NationalityDetails,
  Reference,
  YourDetails
} from '../../steps'

const getActiveStep = (activeStep: number) => {
  switch (activeStep) {
    case 0:
      return <ApplicationFormWelcome />
    case 1:
      return <YourDetails />
    case 2:
      return <Course />
    case 3:
      return <NationalityDetails />
    case 4:
      return <Education />
    case 5:
      return <Reference />
    case 6:
      return <AdditionalInformation />
    case 7:
      return <EqualOpportunities />
    case 8:
      return <Declaration />
    case 9:
      return <ApplicationFormSummary />
    default:
      return null
  }
}

const Step = () => {
  const { activeStep, submitForm, isFormLoading } = useApplicationForm()

  return (
    <Box sx={{ display: 'flex', flex: 1 }}>
      <Paper sx={{ pl: 6, mt: 4, width: '100%', position: 'relative' }}>
        { activeStep !== 0 && activeStep !== 9 && <Typography variant="body1" color='error.dark' sx={{ position: 'absolute', right: '36px' }}>All fields with an * are required</Typography> }
        <form noValidate onSubmit={submitForm}>
        {
          isFormLoading
            ? <CircularProgress
                sx={{
                  top: '200px',
                  left: 0,
                  right: 0,
                  position: 'absolute',
                  margin: '0 auto'
                }}
              />
            : getActiveStep(activeStep)
          }
        </form>
      </Paper>
    </Box>
  )
}

export default Step
