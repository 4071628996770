import { createContext } from 'react'

import { UserProfileData } from '../../services/types'

export type UserDataContextData = {
  user?: UserProfileData
  loadingUserData: boolean
  updateUserDataField: (value: any, path: string) => void // local update, no BE call here
  saveUserData: () => Promise<void>
  changePassword: (currentPassword: string, newPassword: string) => Promise<void>
}

const UserDataContext = createContext({} as UserDataContextData)

export default UserDataContext
