/* eslint-disable react/display-name */

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { Button, CircularProgress, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid'
import { useState } from 'react'

import { AddEditAgentDialog, ConfirmationDialog, Header } from '../../../components'
import { useAdminData } from '../../../hooks/useAdminData'
import useDeleteUser from '../../../services/hooks/mutations/useDeleteUser'
import { Agent } from '../../../services/types'

const iconStyles = {
  width: '18px',
  height: '18px',
  minWidth: '18px',
  marginRight: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const getColumns = (onEditClick: (agentData: Agent) => void, onDeleteClick: (agentData: Agent) => void): GridColDef[] => {
  return [
    {
      field: 'name',
      flex: 1,
      headerName: 'Name'
    },
    {
      field: 'surname',
      flex: 1,
      headerName: 'Surname'
    },
    {
      field: 'userName',
      flex: 1,
      headerName: 'Email'
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 1,
      cellClassName: 'actions',
      sortable: false,
      getActions: ({ row }) => [
        <GridActionsCellItem
          key='edit'
          icon={<EditIcon color="primary" sx={iconStyles}/>}
          size="large"
          onClick={() => onEditClick(row)}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            justifyItems: 'center',
            alignContent: 'center'
          }}
        />,
        <GridActionsCellItem
          key='edit'
          icon={<DeleteIcon color="primary" sx={iconStyles}/>}
          size="large"
          onClick={() => onDeleteClick(row)}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            justifyItems: 'center',
            alignContent: 'center'
          }}
        />
      ]
    }
  ]
}

const AgentsList = () => {
  const { isLoadingAgents, agents } = useAdminData()
  const { mutateAsync: deleteAgent } = useDeleteUser()
  const [isAgentDialogOpen, setIsAgentDialogOpen] = useState(false)
  const [agentToDelete, setAgentToDelete] = useState<Agent>()
  const [editAgentData, setEditAgentData] = useState<Agent>()

  const onEditClick = async (agentData: Agent) => {
    setEditAgentData(agentData)
    setIsAgentDialogOpen(true)
  }

  const onDeleteClick = (agentData: Agent) => {
    setAgentToDelete(agentData)
  }

  const onAddClick = () => {
    setIsAgentDialogOpen(true)
  }

  const closeEditDialog = () => {
    setEditAgentData(undefined)
    setIsAgentDialogOpen(false)
  }

  const closeConfirmationDialog = (confirmed?: boolean) => {
    if (agentToDelete && confirmed) {
      deleteAgent({ id: agentToDelete.id })
    }

    setAgentToDelete(undefined)
  }

  return (
    <Box sx={{ pl: 6, width: 'calc(100% - 225px)', position: 'relative' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
        <Header>Agents List</Header>
        <Button endIcon={<PersonAddIcon/>} size='small' variant="contained" onClick={onAddClick}>Add Agent</Button>
      </Box>
      {
        isLoadingAgents && !agents
          ? <CircularProgress
              sx={{
                top: '200px',
                left: 0,
                right: 0,
                position: 'absolute',
                margin: '0 auto'
              }}
            />
          : <DataGrid
            rows={agentToDelete ? [] : agents || []}
            columns={getColumns(onEditClick, onDeleteClick)}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10
                }
              }
            }}
            pageSizeOptions={[10, 20, 40]}
            disableRowSelectionOnClick
            sx={{
              maxWidth: '100% !important',
              '& .MuiDataGrid-columnHeaders': {
                background: '#EC6605 !important'
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                color: 'white'
              },
              '& .MuiDataGrid-columnHeader--moving': {
                background: '#EC6605 !important'
              },
              '& .MuiDataGrid-cellContent': {
                paddingTop: 1,
                paddingBottom: 1
              },
              '& .MuiDataGrid-virtualScroller': {
                overflowX: 'hidden'
              },
              '& .MuiTablePagination-root': {
                color: 'black !important'
              },
              '& .MuiDataGrid-row:nth-child(odd)': {
                backgroundColor: '#F5F5F5 !important'
              }
            }}
          />
      }
      { isAgentDialogOpen ? <AddEditAgentDialog agentToEdit={editAgentData} isOpen={isAgentDialogOpen} closeDialog={closeEditDialog}/> : null}
      { agentToDelete
        ? <ConfirmationDialog isOpen={!!agentToDelete} title='Confirm Agent Removal' onClose={closeConfirmationDialog}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <Typography color="error" fontWeight="bold" sx={{ mb: 2 }}>Caution, this action is irreversible!</Typography>
              <Typography>Are you sure you want to remove</Typography>
              <Typography fontWeight="bold" sx={{ mb: 2 }}>{agentToDelete.name && agentToDelete.surname ? `${agentToDelete.name} ${agentToDelete.surname}` : agentToDelete?.email}</Typography>
            </Box>
          </ConfirmationDialog>
        : null
      }
    </Box>
  )
}

export default AgentsList
