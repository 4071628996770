import { Stack } from '@mui/material'

import { Header, ParagraphHeader, SectionHeader, Text, FormInformation } from '../../../components'

const Welcome = () => {
  return (
    <Stack>
      <Header>Application Form: International applicants who require a Student Visa</Header>
      <FormInformation type="info">You should only complete this form if you are an international applicant who requires a Student Visa to study in the UK.</FormInformation>
      <SectionHeader>Completing the Application Form</SectionHeader>
      <FormInformation type="important" sx={{ mb: 3 }}>IMPORTANT: When you have completed the Application Form, click the SUBMIT button.</FormInformation>
      <ParagraphHeader>Conditional Offer</ParagraphHeader>
      <Text>
        If you are made a Conditional Offer, we will then ask you to submit the following documents: (i) academic qualifications and transcripts; and (ii) proof of English language (if applicable).
        We will also ask you to upload your passport so that we can check your name against that on your qualifications.
        <br/>
        <br/>
        You will be required to provide contact details of a referee on the application form. However, if you upload a Letter of Recommendation from your school, college, university or employer,
        we will not need to wait for the reference from your referee.
        <br/>
        <br/>
        You will also be required to upload a CV - this is so that we can identify if there are any gaps in your study/employment record. This does not bar you from applying, but we will need to
        clarify any gaps before we can issue you with a Confirmation of Acceptance for Studies (CAS) that you need to apply for your Student Visa.
        <br/>
        <br/>
        We also require you to upload a photograph. We need this to publish your Student ID Card.
        <br/>
        <br/>
        If you have applied for the MBA, you will need to upload proof of your minimum 2 years managerial/supervisory experience.
        <br/>
        <br/>
        Once we have all of the above, we will then be able to make you an Unconditional Offer.
        <br/>
        <br/>
        An Unconditional Offer means that you satisfy the entry criteria to be admitted onto the course. We will undertake checks to verify (i) your qualifications and transcripts
        (including your English Language qualification); and (ii) any other information or documents you provide as part of the application process (e.g. passport).
        You are required to provide your consent to these verification checks being undertaken within the Declaration section of this Application Form.
        <br/>
        <br/>
        With regard to your qualifications and transcripts (and English Language qualification if we cannot verify it), when we make you an Unconditional Offer,
        you will normally be provided with access to Qualification Check for verification to be undertaken.
      </Text>
      <ParagraphHeader>Confirmation of Acceptance for Studies (CAS) and Student Visa</ParagraphHeader>
      <Text>
        Once you have been made an Unconditional Offer, you will be transferred to a different system (CAS Shield) where we will ask you for some additional documentation.
        This is to enable us to issue a Confirmation of Acceptance for Studies (CAS).
        You need a CAS before you make an application for your Student Visa.
      </Text>
      <ParagraphHeader>Track progress of your application</ParagraphHeader>
      <Text>
        You can track the progress of your application through the Self Service Centre (and CAS Shield once you have been made an unconditional offer).
        You should login to the Self Service Centre (and CAS Shield) regularly to track progress, and to ensure you comply with any requirements (i.e. conditions) which we may stipulate.
      </Text>
      <ParagraphHeader>Admissions Policy and Terms and Conditions</ParagraphHeader>
      <Text>
        Two documents which you should find useful to read prior to making an application are:
      </Text>
      <ul>
        <li>
          <a href="https://www.bil.ac.uk/app/uploads/2023/06/Admissions-Policy-1.pdf" target='_blank' rel="noreferrer">Admissions Policy</a><br/>
        </li>
        <li>
          <a href="https://www.bil.ac.uk/app/uploads/2023/06/Terms-and-Conditions_Final-2.pdf" target='_blank' rel="noreferrer">Terms And Conditions</a>
        </li>
      </ul>
    </Stack>
  )
}

export default Welcome
