import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined'
import LinkIcon from '@mui/icons-material/Link'
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Header, Paper } from '../../../components'
import { DecisionDocumentDialog } from '../../../components/DecisionDocumentDialog'
import { useDashboardData } from '../../../services/hooks'
import { DashboardStatusType } from '../../../services/types'

interface IApplicationStatusRow {
  title: string;
  displayName: string;
  type: DashboardStatusType
}

const getColorAndIcon = (status: DashboardStatusType) => {
  switch (status) {
    case 1:
      return { icon: PriorityHighRoundedIcon, color: 'warning.main' as any }
    case 3:
      return { icon: CheckOutlinedIcon, color: 'success.main' as any }
    case 4:
      return { icon: CloseOutlinedIcon, color: 'error.dark' as any }
    case 2:
      return { icon: HourglassEmptyOutlinedIcon, color: '#7B61FF' as any }
    default:
      return { icon: null, color: 'text.secondary' as any }
  }
}

const getStatusIcon = (type: DashboardStatusType) => {
  const colorAndIcon = getColorAndIcon(type)
  const Icon = colorAndIcon.icon

  const boxStyles = {
    mr: 0.75,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '16px',
    height: '16px',
    border: type !== 2 ? '2px solid' : 'none',
    borderColor: colorAndIcon.color
  }

  const size = type !== 2 ? '12px' : '16px'

  return Icon
    ? (
        <Box sx={boxStyles}>
          <Icon color={colorAndIcon.color.split('.')[0]} sx={{ width: size, height: size }}/>
        </Box>
      )
    : <Box sx={{ ...boxStyles, color: colorAndIcon.color.split('.')[0] }}/>
}

interface ApplicationStatusRowProps {
  row: IApplicationStatusRow
  onLinkIconClick: () => void
  formLink: string
  decisionId?: number
}

const showFormLink = (rowStatus: string, formLink: string) => {
  if (rowStatus.includes('Conditional offer') && formLink.includes('/conditional')) {
    return true
  } else if (rowStatus.includes('Unconditional offer') && formLink.includes('/unconditional')) {
    return true
  } else if (rowStatus.includes('Enrolment Form') && formLink.includes('/enrolment')) {
    return true
  }

  return false
}

const ApplicationStatusRow = ({ row, onLinkIconClick, formLink, decisionId }: ApplicationStatusRowProps) => {
  const [isDecisionDocDialogOpen, setIsDecisionDocDialogOpen] = useState(false)

  const onShowDecisionModalClick = () => {
    setIsDecisionDocDialogOpen(true)
  }

  const closeEditDialog = () => {
    setIsDecisionDocDialogOpen(false)
  }

  return (
    <Stack direction="row" sx={{ mb: 2, width: '100%' }}>
      <Typography variant="body1" sx={{ minWidth: '320px', maxWidth: '320px' }}>{row.title}: </Typography>
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        {getStatusIcon(row.type)}
        {
          row.displayName &&
          <Typography color={getColorAndIcon(row.type).color} sx={{ display: 'flex', alignItems: 'center' }}variant="body1">
            {
              row.title === 'Decision Outcome' && decisionId
                ? <Box onClick={onShowDecisionModalClick} sx={{ textDecoration: 'underline', textUnderlineOffset: '4px', transition: 'all .2s linear', cursor: 'pointer', '&:hover': { textShadow: '-0.1px -0.1px 0 #2DD785, 0.1px -0.1px 0 #2DD785, -0.1px 0.1px 0 #2DD785, 0.1px 0.1px 0 #2DD785', transition: 'all .2s linear' } }}>{row.displayName}</Box>
                : row.displayName
            }
            {
              showFormLink(row.displayName, formLink) && <LinkIcon color="info" onClick={onLinkIconClick} sx={{
                transform: 'rotate(135deg)',
                cursor: 'pointer',
                width: '26px',
                height: '26px',
                position: 'relative',
                top: '2px',
                marginLeft: '5px'
              }}/>
            }
          </Typography>
        }
      </Stack>
      { isDecisionDocDialogOpen && decisionId ? <DecisionDocumentDialog decisionId={decisionId} isOpen={isDecisionDocDialogOpen} closeDialog={closeEditDialog}/> : null}
    </Stack>
  )
}

const keyToHumanReadable = (key: string) => {
  switch (key) {
    case 'applicationForm':
      return 'Application Form'
    case 'decisionOutcome':
      return 'Decision Outcome'
    case 'supportingDocuments':
      return 'Upload Documents'
    case 'qualificationCheckDocuments':
      return 'Qualification Verification'
    case 'casEnrolmentForm':
      return 'Online Enrolment Form'
    case 'casIssued':
      return 'CAS Issued'
  }
}

const pairToRow = (pair: [string, { displayName: string; type: DashboardStatusType }]) => ({ title: keyToHumanReadable(pair[0]), ...pair[1] })

const ApplicantHome = () => {
  const { data, isLoading } = useDashboardData({ queryOptions: { refetchOnMount: true } })
  const navigate = useNavigate()

  const dashboardSteps = data ? Object.entries(data).filter(pair => pair[1] && typeof pair[1] === 'object') : []

  const onLinkIconClick = () => {
    const path = data?.formLink

    navigate('/' + path || '.')
  }

  return <Stack direction="column" sx={{ pl: 6, width: '100%' }}>
    <Header>Your application status</Header>
    <Paper sx={{ width: '100%', height: '100%', minHeight: '221px', position: 'relative', justifyContent: 'space-between' }}>
      <Box>
      {
        !isLoading
          // @ts-ignore
          ? dashboardSteps.map(pair => <ApplicationStatusRow decisionId={data?.decisionId} key={pair[0]} row={pairToRow(pair)} onLinkIconClick={onLinkIconClick} formLink={data?.formLink} />)
          : <CircularProgress
              sx={{
                top: '200px',
                left: 0,
                right: 0,
                position: 'absolute',
                margin: '0 auto'
              }}
            />
      }
      </Box>
      <Box>
        {
          data?.enrlomentDate && <Stack direction="row" sx={{ mb: 2, width: '100%' }}>
            <Typography variant="body1" sx={{ minWidth: '320px', maxWidth: '320px' }}>Enrolment date: </Typography>
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <Typography variant="body1">{data?.enrlomentDate}</Typography>
            </Stack>
          </Stack>
        }
        {
          data?.inductionDate && <Stack direction="row" sx={{ mb: 2, width: '100%' }}>
            <Typography variant="body1" sx={{ minWidth: '320px', maxWidth: '320px' }}>Induction date: </Typography>
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <Typography variant="body1">{data?.inductionDate}</Typography>
            </Stack>
          </Stack>
        }
      </Box>
    </Paper>
  </Stack>
}

export default ApplicantHome
