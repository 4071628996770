// seconds * minutes * hours * days
export const COOKIE_EXPIRATION_TIME = 60 * 60 * 24 // 1 day
export const TOKEN_COOKIE = 'reactauth.token'
export const REFRESH_TOKEN_COOKIE = 'reactauth.refreshToken'
export const TENANT_ID = '3fa85f64-5717-4562-b3fc-2c963f66afa6'

export const stepsNames: string[] = [
  'Start',
  'Your Details',
  'Course',
  'Nationality Details',
  'Education',
  'Reference',
  'Additional Information',
  'Equal Opportunities',
  'Declaration',

  'Summary'
]

export const EMPTY_FORM_FIELD_CONFIGURATION = { className: '', fieldName: '', form: '', possibleValues: [], sectionPath: [] }

export const YesNo = ['Yes', 'No']

export const MULTISELECT_SEPARATOR = '{'

export enum ROLES {
  Applicant = 'Applicant',
  Agent = 'Agent',
  Admin = 'admin'
}
