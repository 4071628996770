import { InputLabel, SxProps } from '@mui/material'
import { DateView } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { parseISO } from 'date-fns'
import React from 'react'

import { formatDate } from '../../utils/date'

interface Props {
  label: string;
  value: string;
  name: string;
  handleChange: (value: string, name: string) => void
  placeholder?: string;
  required?: boolean;
  helperText?: string;
  mb?: boolean;
  mt?: boolean;
  labelSx?: SxProps
  inputSx?: SxProps
  views?: DateView[]
  disabled?: boolean
}

const BloomDatePicker = ({
  label,
  required = false,
  value,
  placeholder,
  name,
  handleChange,
  helperText,
  mt,
  mb,
  inputSx,
  labelSx,
  views,
  disabled
}: Props) => {
  return <>
    <InputLabel required={required} sx={{
      cursor: disabled ? 'not-allowed' : 'inherit',

      '& .MuiInputLabel-asterisk': {
        position: 'absolute',
        left: 0,
        top: '2.5px',
        fontSize: '16px',
        color: '#E54C17',
        cursor: disabled ? 'not-allowed' : 'inherit'
      },
      color: 'black',
      fontSize: '14px',
      mt: mt ? 3 : 0,
      paddingLeft: required ? '13px' : 0,
      mb: 1.5,
      ...labelSx
    }}>
      {label}
    </InputLabel>
    <DatePicker
      value={value && parseISO(value)}
      slotProps={{
        textField: {
          onBeforeInput: (e) => {
            e.preventDefault()
            e.stopPropagation()
          },
          helperText,
          required: false,
          fullWidth: true,
          disabled,
          variant: 'outlined',
          placeholder: placeholder || (views && views.length === 2 ? '12/1999' : '11/12/1999'),
          size: 'small',
          name,
          id: name
        }
      }}
      views={views}
      onChange={(value) => handleChange(value ? formatDate(value) : '', name)}
      sx={{
        cursor: disabled ? 'not-allowed' : 'inherit',

        '& .MuiInputBase-root': {
          cursor: disabled ? 'not-allowed' : 'inherit',

          '& input': {
            cursor: disabled ? 'not-allowed' : 'inherit'
          }
        },

        '& .MuiFormHelperText-root': {
          cursor: disabled ? 'not-allowed' : 'inherit',
          color: '#00000078',
          fontSize: '14px'
        },

        mb: mb ? 3 : 0,
        ...inputSx
      }}
      format={views && views.length === 2 ? 'MM/yyyy' : 'd/MM/yyyy'}
    />
  </>
}

export default BloomDatePicker
