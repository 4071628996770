import { createQueryHook } from '../../createQueryHook'
import { ApplicationForm } from '../../types'

type Props = {
  applicationId: string;
};

export default createQueryHook<Props, ApplicationForm>({
  getKey: ({ applicationId }) => [`app/application-forms/${applicationId}/application`],
  getEndpoint: ({ applicationId }) => `app/application-forms/${applicationId}/application`
})
