import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { ReactNode } from 'react'

export interface ConfirmationDialogProps {
  onClose: (confirm?: boolean) => void;
  title: string
  children: ReactNode
  isOpen: boolean
}

const ConfirmationDialog = ({ onClose, title, children, isOpen }: ConfirmationDialogProps) => {
  const handleCancel = () => {
    onClose()
  }

  const handleOk = () => {
    onClose(true)
  }

  return (
    <Dialog
      keepMounted
      sx={{
        '& .MuiDialog-paper':
        {
          width: '100%',
          maxHeight: 435,
          justifyContent: 'center',
          justifyItems: 'center',
          display: 'flex',
          alignItems: 'center'
        }
      }}
      maxWidth="xs"
      open={isOpen}
    >
      <DialogTitle sx={{ pt: 3, pb: 2 }}>{title}</DialogTitle>
      <DialogContent sx={{ textAlign: 'center', maxWidth: '100%' }} dividers>
        {children}
      </DialogContent>
      <DialogActions sx={{
        pt: 0,
        pb: 3,
        marginTop: 2,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
      }}>
        <Button autoFocus variant='outlined' onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleOk}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
