import { useContext } from 'react'

import { AgentContext } from '../../contexts'

function useAgentData () {
  const data = useContext(AgentContext)

  return data
}

export default useAgentData
