import { ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Navigate } from 'react-router-dom'

import { ErrorDocument } from '../../components'
import { useUserSession } from '../../hooks/useUserSession'

type Props = {
  children: ReactNode
}

function PublicRoute (props: Props) {
  const { children } = props

  const { isAuthenticated } = useUserSession()

  return isAuthenticated
    ? <Navigate to="/" />
    : <ErrorBoundary
      fallback={
        <ErrorDocument
          header="Oops, it s an Error!"
          message="Something went wrong loading this page. Please try again later."
        />
      }
    >
      {children}
    </ErrorBoundary>
}

export default PublicRoute
