import { createMutationHook } from '../../createMutationHook'
import { ApplicationForm, FormSummary } from '../../types'
import { useDashboardData } from '../queries'

export default createMutationHook<ApplicationForm, FormSummary>({
  httpMethod: 'PUT',
  getEndpoint: () => 'app/application-forms/application',
  getApiClientConfig: (payload) => {
    return {
      data: payload
    }
  },
  getQueryOptions: (queryClient) => ({
    onSuccess: (_data) => {
      queryClient.invalidateQueries(useDashboardData.getKey(undefined))
    }
  })
})
