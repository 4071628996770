/* eslint-disable react/display-name */

import ImpersonationIcon from '@mui/icons-material/TransferWithinAStation'
import { CircularProgress, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { DataGrid, GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid'

import { Header } from '../../../components'
import { useAgentData, useUserSession } from '../../../hooks'

const iconStyles = {
  width: '18px',
  height: '18px',
  minWidth: '18px',
  marginRight: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const getColumns = (onImpersonateClick: (userId: GridRowId) => void): GridColDef[] => {
  return [
    {
      field: 'firstName',
      flex: 1,
      headerName: 'Name'
    },
    {
      field: 'surname',
      flex: 1,
      headerName: 'Surname'
    },
    {
      field: 'userName',
      flex: 2,
      headerName: 'Email'
    },
    {
      field: 'applicationStatus',
      flex: 1,
      headerName: 'App. Status',
      renderCell: (params) => {
        switch (params.row.applicationStatus) {
          case 'Submitted':
            return <Typography color="success.main">Submitted</Typography>
          case 'InProgress':
            return <Typography color="warning.main">In Progress</Typography>
          case 'New':
            return <Typography>New</Typography>
        }
      }
    },
    {
      field: 'decisionOutcome',
      flex: 1,
      headerName: 'Decision outcome'
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 0,
      cellClassName: 'actions',
      sortable: false,
      getActions: ({ id }) => [
        <GridActionsCellItem
          key='impersonate'
          icon={<ImpersonationIcon color="primary" sx={iconStyles}/>}
          label="Impersonate"
          size="large"
          onClick={() => onImpersonateClick(id)}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

            '&::after': {
              content: '"Impersonate"',
              textTransform: 'uppercase',
              fontSize: '10px',
              fontWeight: 700,
              color: '#0F2537',
              display: 'inline-block',
              width: '100%',
              lineHeight: 1.4,
              textAlign: 'center'
            }
          }}
        />
      ]
    }
  ]
}

const ApplicantsList = () => {
  const { delegatedUsers, isLoadingDelegatedUsers } = useAgentData()
  const { impersonate } = useUserSession()

  const onImpersonateClick = (userId: GridRowId) => {
    impersonate(userId as string)
  }
  console.log(delegatedUsers)
  return (
    <Box sx={{ pl: 6, width: '100%', position: 'relative' }}>
      <Header>Applicants List</Header>
      {
        isLoadingDelegatedUsers && !delegatedUsers
          ? <CircularProgress
              sx={{
                top: '200px',
                left: 0,
                right: 0,
                position: 'absolute',
                margin: '0 auto'
              }}
            />
          : <DataGrid
            rows={delegatedUsers || []}
            columns={getColumns(onImpersonateClick)}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20
                }
              }
            }}
            pageSizeOptions={[20, 40, 100]}
            disableRowSelectionOnClick
            sx={{
              maxWidth: '100% !important',
              '& .MuiDataGrid-columnHeaders': {
                background: '#EC6605 !important'
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                color: 'white'
              },
              '& .MuiDataGrid-columnHeader--moving': {
                background: '#EC6605 !important'
              },
              '& .MuiDataGrid-cellContent': {
                paddingTop: 1,
                paddingBottom: 1
              },
              '& .MuiDataGrid-virtualScroller': {
                overflowX: 'hidden'
              },
              '& .MuiTablePagination-root': {
                color: 'black !important'
              },
              '& .MuiDataGrid-row:nth-child(odd)': {
                backgroundColor: '#F5F5F5 !important'
              }
            }}
          />
      }
    </Box>
  )
}

export default ApplicantsList
