import { Box, Stack, Typography } from '@mui/material'
import { Outlet } from 'react-router-dom'

import { AdminUserPanel } from '../../../components'
import { AdminProvider } from '../../../providers/AdminProvider'

function Dashboard () {
  return (
    <Box sx={{ width: '100%', pt: 1 }}>
      <Typography
        sx={{
          mb: 2,
          position: 'relative',
          left: '0px',
          top: '0px',
          fontWeight: 700
        }}
        variant="body2"
        color="primary"
      >
        Dashboard
      </Typography>
      <Stack direction="row">
        <AdminUserPanel />
        <AdminProvider>
          <Outlet/>
        </AdminProvider>
      </Stack>
    </Box>
  )
}

export default Dashboard
