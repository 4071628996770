import React from 'react'

import { Text, Header, BloomInput, BloomSelect, BloomDatePicker } from '../../../components'
import { useApplicationForm } from '../../../hooks/useApplicationForm'
import { YesNo } from '../../../utils/constants'

const FIELDS_CONFIG_SECTION_NAME = 'Education'
const FORM_SECTION_ID = 'educationDetails'

function Education () {
  const { getStepConfig, getFieldConfig, form, updateFormField, isFormSubmitted } = useApplicationForm()

  const stepConfig = getStepConfig(FIELDS_CONFIG_SECTION_NAME)

  if (!stepConfig) {
    throw new Error('Form configuration for this step seems to be missing :(')
  }

  const educationDetails = form && form[FORM_SECTION_ID]

  if (!educationDetails) {
    return null
  }

  return (
    <>
    <Header>English Language</Header>
    <Text>
      International applicants who require a student visa to study in the UK
    </Text>
    <br/>
    <Text>
      Because you require a student visa to study in the UK, you will need to show proof of your English Language level when making your student visa application.
    </Text>
    <br/>
    <Text>
      You can do this by passing a Secure English Language Test (SELT). A SELT is valid for a period of two years.
    </Text>
    <br/>
    <Text>
      We also accept other qualifications as proof of your English Language level.
    </Text>
    <br/>
    <Text>
      <a href="https://www.bil.ac.uk/how-to-apply/international-students/english-language-requirements/" target='_blank' rel="noreferrer">Click here</a> for full details.
    </Text>
    <br/>
    <Text>
      If you do not have a SELT or do not have one of the other qualifications we accept, you can
      take the <a href="https://passwordplus.englishlanguagetesting.co.uk/test-takers/" target='_blank' rel="noreferrer">Password Skills Plus Test</a>. It is scored on the same basis as IELTS. You can take
      the Test online anywhere in the world, even from your own home. The Tests costs £110, and
      you can complete a practice test beforehand for £40. You can book your test by <a href="https://passwordplus.englishlanguagetesting.co.uk/buy-a-test/" target='_blank' rel="noreferrer">clicking here</a>.
    </Text>
    <br/>
    <BloomSelect
      disabled={isFormSubmitted}
      required
      handleSelectChange={updateFormField}
      value={educationDetails.satisfiedEnglishLanguageRequirement}
      labelId={`${FORM_SECTION_ID}.satisfiedEnglishLanguageRequirement`}
      name={`${FORM_SECTION_ID}.satisfiedEnglishLanguageRequirement`}
      label="Do you satisfy our English Language requirements?"
      options={getFieldConfig('SatisfiedEnglishLanguageRequirement', stepConfig).possibleValues}
      mb
    />
    <Header sx={{ mt: 2 }}>Undergraduate Degree or Postgraduate Degree or Other Qualification</Header>
    <BloomSelect
      disabled={isFormSubmitted}
      required
      handleSelectChange={updateFormField}
      value={educationDetails.undergraduateQualificationCompletedInUk}
      labelId={`${FORM_SECTION_ID}.undergraduateQualificationCompletedInUk`}
      name={`${FORM_SECTION_ID}.undergraduateQualificationCompletedInUk`}
      label="Have you completed (or are you due to complete) an undergraduate or postgraduate degree or qualification in the UK or any other country?"
      options={YesNo}
      mb
    />
    <Header sx={{ mt: 2 }}>Last School, College or University Attended (at the time you will start the course)</Header>
    <BloomInput
      maxLength={getFieldConfig('lastSchoolName', stepConfig).maxLength}
      disabled={isFormSubmitted}
      handleChange={updateFormField}
      label='Name of the last School, College or University attended at the time you start the course'
      value={educationDetails.lastSchoolName}
      name={`${FORM_SECTION_ID}.lastSchoolName`}
      mb
    />
    <BloomInput
      maxLength={getFieldConfig('lastSchoolStreet', stepConfig).maxLength}
      disabled={isFormSubmitted}
      handleChange={updateFormField}
      label='Street'
      value={educationDetails.lastSchoolStreet}
      name={`${FORM_SECTION_ID}.lastSchoolStreet`}
      mb
    />
    <BloomInput
      maxLength={getFieldConfig('lastSchoolCity', stepConfig).maxLength}
      disabled={isFormSubmitted}
      handleChange={updateFormField}
      label='City'
      value={educationDetails.lastSchoolCity}
      name={`${FORM_SECTION_ID}.lastSchoolCity`}
      mb
    />
    <BloomInput
      maxLength={getFieldConfig('lastSchoolCountry', stepConfig).maxLength}
      disabled={isFormSubmitted}
      handleChange={updateFormField}
      label='Country'
      value={educationDetails.lastSchoolCountry}
      name={`${FORM_SECTION_ID}.lastSchoolCountry`}
      mb
    />
    <BloomInput
      maxLength={getFieldConfig('lastSchoolPostCode', stepConfig).maxLength}
      disabled={isFormSubmitted}
      handleChange={updateFormField}
      label='Post Code (UK Only)'
      value={educationDetails.lastSchoolPostCode}
      name={`${FORM_SECTION_ID}.lastSchoolPostCode`}
      mb
    />
    {
      educationDetails.undergraduateQualificationCompletedInUk === YesNo[0] &&
        <>
          <Header sx={{ mt: 2 }}>Undergraduate or Postgraduate Degree or Qualification</Header>
          <Text sx={{ mb: 4 }}>
            NOTE: We use the information you provide about your qualifications to decide whether
            or not you satisfy our entry criteria.  If you have more than one undergraduate or
            postgraduate degree or qualification, include your other degrees/qualifications below, in
            &quot;Post-School Qualifications (i.e. after 18 years old)&quot; and/or in &quot;Additional Qualifications&quot;.
          </Text>
          <BloomSelect
            disabled={isFormSubmitted}
            required
            handleSelectChange={updateFormField}
            value={educationDetails.undergraduateQualification.type}
            labelId={`${FORM_SECTION_ID}.undergraduateQualification.type`}
            name={`${FORM_SECTION_ID}.undergraduateQualification.type`}
            label="Type of Undergraduate or Postgraduate Degree or Qualification"
            options={getFieldConfig('undergraduateQualification.type', stepConfig).possibleValues}
            mb
          />
          <BloomInput
            maxLength={getFieldConfig('undergraduateQualification.schoolName', stepConfig).maxLength}
            disabled={isFormSubmitted}
            handleChange={updateFormField}
            label='Name of the University or College that awarded your Degree or Qualification'
            value={educationDetails.undergraduateQualification.schoolName}
            name={`${FORM_SECTION_ID}.undergraduateQualification.schoolName`}
            required
            mb
          />
          <BloomInput
            maxLength={getFieldConfig('undergraduateQualification.schoolCountry', stepConfig).maxLength}
            disabled={isFormSubmitted}
            handleChange={updateFormField}
            label='Country in which the University or College that awarded your Degree or Qualification is based'
            value={educationDetails.undergraduateQualification.schoolCountry}
            name={`${FORM_SECTION_ID}.undergraduateQualification.schoolCountry`}
            helperText='For example, Mexico'
            required
            mb
          />
          <BloomInput
            maxLength={getFieldConfig('undergraduateQualification.title', stepConfig).maxLength}
            disabled={isFormSubmitted}
            handleChange={updateFormField}
            label='What is the title of your Degree (as stated on your Degree Certificate)?'
            value={educationDetails.undergraduateQualification.title}
            name={`${FORM_SECTION_ID}.undergraduateQualification.title`}
            helperText='For example, BA with Honours Business and Management'
            required
            mb
          />
          <BloomInput
            maxLength={getFieldConfig('undergraduateQualificationSubject', stepConfig).maxLength}
            disabled={isFormSubmitted}
            handleChange={updateFormField}
            label='What Subject(s) did you study as part of your Degree?'
            value={educationDetails.undergraduateQualificationSubject}
            name={`${FORM_SECTION_ID}.undergraduateQualificationSubject`}
            helperText='For example, Business and Management'
            required
            mb
          />
          <BloomSelect
            disabled={isFormSubmitted}
            required
            handleSelectChange={updateFormField}
            value={educationDetails.undergraduateQualificationInEnglish}
            labelId={`${FORM_SECTION_ID}.undergraduateQualificationInEnglish`}
            name={`${FORM_SECTION_ID}.undergraduateQualificationInEnglish`}
            label="Was your Degree taught and assessed in English (i.e. the Medium of Instruction was English)?"
            options={YesNo}
            mb
          />
          <BloomSelect
            disabled={isFormSubmitted}
            required
            handleSelectChange={updateFormField}
            value={educationDetails.undergraduateQualificationInHonours}
            labelId={`${FORM_SECTION_ID}.undergraduateQualificationInHonours`}
            name={`${FORM_SECTION_ID}.undergraduateQualificationInHonours`}
            label="Was your Degree awarded with Honours?"
            options={getFieldConfig('UndergraduateQualificationInHonours', stepConfig).possibleValues}
            helperText='If your degree was awarded with Honours, this must be stated on your degree certificate'
            mb
          />
          <BloomInput
            maxLength={getFieldConfig('undergraduateQualification.achievedPercentage', stepConfig).maxLength}
            disabled={isFormSubmitted}
            handleChange={updateFormField}
            label='What grade or overall mark (%) did you achieve?'
            value={educationDetails.undergraduateQualification.achievedPercentage}
            name={`${FORM_SECTION_ID}.undergraduateQualification.achievedPercentage`}
            helperText='State "NA" if you have not yet completed the Qualification'
            required
            mb
          />
          <BloomInput
            maxLength={getFieldConfig('undergraduateQualification.yearOfCompletion', stepConfig).maxLength}
            disabled={isFormSubmitted}
            handleChange={updateFormField}
            label='What year did you complete (or will you complete) your Degree?'
            value={educationDetails.undergraduateQualification.yearOfCompletion}
            name={`${FORM_SECTION_ID}.undergraduateQualification.yearOfCompletion`}
            required
            helperText='For example, 2022'
            mb
          />
          {/* <BloomInput
            maxLength={getFieldConfig('undergraduateQualificationStarDate', stepConfig).maxLength}
            disabled={isFormSubmitted}
            handleChange={updateFormField}
            label="When did you start your Degree (month and year, for example 12/2018)?"
            value={educationDetails.undergraduateQualificationStarDate}
            name={`${FORM_SECTION_ID}.undergraduateQualificationStarDate`}
            required
            mb
          />
          <BloomInput
            maxLength={getFieldConfig('undergraduateQualificationCompleteDate', stepConfig).maxLength}
            disabled={isFormSubmitted}
            handleChange={updateFormField}
            label="When did you complete, or will you complete, your Degree (month and year, for example 12/2022)?"
            value={educationDetails.undergraduateQualificationCompleteDate}
            name={`${FORM_SECTION_ID}.undergraduateQualificationCompleteDate`}
            required
            mb
          /> */}
          <BloomDatePicker
            disabled={isFormSubmitted}
            label="When did you start your Degree (month and year, for example 12/2018)?"
            handleChange={updateFormField}
            value={educationDetails.undergraduateQualificationStarDate}
            name={`${FORM_SECTION_ID}.undergraduateQualificationStarDate`}
            views={['month', 'year']}
            required
            mb
          />
          <BloomDatePicker
            disabled={isFormSubmitted}
            label="When did you complete, or will you complete, your Degree (month and year, for example 12/2022)?"
            handleChange={updateFormField}
            value={educationDetails.undergraduateQualificationCompleteDate}
            name={`${FORM_SECTION_ID}.undergraduateQualificationCompleteDate`}
            views={['month', 'year']}
            required
            mb
          />
        </>
      }
      <Header sx={{ mt: 2 }}>Post-School Qualifications (i.e. after 18 years old)</Header>
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        value={educationDetails.postSchoolQualification.type}
        labelId={`${FORM_SECTION_ID}.postSchoolQualification.type`}
        name={`${FORM_SECTION_ID}.postSchoolQualification.type`}
        label="Type of post-School Qualification (i.e. after 18 years old)"
        options={getFieldConfig('PostSchoolQualification.Type', stepConfig).possibleValues}
        helperText='Pick the one that you think best describes the type of qualification, otherwise select "Other". If you do not have any post-School qualifications, select "None" and leave all the other boxes blank'
        mb
      />
      <BloomInput
        maxLength={getFieldConfig('postSchoolQualification.schoolName', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='Name of the College or Body that awarded your Qualification'
        value={educationDetails.postSchoolQualification.schoolName}
        name={`${FORM_SECTION_ID}.postSchoolQualification.schoolName`}
        mb
      />
      <BloomInput
        maxLength={getFieldConfig('postSchoolQualification.schoolCountry', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='Country in which the College or Body that awarded your Qualification is based'
        value={educationDetails.postSchoolQualification.schoolCountry}
        name={`${FORM_SECTION_ID}.postSchoolQualification.schoolCountry`}
        helperText='For example, Mexico'
        mb
      />
      <BloomInput
        maxLength={getFieldConfig('postSchoolQualification.title', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='What is the title of your Qualification (as stated on your Qualification Certificate)?'
        value={educationDetails.postSchoolQualification.title}
        name={`${FORM_SECTION_ID}.postSchoolQualification.title`}
        mb
      />
      <BloomInput
        maxLength={getFieldConfig('postSchoolQualification.achievedPercentage', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='What grade or overall mark (%) did you achieve?'
        value={educationDetails.postSchoolQualification.achievedPercentage}
        name={`${FORM_SECTION_ID}.postSchoolQualification.achievedPercentage`}
        mb
      />
      <BloomInput
        maxLength={getFieldConfig('postSchoolQualification.yearOfCompletion', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='What year did you complete your Qualification?'
        value={educationDetails.postSchoolQualification.yearOfCompletion}
        name={`${FORM_SECTION_ID}.postSchoolQualification.yearOfCompletion`}
        helperText='For example, 2022'
        mb
      />

      <Header sx={{ mt: 2 }}>Upper Secondary School Qualification (i.e. at age 17 or 18 years old)</Header>
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        value={educationDetails.upperSchoolQualification.type}
        labelId={`${FORM_SECTION_ID}.upperSchoolQualification.type`}
        name={`${FORM_SECTION_ID}.upperSchoolQualification.type`}
        label="Type of Upper Secondary School Qualification (i.e. that you completed at age 17 or 18 years old)"
        options={getFieldConfig('UpperSchoolQualification.Type', stepConfig).possibleValues}
        helperText='Pick the one that you think best describes the type of qualification, otherwise select "Other". If you do not have an Upper Secondary School qualification, select "None" and leave all the other boxes blank'
        mb
      />
      <BloomInput
        maxLength={getFieldConfig('upperSchoolQualification.schoolName', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='Name of the College or Body that awarded your Qualification'
        value={educationDetails.upperSchoolQualification.schoolName}
        name={`${FORM_SECTION_ID}.upperSchoolQualification.schoolName`}
        mb
      />
      <BloomInput
        maxLength={getFieldConfig('upperSchoolQualification.schoolCountry', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='Country in which the College or Body that awarded your Qualification is based'
        value={educationDetails.upperSchoolQualification.schoolCountry}
        name={`${FORM_SECTION_ID}.upperSchoolQualification.schoolCountry`}
        helperText='For example, Mexico'
        mb
      />
      <BloomInput
        maxLength={getFieldConfig('upperSchoolQualification.title', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='What is the title of your Qualification (as stated on your Qualification Certificate)?'
        value={educationDetails.upperSchoolQualification.title}
        name={`${FORM_SECTION_ID}.upperSchoolQualification.title`}
        mb
      />
      <BloomInput
        maxLength={getFieldConfig('upperSchoolQualification.achievedPercentage', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='What grade or overall mark (%) did you achieve?'
        value={educationDetails.upperSchoolQualification.achievedPercentage}
        name={`${FORM_SECTION_ID}.upperSchoolQualification.achievedPercentage`}
        mb
      />
      <BloomInput
        maxLength={getFieldConfig('upperSchoolQualification.yearOfCompletion', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='What year did you complete your Qualification?'
        value={educationDetails.upperSchoolQualification.yearOfCompletion}
        name={`${FORM_SECTION_ID}.upperSchoolQualification.yearOfCompletion`}
        helperText='For example, 2022'
        mb
      />

      <Header sx={{ mt: 2 }}>Secondary School Qualification (i.e. at age 15 or 16 years old)</Header>
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        value={educationDetails.secondarySchoolQualification.type}
        labelId={`${FORM_SECTION_ID}.secondarySchoolQualification.type`}
        name={`${FORM_SECTION_ID}.secondarySchoolQualification.type`}
        label="Type of Secondary School Qualification (i.e. that you completed at age 15 or 16 years old)"
        options={getFieldConfig('SecondarySchoolQualification.Type', stepConfig).possibleValues}
        helperText='Pick the one that you think best describes the type of qualification, otherwise select "Other"'
        mb
      />
      <BloomInput
        maxLength={getFieldConfig('secondarySchoolQualification.schoolName', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='Name of the College or Body that awarded your Qualification'
        value={educationDetails.secondarySchoolQualification.schoolName}
        name={`${FORM_SECTION_ID}.secondarySchoolQualification.schoolName`}
        mb
      />
      <BloomInput
        maxLength={getFieldConfig('secondarySchoolQualification.schoolCountry', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='Country in which the College or Body that awarded your Qualification is based'
        value={educationDetails.secondarySchoolQualification.schoolCountry}
        name={`${FORM_SECTION_ID}.secondarySchoolQualification.schoolCountry`}
        helperText='For example, Mexico'
        mb
      />
      <BloomInput
        maxLength={getFieldConfig('secondarySchoolQualification.title', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='What is the title of your Qualification (as stated on your Qualification Certificate)?'
        value={educationDetails.secondarySchoolQualification.title}
        name={`${FORM_SECTION_ID}.secondarySchoolQualification.title`}
        mb
      />
      <BloomInput
        maxLength={getFieldConfig('secondarySchoolQualification.achievedPercentage', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='What grade or overall mark (%) did you achieve?'
        value={educationDetails.secondarySchoolQualification.achievedPercentage}
        name={`${FORM_SECTION_ID}.secondarySchoolQualification.achievedPercentage`}
        mb
      />
      <BloomInput
        maxLength={getFieldConfig('secondarySchoolQualification.yearOfCompletion', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='What year did you complete your Qualification?'
        value={educationDetails.secondarySchoolQualification.yearOfCompletion}
        name={`${FORM_SECTION_ID}.secondarySchoolQualification.yearOfCompletion`}
        helperText='For example, 2022'
        mb
      />

      <Header sx={{ mt: 2 }}>Additional Qualifications</Header>
      <BloomInput
        maxLength={getFieldConfig('additionalQualificaitons', stepConfig).maxLength}
        disabled={isFormSubmitted}
        handleChange={updateFormField}
        label='List any additional Qualifications you have in the box below'
        value={educationDetails.additionalQualificaitons}
        name={`${FORM_SECTION_ID}.additionalQualificaitons`}
        mb
        multiline
      />
    </>
  )
}

export default Education
