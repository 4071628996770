import React from 'react'

import { Header, BloomInput, BloomSelect, BloomDatePicker } from '../../../components'
import { useApplicationForm } from '../../../hooks/useApplicationForm'
import { YesNo } from '../../../utils/constants'

const FIELDS_CONFIG_SECTION_NAME = 'Nationality Details'
const FORM_SECTION_ID = 'nationalityDetails'

function NationalityDetails () {
  const { getStepConfig, getFieldConfig, form, updateFormField, isFormSubmitted } = useApplicationForm()

  const stepConfig = getStepConfig(FIELDS_CONFIG_SECTION_NAME)

  if (!stepConfig) {
    throw new Error('Form configuration for this step seems to be missing :(')
  }

  const nationalityDetails = form && form[FORM_SECTION_ID]

  if (!nationalityDetails) {
    return null
  }

  const countries = getFieldConfig('CountryOfBirth', stepConfig).possibleValues
  const countriesWithUK = getFieldConfig('DualNationality', stepConfig).possibleValues

  return (
    <>
      <Header>Nationality</Header>
        <BloomSelect
          disabled={isFormSubmitted}
          required
          handleSelectChange={updateFormField}
          value={nationalityDetails.countryOfBirth}
          labelId={`${FORM_SECTION_ID}.countryOfBirth`}
          name={`${FORM_SECTION_ID}.countryOfBirth`}
          label="What is your country of birth?"
          mb
          helperText='For the purpose of this question, select United Kingdom if you were born in the Channel Islands or Isle of Man'
          options={countries}
        />
        <BloomSelect
          disabled={isFormSubmitted}
          required
          handleSelectChange={updateFormField}
          value={nationalityDetails.nationality}
          labelId={`${FORM_SECTION_ID}.nationality`}
          name={`${FORM_SECTION_ID}.nationality`}
          label="What is your nationality?"
          mb
          helperText={`Select your nationality as it appears in your passport.
            If you have dual nationality and you need a student visa to enter the UK, enter your first nationality
            as it is shown on the passport you intend to use when applying for your
            visa and travelling to the UK for your course`
          }
          options={countriesWithUK}
        />
        <BloomSelect
          disabled={isFormSubmitted}
          handleSelectChange={updateFormField}
          value={nationalityDetails.dualNationality}
          labelId={`${FORM_SECTION_ID}.dualNationality`}
          name={`${FORM_SECTION_ID}.dualNationality`}
          label="Dual Nationality"
          mb
          helperText='If you have dual nationality, select your first nationality in the previous box and your second nationality here'
          options={countriesWithUK}
        />
        <Header sx={{ mt: 2 }}>Previous Study in the UK with a Student or Tier 4 Visa</Header>
        <BloomSelect
          disabled={isFormSubmitted}
          required
          handleSelectChange={updateFormField}
          value={nationalityDetails.haveYouStudiedInUk}
          labelId={`${FORM_SECTION_ID}.haveYouStudiedInUk`}
          name={`${FORM_SECTION_ID}.haveYouStudiedInUk`}
          label="Have you previously studied in the UK with a Student or Tier 4 Visa?"
          mb
          options={YesNo}
        />
        <Header sx={{ mt: 2 }}>Date of Entry in the UK</Header>
        <BloomDatePicker
          disabled={isFormSubmitted}
          value={nationalityDetails.dateOfEntryInUk}
          name={`${FORM_SECTION_ID}.dateOfEntryInUk`}
          label="If you are already living in the UK, please tell us the date when you first arrived in the UK"
          handleChange={updateFormField}
          mb
        />
        <Header sx={{ mt: 2 }}>Right to Reside in the UK</Header>
        <BloomSelect
          disabled={isFormSubmitted}
          mb
          handleSelectChange={updateFormField}
          value={nationalityDetails.rightToResideInUk}
          labelId={`${FORM_SECTION_ID}.rightToResideInUk`}
          name={`${FORM_SECTION_ID}.rightToResideInUk`}
          label="If you have the right to reside in the UK, please select your residential category."
          options={getFieldConfig('RightToResideInUk', stepConfig).possibleValues}
        />
        <Header sx={{ mt: 2 }}>Passport</Header>
        <BloomSelect
          disabled={isFormSubmitted}
          required
          handleSelectChange={updateFormField}
          value={nationalityDetails.havePassport}
          labelId={`${FORM_SECTION_ID}.havePassport`}
          name={`${FORM_SECTION_ID}.havePassport`}
          label="Do you have a current passport?"
          mb
          options={YesNo}
        />
        {
          nationalityDetails.havePassport === YesNo[0] && <>
            <Header sx={{ mt: 2 }}>Passport Details</Header>
            <BloomInput
              maxLength={getFieldConfig('passportNumber', stepConfig).maxLength}
              disabled={isFormSubmitted}
              handleChange={updateFormField}
              label='Passport number'
              value={nationalityDetails.passportNumber}
              name={`${FORM_SECTION_ID}.passportNumber`}
              mb
            />
            <BloomDatePicker
              disabled={isFormSubmitted}
              name={`${FORM_SECTION_ID}.passportIssueDate`}
              value={nationalityDetails.passportIssueDate}
              label="Date your passport was issued"
              handleChange={updateFormField}
              mb
            />
            <BloomDatePicker
              disabled={isFormSubmitted}
              value={nationalityDetails.passportExpiryDate}
              name={`${FORM_SECTION_ID}.passportExpiryDate`}
              label="Date your passport expires"
              handleChange={updateFormField}
              mb
            />
            <BloomInput
              maxLength={getFieldConfig('passportPlaceOfIssue', stepConfig).maxLength}
              disabled={isFormSubmitted}
              handleChange={updateFormField}
              label='Place where your passport was issued'
              value={nationalityDetails.passportPlaceOfIssue}
              name={`${FORM_SECTION_ID}.passportPlaceOfIssue`}
              mb
            />
          </>
        }
     </>
  )
}

export default NationalityDetails
