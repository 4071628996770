import { Stack, CircularProgress } from '@mui/material'
import React, { useEffect } from 'react'
import { useAuth } from 'react-oidc-context'

function Login () {
  const auth = useAuth()

  useEffect(() => {
    if (!auth.isLoading && !auth.isAuthenticated) {
      auth.signinRedirect()
    }
  }, [auth.isLoading, auth.isAuthenticated])

  return (
    <Stack direction="column" sx={{ maxWidth: '650px', width: '50%', alignSelf: 'center' }}>
      <CircularProgress sx={{ position: 'absolute', top: '50%', margin: '0 auto', left: 0, right: 0 }}/>
    </Stack>
  )
}

export default Login
