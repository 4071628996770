import { convertToLocalTime } from 'date-fns-timezone'

export const parseDate = (date?: string, withTime = false) => {
  try {
    if (!date) {
      return '-'
    }

    const _date = new Date(date)
    const parsedDate = _date.toLocaleDateString()

    if (!withTime) {
      return parsedDate
    }

    const localTime = _date.toLocaleTimeString()

    return `${parsedDate}, ${localTime}`
  } catch (e) {
    console.log(e)

    return '-'
  }
}

/**
 * Localize date and return as ISO
 *
 * @param date
 */
export const formatDate = (dateValue: Date) => {
  try {
    const date = dateValue || new Date()
    const _date = new Date(date.getTime() - date.getTimezoneOffset() * 60000)

    // Get the timezone from browser using native methods
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const localDate = convertToLocalTime(_date, {
      timeZone: timezone
    })

    return localDate.toISOString()
  } catch (e) {
    console.log(e)

    return '-'
  }
}
