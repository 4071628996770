import { Dispatch, SetStateAction, createContext } from 'react'

import { DelegateUser } from '../../services/types'

export type AgentDataContext = {
  isImpersonating?: boolean
  setIsImpersonating: Dispatch<SetStateAction<boolean>>

  delegatedUsers?: readonly DelegateUser[]
  isLoadingDelegatedUsers: boolean
}

const AgentContext = createContext({} as AgentDataContext)

export default AgentContext
