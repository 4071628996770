import React from 'react'

import { Text, Header, BloomInput, BloomSelect, ParagraphHeader } from '../../../components'
import { useApplicationForm } from '../../../hooks/useApplicationForm'
import { YesNo } from '../../../utils/constants'
import { decapitalizeFirstLetter, convertSelectOptionToFormKey } from '../../../utils/string'

const FIELDS_CONFIG_SECTION_NAME = 'Course'
const FORM_SECTION_ID = 'courseDetails'

const degreeHelperTexts = {
  'Undergraduate 3-Year Degree': `
    NOTE: This course is delivered over 3 days each week, Monday to Friday, 9am to 5pm.
    In Year 1, you will attend 12 hours of classes each week, and you will need to be available to attend your classes on the 3 days when they are scheduled.
  `,

  'Undergraduate 2-Year Accelerated Degree':
    `NOTE: This course is delivered over 3 days each week, Monday to Friday, 9am to 5pm.
    In Year 1 (Level 4 modules) you will attend 12 hours of classes each week, and you will need to be available to attend your classes on the 3 days when they are scheduled.`,

  'Undergraduate 1-Year Top-up Degree':
    `NOTE: This course is delivered over 3 days each week, Monday to Friday, 9am to 5pm.
    You will attend 8 hours of classes each week, and you will need to be available to attend your classes on the 3 days when they are scheduled.`,

  'Postgraduate Degree':
    `NOTE: This course is delivered over 2 days each week, Monday to Friday, 9am to 5pm.
    You will attend 6 hours of classes each week, and you will need to be available to attend your classes on the 2 days when they are scheduled.`,

  '2 Years\' Postgraduate Experience':
    '-'
}

const onDegreeChangeResets = [
  `${FORM_SECTION_ID}.postgraduateStartDate`,
  `${FORM_SECTION_ID}.undergraduate2YearDegreeStartDate`,
  `${FORM_SECTION_ID}.undergraduate1YearStartDate`,
  `${FORM_SECTION_ID}.undergraduate3YearDegreeStartDate`,

  `${FORM_SECTION_ID}.twoYearsPostgraduateExperience`,
  `${FORM_SECTION_ID}.detailsOfPostgraduateExperience`
]

const INDIAN_COUNTRIES = [
  'india'
]

const getIndianText = () => {
  return <Text>
    If you need any information about the types of course we offer, and the entry requirements, <a href="https://www.bil.ac.uk/" target='_blank' rel="noreferrer">please visit our website</a>.{' '}
    <br/>
    <br/>
    Briefly, the types of course we offer are as follows:
    <br/>
    <ParagraphHeader>Undergraduate Top-up degrees: <u>1 Year</u> - February, June or October start</ParagraphHeader>
    If you have a Pearson or SQA HND or other Level 4 and 5 qualification in a related discipline, you will be able to join the final year of the undergraduate degree (Top-up) over 1 year.  We also accept Ofqual-accredited qualifications, e .g. ATHE and Qualifi (Pass, 120 credits at Level 4 and 120 credits at Level 5).  For the LLB (Hons) Law and Legal Practice, your Level 4 and 5 qualification must be in <u>English Law</u>.
    <br />
    <br />
    You will attend classes for 2 Terms each year.  Each Term lasts for 11 weeks, but before each Term starts you will complete 2 weeks of online pre-reading and exercises.
    <ParagraphHeader>Postgraduate MBA: <u>1 Year</u> - February, June or October start</ParagraphHeader>
    If you have a degree that is equivalent to a UK honours degree in any subject, you will be able to complete the MBA over 1 year.   We also accept Ofqual-accredited qualifications, e .g. ATHE and Qualifi (Pass, 120 credits at Level 4, 120 credits at Level 5 and 120 credits at Level 6).
    <br/>
    <br/>
    2 years` managerial/supervisory experience is required for the MBA.
    <br/>
    <br/>
    You will attend classes for 2 Terms each year. Each Term lasts for 11 weeks, but before each Term starts you will complete 2 weeks of online pre-reading and exercises. In Term 3 you will complete a Dissertation/Research Project.
    <ParagraphHeader>Postgraduate MSc Management: <u>1 Year</u> - February, June or October start</ParagraphHeader>
    If you have a degree that is equivalent to a UK honours degree in any subject, you will be able to complete the MBA over 1 year.   We also accept Ofqual-accredited qualifications, e .g. ATHE and Qualifi (Pass, 120 credits at Level 4, 120 credits at Level 5 and 120 credits at Level 6).
    <br/>
    <br/>
    Work experience is <u>not</u> required for the MSc Management.
    <br/>
    <br/>
    You will attend classes for 2 Terms each year. Each Term lasts for 11 weeks, but before each Term starts you will complete 2 weeks of online pre-reading and exercises. In Term 3 you will complete a Dissertation/Research Project.
    <ParagraphHeader>Postgraduate MSc Accounting and Finance: <u>1 Year</u> - February, June or October start</ParagraphHeader>
    If you have a degree that is equivalent to a UK honours degree in accounting, finance, banking or a related discipline, you will be able to complete the MSc Accounting and Finance over 1 year.   We also accept Ofqual-accredited qualifications, e .g. ATHE and Qualifi (Pass, 120 credits at Level 4, 120 credits at Level 5 and 120 credits at Level 6).
    <br/>
    <br/>
    Work experience is <u>not</u> required for the MSc Accounting and Finance.
    <br/>
    <br/>
    You will attend classes for 2 Terms each year. Each Term lasts for 11 weeks, but before each Term starts you will complete 2 weeks of online pre-reading and exercises. In Term 3 you will complete a Dissertation/Research Project.
    <ParagraphHeader>Postgraduate MSc Finance and Wealth Management: <u>1 Year</u> - February, June or October start</ParagraphHeader>
    If you have a degree that is equivalent to a UK honours degree in accounting, finance, banking, economics or any business-related subject with quantitative skills or in any quantitative subject such as engineering, mathematics, statistics or sciences, you will be able to complete the MSc Finance and Wealth Management over 1 year. We also accept Ofqual-accredited qualifications, e .g. ATHE and Qualifi (Pass, 120 credits at Level 4, 120 credits at Level 5 and 120 credits at Level 6).
    <br/>
    <br/>
    Work experience is <u>not</u> required for the MSc Finance and Wealth Management.
    <br/>
    <br/>
    You will attend classes for 2 Terms each year. Each Term lasts for 11 weeks, but before each Term starts you will complete 2 weeks of online pre-reading and exercises. In Term 3 you will complete a Dissertation/Research Project.
    <ParagraphHeader>Postgraduate LLM English Legal Practice: <u>1 Year</u> - February, June or October start [Subject to Final Approval]</ParagraphHeader>
    If you have a degree that is equivalent to a UK honours degree in law (from any jurisdiction, i.e. it does not have to be in English Law), you will be able to complete the LLM English Legal Practice over 1 year.   We also accept Ofqual-accredited qualifications, e .g. ATHE and Qualifi (Pass, 120 credits at Level 4, 120 credits at Level 5 and 120 credits at Level 6).
    <br/>
    <br/>
    Work experience is <u>not</u> required for the LLM English Legal Practice.
    <br/>
    <br/>
    You will attend classes over 3 Terms.   Each Term lasts for 11 weeks, but before Terms 2 and 3 start you will complete 2 weeks of online pre-reading and exercises.
  </Text>
}

function Course () {
  const { getStepConfig, getFieldConfig, form, updateFormField, isFormSubmitted } = useApplicationForm()

  const stepConfig = getStepConfig(FIELDS_CONFIG_SECTION_NAME)

  if (!stepConfig) {
    throw new Error('Form configuration for this step seems to be missing :(')
  }

  const courseTypeConfig = getFieldConfig('typeOfCourse', stepConfig).possibleValues

  const course = form && form[FORM_SECTION_ID]

  if (!course) {
    return null
  }

  const degreeKey = convertSelectOptionToFormKey(course.typeOfCourse, [['PostgraduateDegree', 'Postgraduate']])

  const smallLetterDegreeKey = decapitalizeFirstLetter(degreeKey)
  // @ts-ignore
  const degreeValue = course[smallLetterDegreeKey]

  const startDateKey = `${convertSelectOptionToFormKey(
    course.typeOfCourse,
    [
      ['PostgraduateDegree', 'Postgraduate'],
      ['Undergraduate2YearAcceleratedDegree', 'Undergraduate2YearDegree'],
      ['Undergraduate1YearTopUpDegree', 'Undergraduate1Year']
    ]
  )}StartDate`

  const firstLetterSmallStartDateKey = decapitalizeFirstLetter(startDateKey)
  // @ts-ignore
  const startDateValue = course[firstLetterSmallStartDateKey]
  const fromIndianSubcontinent = INDIAN_COUNTRIES.includes(form.personalDetails.contactDetails.country.toLowerCase())

  return (
    <>
      <Header>Type of Course</Header>
      {
        fromIndianSubcontinent
          ? getIndianText()
          : <Text>
            If you need any information about the types of course we offer, and the entry requirements, <a href="https://www.bil.ac.uk/" target='_blank' rel="noreferrer">please visit our website</a>.{' '}
            <br/>
            <br/>
            Briefly, the types of course we offer are as follows:
            <br/>
            <ParagraphHeader>Undergraduate degrees: <u>3 Years</u> or <u>2 Years accelerated</u> - October start only</ParagraphHeader>
            If you have A-Levels or other Level 3 qualifications, you will be eligible for our undergraduate degree over <u>3 years</u> or <u>2 years (accelerated)</u>. If you opt to take it over 3-years, you will attend classes for 2 Terms each year. If you opt to take it over 2 years, you will attend classes for 3 Terms each year. Each Term lasts for 11 weeks, but before each Term starts you will complete 2 weeks of online pre-reading and exercises.
            <ParagraphHeader>Undergraduate Top-up degrees: <u>1 Year</u> - February, June or October start</ParagraphHeader>
            If you have a Pearson or SQA HND or other Level 4 and 5 qualification in a related discipline, you will be able to join the final year of the undergraduate degree (Top-up) over 1 year. We also accept Ofqual-accredited qualifications, e .g. ATHE and Qualifi (Pass, 120 credits at Level 4 and 120 credits at Level 5). For the LLB (Hons) Law and Legal Practice, your Level 4 and 5 qualification must be in <u>English Law</u>.
            <br />
            <br />
            You will attend classes for 2 Terms each year. Each Term lasts for 11 weeks, but before each Term starts you will complete 2 weeks of online pre-reading and exercises.
            <ParagraphHeader>Postgraduate MBA: <u>1 Year</u> - February, June or October start</ParagraphHeader>
            If you have a degree that is equivalent to a UK honours degree in any subject, you will be able to complete the MBA over 1 year. We also accept Ofqual-accredited qualifications, e .g. ATHE and Qualifi (Pass, 120 credits at Level 4, 120 credits at Level 5 and 120 credits at Level 6).
            <br/>
            <br/>
            2 years` managerial/supervisory experience is required for the MBA.
            <br/>
            <br/>
            You will attend classes for 2 Terms each year. Each Term lasts for 11 weeks, but before each Term starts you will complete 2 weeks of online pre-reading and exercises. In Term 3 you will complete a Dissertation/Research Project.
            <ParagraphHeader>Postgraduate MSc Management: <u>1 Year</u> - February, June or October start</ParagraphHeader>
            If you have a degree that is equivalent to a UK honours degree in any subject, you will be able to complete the MBA over 1 year. We also accept Ofqual-accredited qualifications, e .g. ATHE and Qualifi (Pass, 120 credits at Level 4, 120 credits at Level 5 and 120 credits at Level 6).
            <br/>
            <br/>
            Work experience is <u>not</u> required for the MSc Management.
            <br/>
            <br/>
            You will attend classes for 2 Terms each year.   Each Term lasts for 11 weeks, but before each Term starts you will complete 2 weeks of online pre-reading and exercises.  In Term 3 you will complete a Dissertation/Research Project.
            <ParagraphHeader>Postgraduate MSc Accounting and Finance: <u>1 Year</u> - February, June or October start</ParagraphHeader>
            If you have a degree that is equivalent to a UK honours degree in accounting, finance, banking or a related discipline, you will be able to complete the MSc Accounting and Finance over 1 year.   We also accept Ofqual-accredited qualifications, e .g. ATHE and Qualifi (Pass, 120 credits at Level 4, 120 credits at Level 5 and 120 credits at Level 6).
            <br/>
            <br/>
            Work experience is <u>not</u> required for the MSc Accounting and Finance.
            <br/>
            <br/>
            You will attend classes for 2 Terms each year.   Each Term lasts for 11 weeks, but before each Term starts you will complete 2 weeks of online pre-reading and exercises.  In Term 3 you will complete a Dissertation/Research Project.
            <ParagraphHeader>Postgraduate MSc Finance and Wealth Management: <u>1 Year</u> - February, June or October start</ParagraphHeader>
            If you have a degree that is equivalent to a UK honours degree in accounting, finance, banking, economics or any business-related subject with quantitative skills or in any quantitative subject such as engineering, mathematics, statistics or sciences, you will be able to complete the MSc Finance and Wealth Management over 1 year.   We also accept Ofqual-accredited qualifications, e .g. ATHE and Qualifi (Pass, 120 credits at Level 4, 120 credits at Level 5 and 120 credits at Level 6).
            <br/>
            <br/>
            Work experience is <u>not</u> required for the MSc Finance and Wealth Management.
            <br/>
            <br/>
            You will attend classes for 2 Terms each year. Each Term lasts for 11 weeks, but before each Term starts you will complete 2 weeks of online pre-reading and exercises. In Term 3 you will complete a Dissertation/Research Project.
            <ParagraphHeader>Postgraduate LLM English Legal Practice: <u>1 Year</u> - February, June or October start [Subject to Final Approval]</ParagraphHeader>
            If you have a degree that is equivalent to a UK honours degree in law (from any jurisdiction, i.e. it does not have to be in English Law), you will be able to complete the LLM English Legal Practice over 1 year.   We also accept Ofqual-accredited qualifications, e .g. ATHE and Qualifi (Pass, 120 credits at Level 4, 120 credits at Level 5 and 120 credits at Level 6).
            <br/>
            <br/>
            Work experience is <u>not</u> required for the LLM English Legal Practice.
            <br/>
            <br/>
            You will attend classes over 3 Terms. Each Term lasts for 11 weeks, but before Terms 2 and 3 start you will complete 2 weeks of online pre-reading and exercises.
            </Text>
      }
      <br/>
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        value={course.typeOfCourse}
        labelId={`${FORM_SECTION_ID}.typeOfCourse`}
        name={`${FORM_SECTION_ID}.typeOfCourse`}
        label="Type of course"
        resetOnChange={[
          `${FORM_SECTION_ID}.undergraduate3YearDegree`,
          `${FORM_SECTION_ID}.undergraduate2YearAcceleratedDegree`,
          `${FORM_SECTION_ID}.undergraduate1YearTopUpDegree`,

          `${FORM_SECTION_ID}.postgraduateStartDate`,
          `${FORM_SECTION_ID}.undergraduate2YearDegreeStartDate`,
          `${FORM_SECTION_ID}.undergraduate1YearStartDate`,
          `${FORM_SECTION_ID}.undergraduate3YearDegreeStartDate`,

          `${FORM_SECTION_ID}.postgraduate`,
          `${FORM_SECTION_ID}.twoYearsPostgraduateExperience`,
          `${FORM_SECTION_ID}.detailsOfPostgraduateExperience`
        ]}
        mb
        // @ts-ignore
        options={fromIndianSubcontinent
          ? [courseTypeConfig[2], courseTypeConfig[3]]
          : courseTypeConfig
        }
      />
      {
        !!course.typeOfCourse &&
          <>
            <Header sx={{ mt: 2 }}>{course.typeOfCourse}</Header>
            <Text sx={{ mb: 4 }}>Select the degree you are applying for</Text>
            <BloomSelect
              disabled={isFormSubmitted}
              required
              handleSelectChange={updateFormField}
              value={degreeValue}
              labelId={`${FORM_SECTION_ID}.${smallLetterDegreeKey}`}
              name={`${FORM_SECTION_ID}.${smallLetterDegreeKey}`}
              label="Degree"
              // @ts-ignore
              helperText={degreeHelperTexts[course.typeOfCourse]}
              mb
              resetOnChange={onDegreeChangeResets}
              options={
                getFieldConfig(
                  convertSelectOptionToFormKey(
                    course.typeOfCourse,
                    [['PostgraduateDegree', 'Postgraduate']]
                  ),
                  stepConfig
                ).possibleValues
              }
            />
          </>
      }
      {
        course.typeOfCourse === 'Postgraduate Degree' && course.postgraduate === 'MBA' && <>
          <Header sx={{ mt: 2 }}>2 Years Postgraduate Experience</Header>
          <BloomSelect
            disabled={isFormSubmitted}
            required
            handleSelectChange={updateFormField}
            value={course.twoYearsPostgraduateExperience}
            labelId={`${FORM_SECTION_ID}.twoYearsPostgraduateExperience`}
            name={`${FORM_SECTION_ID}.twoYearsPostgraduateExperience`}
            label="Do you have 2 years' managerial/supervisory experience?"
            helperText={'Note: For the MBA, you require 2 years "managerial/supervisory experience (this does not have to be management of people; it could, for example, include running your own business).  If you do not have 2 years" experience, you would be eligible to apply for the MSc Management.'}
            mb
            resetOnChange={[`${FORM_SECTION_ID}.detailsOfPostgraduateExperience`]}
            options={YesNo}
          />
        </>
      }
      {
        course.typeOfCourse === 'Postgraduate Degree' && course.postgraduate === 'MBA' && course.twoYearsPostgraduateExperience === YesNo[0] && <>
          <Header sx={{ mt: 2 }}>Details of Postgraduate Experience</Header>
          <Text sx={{ mb: 4 }}>Please provide details of your postgraduate experience</Text>
          <BloomInput
            disabled={isFormSubmitted}
            multiline
            mb
            required
            handleChange={updateFormField}
            label='Please provide details of your postgraduate experience (max chars.: 500)'
            name={`${FORM_SECTION_ID}.detailsOfPostgraduateExperience`}
            value={course.detailsOfPostgraduateExperience}
            helperText={`
              Please provide details of what you have been doing since you graduated.

              Please state month and year, and if you were working, the name of your employer and your position.

              If you were not working, state what you were doing (e.g. study; maternity leave; caring for a family member; travelling).  For example:

              08/21 to date: British Airways - Senior Administrative Executive
              05/19 to 08/21: Air France - Administrative Executive
              01/18 to 05/19: Maternity Leave
              08/16 to 01/18: TAP - Administrative Assistant`}
          />
        </>
      }
      {
        course.typeOfCourse &&
          <>
            <Header sx={{ mt: 2 }}>Start Date</Header>
              <Text sx={{ mb: 4 }}>Select the date you want to start the course [Note: The first week of the course is Welcome Week, that includes Induction and Enrolment]:</Text>
              <BloomSelect
                disabled={isFormSubmitted}
                required
                handleSelectChange={updateFormField}
                value={startDateValue}
                labelId={`${FORM_SECTION_ID}.${firstLetterSmallStartDateKey}`}
                name={`${FORM_SECTION_ID}.${firstLetterSmallStartDateKey}`}
                label="Start Date"
                mb
                options={getFieldConfig(startDateKey, stepConfig).possibleValues}
              />
            </>
        }
    </>
  )
}

export default Course
