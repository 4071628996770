import { VisibilityOff, Visibility } from '@mui/icons-material'
import { InputAdornment, IconButton } from '@mui/material'
import React from 'react'

interface Props {
  handleClickShowPassword: () => void
  showPassword: boolean
};

function PasswordInputEndAdornment ({ handleClickShowPassword, showPassword }: Props) {
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  )
}

export default PasswordInputEndAdornment
