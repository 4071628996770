/* eslint-disable */

import { AxiosError } from 'axios'
import { ReactNode, useEffect, useState } from 'react'

import { AuthContext, AuthUser } from '../../contexts'
import { APIError, RegisterCredentials, RegisterResult } from '../../contexts/AuthContext/AuthContext'
import { api } from '../../services/api'
import { setAuthorizationHeader, setFormDataHeader, unsetFormDataHeader } from '../../services/interceptors'
import { removeTokenCookies, createTokenCookies } from '../../utils/tokenCookies'
import { useForgotPassword, useResetPassword, useAppConfiguration, useImpersonation } from '../../services/hooks'
import { useAuth } from "react-oidc-context";
import { User } from 'oidc-client-ts'

type Props = {
  children: ReactNode
}

export enum AppViewMode {
  'dashboard' = 'dashboard',
  'form' = 'application-form'
}

function AuthProvider (props: Props) {
  const auth = useAuth();
  const { children } = props
  const { mutateAsync: sendForgotPassword } = useForgotPassword()
  const { mutateAsync: sendResetPassword } = useResetPassword()
  const { mutateAsync: impersonateUser } = useImpersonation()
  const { refetch: fetchAppConfiguration } = useAppConfiguration({ queryOptions: { enabled: false } })

  const [authUser, setAuthUser] = useState<AuthUser | null>()
  const [loadingUserData, setLoadingUserData] = useState(true)
  const [viewMode, setViewMode] = useState<AppViewMode>(AppViewMode.form)
  const isAuthenticated = auth.isAuthenticated

  // const [impersonatedUserId, setImpersonatedUserId] = useState('')
  const [impersonatorAuthUser, setImpersonatorAuthUser] = useState<User | null>()

  useEffect(() => {
    (async () => {
      try {
        if (!auth.isLoading) {
          if (auth.isAuthenticated) {
              const token = auth.user?.access_token;
              const refreshToken = auth.user?.refresh_token

              if (token) {
                signIn(token, refreshToken)
              }
          } else {
            clearLocalUserData()
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
}, [auth]);

  async function registerWithProfile (data: RegisterCredentials): Promise<RegisterResult> {
    try {
      const response = await api.post('app/account-extensions/register-with-profile', { ...data, userName: data.emailAddress }, { withCredentials: false })

      return { success: true, data: response.data }
    } catch (e: any) {
      return { success: false, error: e?.response?.data?.error as APIError }
    }
  }

  async function confirmEmail(userId: string, token: string): Promise<{ success: boolean }> {
    try {
      const result = await api.post('account/confirm-email', { userId, token }, { withCredentials: false })

      if (result.status >= 200 && result.status <= 300) {
        return { success: true }
      } else {
        return { success: false }
      }
    } catch (e) {
      return { success: false }
    }
  }

  async function resetPassword(userId: string, resetToken: string, password: string): Promise<{ success: boolean }> {
    try {
      await sendResetPassword({ userId, resetToken, password })

      return { success: true }
    } catch (e) {
      return { success: false }
    }
  }

  async function forgotPassword (emailAddress: string): Promise<{ success: boolean}> {
    try {
      await sendForgotPassword({ emailAddress })

      return { success: true }
    } catch (e) {
      return { success: false }
    }
  }

  async function signIn (token: string, refresh_token: string = "", impersonationSignIn: boolean = false) {
    try {
      setLoadingUserData(true)

      if (impersonationSignIn) {
        setImpersonatorAuthUser(auth.user)
      }

      setAuthorizationHeader(api.defaults, token)
      createTokenCookies(token, refresh_token)

      const { data } = await fetchAppConfiguration()

      if (data) {
        const { email, roles } = data.currentUser;

        setAuthUser({ email, permissions: ['users.list', 'users.create'], roles })

        setLoadingUserData(false)
      }
    } catch (e) {
      console.log(e)
      console.log("Error while signIn: " + e)
    }
  }
  function stopImpersonation() {
    const impersonatorAuthData = impersonatorAuthUser

    if (impersonatorAuthData && impersonatorAuthData.access_token && impersonatorAuthData.refresh_token) {
      setImpersonatorAuthUser(null)
      signIn(impersonatorAuthData.access_token, impersonatorAuthData.refresh_token)
    }
  }

  function clearLocalUserData() {
    removeTokenCookies()
    setAuthUser(null)
    setLoadingUserData(false)
  }

  const impersonate = async (userToImpersonateId: string) => {
    const token = auth.user?.access_token;

    if (!token) {
      return
    }

    setFormDataHeader(api.defaults)

    const { access_token, refresh_token } = await impersonateUser({ accessToken: token, userId: userToImpersonateId })

    unsetFormDataHeader(api.defaults)

    if (access_token && refresh_token) {
      console.log("SIGNING IN WITH IMPERSONATED DATA...")

      signIn(access_token, refresh_token, true)
    }



    // const configResponse = await fetch("https://localhost:44395/api/abp/application-configuration?includeLocalizationResources=false", {
    //             headers: {
    //                 Authorization: `Bearer ${newTokenObj.access_token}`,
    //             }
    //         });

    // setUserDetails(configObj.currentUser);
    // setImpersonated(!!configObj?.currentUser?.impersonatorUserId);

    // window.location.href="/";
  }

  async function signOut (pathname = '/') {
    try {
      setLoadingUserData(true)

      await auth.signoutRedirect()

      clearLocalUserData()
    } catch(e) {
      const error = e as AxiosError
    }
  }

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      authUser: authUser as AuthUser,
      loadingUserData,
      signIn,
      signInRedirect: () => {
        return auth.signinRedirect()
      },
      signOut,
      registerWithProfile,
      confirmEmail,
      viewMode,
      forgotPassword,
      resetPassword,
      setViewMode,
      stopImpersonation,
      impersonate,
      isImpersonating: !!impersonatorAuthUser
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
