import { ReactNode, useMemo } from 'react'

import { DashboardDataContext } from '../../contexts'
import { useDashboardData } from '../../services/hooks'

type Props = {
  children: ReactNode
}

function DashboardDataProvider ({ children }: Props) {
  const {
    data,
    isLoading
  } = useDashboardData()

  const applicationFormSubmitted = useMemo(() => data?.applicationForm?.displayName?.toLowerCase() === 'submitted', [data])

  return (
    <DashboardDataContext.Provider value={{
      isLoading,
      data,
      applicationFormSubmitted
    }}>
      {children}
    </DashboardDataContext.Provider>
  )
}

export default DashboardDataProvider
