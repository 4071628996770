import { Box } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { QueryClientProvider } from '@tanstack/react-query'
import { AuthProvider as OIDCAuthProvider } from 'react-oidc-context'
import { BrowserRouter } from 'react-router-dom'

import { NavBar } from './components'
import { SnackbarProvider } from './hooks/useSnackbar'
import { ApplicationFormProvider, AuthProvider, ThemeProvider } from './providers'
import { RouteList } from './router'
import { queryClient } from './services/queryClient'

const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_CONFIG_AUTHORITY || '',
  client_id: process.env.REACT_APP_OIDC_CONFIG_CLIENT_ID || '',
  redirect_uri: process.env.REACT_APP_OIDC_CONFIG_REDIRECT_URI || '',
  post_logout_redirect_uri: process.env.REACT_APP_OIDC_CONFIG_POST_LOGOUT_REDIRECT_URI || '',
  response_type: process.env.REACT_APP_OIDC_CONFIG_RESPONSE_TYPE || '',
  scope: 'offline_access BloomInternational'
}

const App = () => {
  return (
    <OIDCAuthProvider {...oidcConfig}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ThemeProvider>
              <AuthProvider>
                <SnackbarProvider>
                  <ApplicationFormProvider>
                    <Box sx={{ alignContent: 'center', justifyContent: 'center', display: 'flex', flex: 1, alignItems: 'center' }}>
                      <Box sx={{ alignContent: 'center', justifyContent: 'center', display: 'flex', flex: 1, height: 'calc(100% - 50px)', width: 'calc(100vw - 32px)', maxWidth: '1460px', alignItems: 'center', flexDirection: 'column', p: 2 }}>
                        <NavBar />
                        <RouteList />
                      </Box>
                    </Box>
                  </ApplicationFormProvider>
                </SnackbarProvider>
              </AuthProvider>
            </ThemeProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </LocalizationProvider>
    </OIDCAuthProvider>
  )
}

export default App
