import RotateRightIcon from '@mui/icons-material/RotateRight'
import SyncLockIcon from '@mui/icons-material/SyncLock'
import UpdateIcon from '@mui/icons-material/Update'
import { Button, CircularProgress, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form'

import { BloomInput, Header, Paper, PasswordInputEndAdornment } from '../../components'
import { useUserData } from '../../hooks/useUserData'
import { UserDataProvider } from '../../providers'
import { PASS_VALIDATION_PATTERN } from '../../utils/validations'

type FormData = {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
};

const MyProfile = () => {
  const { user, loadingUserData, updateUserDataField, saveUserData, changePassword } = useUserData()

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors, isValid },
    // getValues,
    reset,
    setError,
    watch
  } = useForm<FormData>({ mode: 'onChange' })
  const [resetRequestStatus, setResetRequestStatus] = useState('success')
  const [showPassword, setShowPassword] = useState(false)

  const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues, e) => {
    try {
      const typed = data as FormData

      e?.preventDefault()

      setResetRequestStatus('loading')

      await changePassword(typed.currentPassword, typed.newPassword)

      reset()

      setResetRequestStatus('success')
    } catch (e: any) {
      const error = e as AxiosError

      setResetRequestStatus('failed')
      setError('root', { message: error.message }) // TODO: manage errors here and on every page before login
    }
  }

  useEffect(() => {
    // clean the function to fix memory leak
    return () => setResetRequestStatus('success')
  }, [])

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  return (
    <UserDataProvider>
      <Stack direction="column" sx={{ width: '100%' }}>
        <Stack direction="column" sx={{ pl: 6, width: '100%' }}>
          <Header>Account Information</Header>
          <Paper sx={{ width: '100%', height: '100%', minHeight: '221px', position: 'relative' }}>
            {
              loadingUserData
                // @ts-ignore
                ? <CircularProgress
                    sx={{
                      top: '200px',
                      left: 0,
                      right: 0,
                      position: 'absolute',
                      margin: '0 auto'
                    }}
                  />
                : user
                  ? <Stack direction="column">
                      <BloomInput
                        label="Username"
                        disabled
                        handleChange={updateUserDataField}
                        value={user?.userName}
                        name="username"
                        mb
                      />
                      <BloomInput
                        disabled
                        label="Name"
                        handleChange={updateUserDataField}
                        value={user?.name}
                        name="name"
                        mb
                      />
                      <BloomInput
                        disabled
                        label="Surname"
                        handleChange={updateUserDataField}
                        value={user?.surname}
                        name="surname"
                        mb
                      />
                      <BloomInput
                        label="Email"
                        handleChange={updateUserDataField}
                        value={user?.email}
                        name="email"
                        mb
                      />
                      <BloomInput
                        label="Phone number (digits only)"
                        handleChange={updateUserDataField}
                        value={user?.phoneNumber}
                        name="phoneNumber"
                        mb
                      />
                      <Button
                        endIcon={<UpdateIcon />}
                        variant='contained'
                        sx={{ textTransform: 'none', mt: '16px', width: 'fit-content', alignSelf: 'flex-start' }}
                        onClick={saveUserData}
                      >
                        Update account data
                      </Button>
                    </Stack>
                  : null
            }
          </Paper>
        </Stack>
        {!loadingUserData && user
          ? <Stack direction="column" sx={{ mt: 4, pl: 6, width: '100%' }}>
              <Header>Change password</Header>
              <Paper sx={{ width: '100%', height: '100%', minHeight: '221px', position: 'relative' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    control={control}
                    name="currentPassword"
                    defaultValue=""
                    rules={{
                      required: 'Current password is required'
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        sx={{ mt: 2, mb: 1 }}
                        required
                        fullWidth
                        helperText={errors.currentPassword?.message}
                        error={!!error}
                        variant='outlined'
                        placeholder='Your current password'
                        id="currentPassword"
                        disabled={resetRequestStatus === 'loading'}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: <PasswordInputEndAdornment
                              handleClickShowPassword={handleClickShowPassword}
                              showPassword={showPassword}
                            />
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="newPassword"
                    defaultValue=""
                    rules={{
                      required: 'New password is required',
                      minLength: {
                        value: 8,
                        message: 'Minimum length is 8'
                      },
                      pattern: {
                        value: PASS_VALIDATION_PATTERN,
                        message: 'Password has to include small letter, capital letter, digit and special character'
                      }
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        sx={{ mt: 2, mb: 3 }}
                        fullWidth
                        required
                        helperText={errors.newPassword?.message}
                        error={!!error}
                        variant='outlined'
                        placeholder='New password (at least 8 characters, case sensitive)'
                        id="newPassword"
                        disabled={resetRequestStatus === 'loading'}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: <PasswordInputEndAdornment
                              handleClickShowPassword={handleClickShowPassword}
                              showPassword={showPassword}
                            />
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="passwordConfirmation"
                    defaultValue=""
                    rules={{
                      required: 'Password confirmation is required',
                      minLength: {
                        value: 8,
                        message: 'Minimum length is 8'
                      },
                      validate: (val: string) => {
                        if (watch('newPassword') !== val) {
                          return 'Your passwords do no match'
                        }
                      }
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        sx={{ mb: 3 }}
                        fullWidth
                        required
                        variant='outlined'
                        placeholder='Confirm new password'
                        id="passwordConfirmation"
                        helperText={errors.passwordConfirmation?.message}
                        error={!!error}
                        disabled={resetRequestStatus === 'loading'}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: <PasswordInputEndAdornment
                              handleClickShowPassword={handleClickShowPassword}
                              showPassword={showPassword}
                            />
                        }}
                      />
                    )}
                  />
                  {errors.root?.message && <Typography color="error">{errors.root?.message}</Typography>}
                  <Button
                    endIcon={resetRequestStatus === 'loading' ? <RotateRightIcon /> : <SyncLockIcon />}
                    type="submit"
                    fullWidth
                    variant='contained'
                    sx={{ textTransform: 'none', mt: '16px', width: 'fit-content', alignSelf: 'flex-end' }}
                    disabled={resetRequestStatus === 'loading' || !isDirty || !isValid}
                  >
                    {resetRequestStatus === 'loading' ? 'Loading...' : 'Change password'}
                  </Button>
                </form>
            </Paper>
          </Stack>
          : null
        }
      </Stack>
    </UserDataProvider>
  )
}

const WithProvider = () => {
  return (
    <UserDataProvider>
      <MyProfile />
    </UserDataProvider>
  )
}

export default WithProvider
