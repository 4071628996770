import { createMutationHook } from '../../createMutationHook'
import { UnconditionalOfferForm } from '../../types'
import { useDashboardData, useUserDefaultApplicationForm } from '../queries'

interface Payload {
  form: UnconditionalOfferForm
}

export default createMutationHook<Payload, { success: boolean }>({
  httpMethod: 'POST',
  getEndpoint: () => 'app/unconditional-form/submit-form',
  getApiClientConfig: (payload) => {
    return {
      data: payload.form
    }
  },
  getQueryOptions: (queryClient) => ({
    onSuccess: (_data) => {
      queryClient.invalidateQueries(useUserDefaultApplicationForm.getKey(undefined))
      queryClient.invalidateQueries(useDashboardData.getKey(undefined))
    }
  })
})
