import React from 'react'

import { Text, Header, BloomSelect } from '../../../components'
import { useApplicationForm } from '../../../hooks/useApplicationForm'
import { YesNo } from '../../../utils/constants'

function Declaration () {
  const { updateFormField, form, isFormSubmitted } = useApplicationForm()

  if (!form) {
    return null
  }

  return (
    <>
      <Header>Further Information and Documents</Header>
      <Text sx={{ mb: 4 }}>
        Once you have submitted your application, you will be required to upload specified documents.<br/><br/>
        For example, you will be required to submit your qualifications/transcript, English Language Test, passport and photograph.<br/><br/>
        You will receive full details of the documents that we require.
      </Text>
      <Header>Declaration</Header>
      <Text sx={{ mb: 4 }}>
        I certify that all of the information I have submitted as part of this application is truthful, complete and accurate to the best of my knowledge.<br/><br/>
        I accept that, other than in accordance with the Admissions Policy, I cannot complain or appeal against the outcome of a selection decision.<br/><br/>
        I acknowledge that Bloomsbury Institute reserves the right to withdraw any offer made if any of the information provided in or with this form is found to be missing or fraudulent.<br/><br/>
        I authorise Bloomsbury Institute to contact my referee for the purpose of evaluating my application.<br/><br/>
        I authorise Bloomsbury Institute to contact UK Visas and Immigration regarding my immigration history and any other matters.<br/><br/>
        I authorise Bloomsbury Institute (whether directly or through a third party such as Qualification Check) to contact any awarding body or educational provider (e.g. school, college or university)
        to verify that my qualifications and transcripts (including any English Language qualifications) are genuine.  I authorise any awarding body or educational provider to release this verification information to
        Bloomsbury Institute or any third party instructed by Bloomsbury Institute.<br/><br/>
        I authorise Bloomsbury Institute (whether directly or through a third party) to undertake verification checks with regards to any information or documents that are provided as part of my application (e.g. my passport).
        I authorise any body or person to release this verification information to Bloomsbury Institute or any third party instructed by Bloomsbury Institute.<br/><br/>
        Our Privacy Notice concerns the processing of personal data of past, present and prospective students.  The Privacy Notice explains how Bloomsbury Institute collects, uses and shares your
        personal data, and your rights in relation to the personal data we hold. <br/><br/>
        I confirm that I have read and understood the
        <a href="https://www.bil.ac.uk/wp-content/uploads/2022/01/Terms-and-Conditions-012021.pdf" target='_blank' rel="noreferrer">Terms And Conditions</a>{' '},
        <a href="https://www.bil.ac.uk/wp-content/uploads/2022/01/Admissions-Policy.pdf" target='_blank' rel="noreferrer">Admissions Policy</a> and{' '}
        <a href="https://www.bil.ac.uk/wp-content/uploads/2023/03/Privacy-Notice.pdf" target='_blank' rel="noreferrer">Privacy Notice</a>.
      </Text>
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        value={form.declarationConsent}
        labelId="declarationConsent"
        name="declarationConsent"
        label="I certify the above:"
        mb
        options={YesNo}
      />
      <Header>Contact Details and Data Protection</Header>
      <Text>
        If you want to get in touch with us, please contact:<br/><br/>

        <strong>Bloomsbury Institute</strong><br/>
        International Admissions Office<br/>
        7 Bedford Square<br/>
        London WC1B 3RA<br/>
        United Kingdom<br/><br/>

        Tel: + 44 20 3873 9793<br/>
        Email: <a href="mailto:international-office@bil.ac.uk">international-office@bil.ac.uk</a><br/><br/>

        For more information about our courses please visit our website <a href="https://www.bil.ac.uk/" target='_blank' rel="noreferrer">www.bil.ac.uk</a> or contact the
        International Admissions Team by telephoning + 44 20 3873 9793, or emailing: <a href="mailto:international-office@bil.ac.uk">international-office@bil.ac.uk</a>
      </Text>
    </>
  )
}

export default Declaration
