import {
  QueryClient,
  UseMutationOptions,
  useMutation,
  useQueryClient
} from '@tanstack/react-query'
import { AxiosError, AxiosRequestConfig } from 'axios'

import { api } from './api'
import { HttpRequestMethods } from './types'

type CreateMutationHookProps<Props, ReturnType> = {
  getEndpoint: (props: Props) => string;
  getApiClientConfig?: (props: Props) => AxiosRequestConfig;
  getQueryOptions?: (
    queryClient: QueryClient,
  ) => Omit<UseMutationOptions<ReturnType, AxiosError, Props>, 'mutationFn'>;
  httpMethod?: HttpRequestMethods;
  /**
   * Optional function that lets you defined a mock response for unfinished API endpoints
   */
  mockResponse?: ReturnType;
};

export function createMutationHook<
  Props extends void | {},
  ReturnType extends {} | unknown | null = unknown,
> ({
  getEndpoint,
  getApiClientConfig,
  getQueryOptions,
  httpMethod = 'POST',
  mockResponse
}: CreateMutationHookProps<Props, ReturnType>) {
  type QueryOptionsChild = {
    queryOptions?: UseMutationOptions<ReturnType, AxiosError, Props>;
  };

  const apiMethod = httpMethod.toLowerCase() as Lowercase<typeof httpMethod>

  const useHookFn = (props?: QueryOptionsChild | void) => {
    const queryClient = useQueryClient()
    const queryOptions = getQueryOptions?.(queryClient)

    return useMutation({
      mutationFn: async (props: any) => {
        const apiClientConfig = getApiClientConfig?.(props)

        if (mockResponse) {
          return mockResponse
        }

        const response = await api({
          method: apiMethod,
          url: `${getEndpoint(props)}`,
          ...apiClientConfig
        })

        return response.data
      },
      ...queryOptions,
      ...props?.queryOptions
    })
  }

  const getters = {
    getEndpoint
  }

  return Object.assign(useHookFn, getters)
}
