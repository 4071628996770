import React from 'react'

import { Text, Header, BloomInput, BloomSelect } from '../../../components'
import { useApplicationForm } from '../../../hooks/useApplicationForm'
import { MULTISELECT_SEPARATOR, YesNo } from '../../../utils/constants'

const FIELDS_CONFIG_SECTION_NAME = 'Additional Information'
const FORM_SECTION_ID = 'additionalInformation'

function AdditionalInformation () {
  const { getStepConfig, getFieldConfig, form, updateFormField, isFormSubmitted } = useApplicationForm()

  const stepConfig = getStepConfig(FIELDS_CONFIG_SECTION_NAME)

  if (!stepConfig) {
    throw new Error('Form configuration for this step seems to be missing :(')
  }

  const additionalInformation = form && form[FORM_SECTION_ID]

  if (!additionalInformation) {
    return null
  }

  const getFilteredDisabilities = (selected: string[], all: string[]) => {
    const _selected = selected.filter(d => !!d)

    if (_selected.length === 0) {
      return all
    }

    if (_selected.includes(all[0])) {
      return [all[0]]
    } else if (_selected.includes(all[1])) {
      return [all[1]]
    }

    const _all = [...all]

    _all.shift()
    _all.shift()

    return _all
  }

  const allDisabilities = getFieldConfig('disabilities', stepConfig).possibleValues
  const filteredDisabilities = getFilteredDisabilities(additionalInformation.disabilities, allDisabilities)

  return (
    <>
      <Header>Interruption of studies</Header>
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        value={additionalInformation.interruptionOfStudies}
        labelId={`${FORM_SECTION_ID}.interruptionOfStudies`}
        name={`${FORM_SECTION_ID}.interruptionOfStudies`}
        label="Are you aware of anything that could result in you having to interrupt your studies for 60 calendar days or more?"
        mb
        options={YesNo}
      />
      {
        additionalInformation.interruptionOfStudies === YesNo[0]
          ? <>
            <Header>Interruption of Studies - Further Information</Header>
            <BloomInput
                disabled={isFormSubmitted}
                handleChange={updateFormField}
                label="Please provide full information as to why you might have to interrupt your studies for 60 calendar days or more"
                multiline
                value={additionalInformation.interruptionOfStudiesDetails}
                name={`${FORM_SECTION_ID}.interruptionOfStudiesDetails`}
                mb
                required
              />
            </>
          : null
      }
      <Header>Disability</Header>
      <Text sx={{ mb: 4 }}>
        We are fully committed to supporting students with an impairment, long term health condition,
        mental health difficulty or specific learning difficulty.<br/><br/>
        Declaring such a condition does not affect the academic selection process.
        The reason why we ask you is to ensure our Disability and Wellbeing Team can
        provide you with any support that you may require. <br/><br/>
        You can email our Disability and Wellbeing Team if you want to find out more about
        how we can support you, or if you want to have a confidential discussion before or
        after submitting an application: <a href="mailto:disability@bil.ac.uk">disability@bil.ac.uk</a>.
      </Text>
      <BloomSelect
        disabled={isFormSubmitted}
        required
        multiple
        handleSelectChange={(value, name) => { updateFormField(value.split(MULTISELECT_SEPARATOR), name) }}
        value={Array.isArray(additionalInformation.disabilities) ? additionalInformation.disabilities.join(MULTISELECT_SEPARATOR) : additionalInformation.disabilities}
        labelId={`${FORM_SECTION_ID}.disabilities`}
        name={`${FORM_SECTION_ID}.disabilities`}
        label="Do you have an impairment that has a substantial impact on your ability to carry out day-to-day activities? Please select all that apply"
        mb
        options={filteredDisabilities}
      />
      <Header>Tuition Fees and Funding</Header>
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        value={additionalInformation.fundingSource}
        labelId={`${FORM_SECTION_ID}.fundingSource`}
        name={`${FORM_SECTION_ID}.fundingSource`}
        label="How will your tuition fees be paid?"
        mb
        options={getFieldConfig('FundingSource', stepConfig).possibleValues}
      />
      <BloomSelect
        disabled={isFormSubmitted}
        required
        handleSelectChange={updateFormField}
        value={additionalInformation.workingDuringStudies}
        labelId={`${FORM_SECTION_ID}.workingDuringStudies`}
        name={`${FORM_SECTION_ID}.workingDuringStudies`}
        label="Will you be working throughout your studies?"
        mb
        options={YesNo}
      />
    </>
  )
}

export default AdditionalInformation
