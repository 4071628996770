import { Box, Stack, Typography } from '@mui/material'
import { Outlet } from 'react-router-dom'

import { AgentUserPanel } from '../../../components/AgentDashboard'
import { AgentProvider } from '../../../providers'

function Dashboard () {
  return (
    <Box sx={{ width: '100%', pt: 1 }}>
      <Typography
        sx={{
          mb: 2,
          position: 'relative',
          left: '0px',
          top: '0px',
          fontWeight: 700
        }}
        variant="body2"
        color="primary"
      >
        Dashboard
      </Typography>
      <Stack direction="row">
        <AgentUserPanel />
        <AgentProvider>
          <Outlet/>
        </AgentProvider>
      </Stack>
    </Box>
  )
}

export default Dashboard
