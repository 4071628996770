import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import * as React from 'react'

import useFetchDecisionLetter from '../../services/hooks/queries/useFetchDecisionLetter'

interface Props {
  isOpen: boolean
  closeDialog: () => void
  decisionId: number
}

const DecisionDocumentDialog = ({ isOpen, closeDialog, decisionId }: Props) => {
  const { data } = useFetchDecisionLetter({ id: decisionId })

  return (
    <Dialog fullWidth maxWidth='md' open={isOpen} onClose={closeDialog} sx={{ '.MuiPaper-root': { backgroundColor: '#fff' } }}>
      <DialogTitle sx={{ pt: 3, pb: 2 }}>Decision Letter</DialogTitle>
      <DialogContent>
        {data ? <div dangerouslySetInnerHTML={{ __html: data }} /> : <Typography>No content available for this decision identifier</Typography>}
      </DialogContent>
      <DialogActions sx={{ pt: 3, pb: 3 }}>
        <Button variant='contained' onClick={closeDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DecisionDocumentDialog
