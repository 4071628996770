import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const useConfirmationData = () => {
  try {
    const { search } = useLocation()

    const values = search.split('&')

    const userId = values[0].split('userId=')[1]
    const token = values[1].split('token=')[1]

    return useMemo(() => ({ userId, token }), [userId, token])
  } catch (e) {
    return { userId: '', token: '' }
  }
}

export default useConfirmationData
