import { useContext } from 'react'

import { DashboardDataContext } from '../../contexts'

function useDashboardData () {
  const data = useContext(DashboardDataContext)

  return data
}

export default useDashboardData
