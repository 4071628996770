import FolderIcon from '@mui/icons-material/Folder'
import HomeIcon from '@mui/icons-material/Home'
import MrSmiley from '@mui/icons-material/InsertEmoticon'
// import MailIcon from '@mui/icons-material/Mail'
// import NotificationsIcon from '@mui/icons-material/Notifications'
import { Box, Stack, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { PathMatch, useMatch, useNavigate } from 'react-router-dom'

import { useDashboardData } from '../../../hooks'
import { UserDataProvider } from '../../../providers'
import { useMyProfile } from '../../../services/hooks'
import { Paper } from '../../Paper'

const iconStyles = {
  width: '18px',
  height: '18px',
  minWidth: '18px',
  marginRight: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const rowStyles = { mb: 2, alignItems: 'center', cursor: 'pointer' }

const getItemColor = (match: PathMatch | null, activeIf: string | undefined) => {
  const matched = match?.params['*']

  return matched === activeIf ? 'primary' : 'inherit'
}

const UserPanel = () => {
  const { data: profileData } = useMyProfile()
  const { data: dashboardData } = useDashboardData()

  const navigate = useNavigate()
  const match = useMatch('/applicant/dashboard/*')

  const onMenuItemClick = (path: string) => {
    navigate(path)
  }

  return (
    <UserDataProvider>
      <Paper sx={{ minWidth: '223px', width: '223px', height: '500px', mt: 8, justifyContent: 'space-between' }}>
        <Box>
          <Box onClick={() => onMenuItemClick('/applicant/dashboard/my-profile')}>
            <Stack direction="row" sx={rowStyles}>
              <MrSmiley color={getItemColor(match, 'my-profile')} sx={iconStyles}/>
              <Typography color={getItemColor(match, 'my-profile')} variant="body1" fontWeight="bold"
                sx={{
                  position: 'relative',
                  top: '1.4px',
                  width: '180px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                {profileData?.name || ''}{' '}{profileData?.surname || ''}
              </Typography>
            </Stack>
          </Box>
          <Box onClick={() => onMenuItemClick('/applicant/dashboard')}>
            <Stack direction="row" sx={rowStyles}>
              <HomeIcon color={getItemColor(match, '')} sx={iconStyles}/>
              <Typography variant="body1" color={getItemColor(match, '')} fontWeight="bold" sx={{ position: 'relative', top: '1px' }}>
                Home
              </Typography>
            </Stack>
          </Box>
          {/* <Box onClick={() => onMenuItemClick('/applicant/dashboard/messages')}>
            <Stack direction="row" sx={rowStyles}>
              <MailIcon color={getItemColor(match, 'messages')} sx={iconStyles}/>
              <Typography color={getItemColor(match, 'messages')} variant="body1" fontWeight="bold" sx={{ position: 'relative', top: '1.4px' }}>
                Messages
              </Typography>
            </Stack>
          </Box> */}
          <Box onClick={() => onMenuItemClick('/applicant/dashboard/required-documents')}>
            <Stack direction="row" sx={rowStyles}>
              <FolderIcon color={getItemColor(match, 'required-documents')} sx={iconStyles}/>
              <Typography color={getItemColor(match, 'required-documents')} variant="body1" fontWeight="bold" sx={{ position: 'relative', top: '2px' }}>
                Upload Documents
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Box>
          <Stack direction="column" sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ mb: 1, width: '55px', height: '55px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
              <Typography variant="body1" sx={{ lineHeight: '11px', fontSize: '11px', position: 'absolute' }}>{dashboardData?.progress || 0}%</Typography>
              <CircularProgress size={55} color="primary" variant="determinate" value={dashboardData?.progress || 0} />
            </Box>
            <Typography variant="body1" sx={{ width: '100px', textAlign: 'center' }}>Application progress</Typography>
          </Stack>
        </Box>
      </Paper>
    </UserDataProvider>
  )
}

export default UserPanel

// <Box onClick={() => onMenuItemClick('/applicant/dashboard/notifications')}> {/* MOVE ROUTES TO CONSTS and use allover */}
//        <Stack direction="row" sx={rowStyles}>
//        <NotificationsIcon color={getItemColor(match, 'notifications')} sx={iconStyles}/>
//      <Typography color={getItemColor(match, 'notifications')} variant="body1" fontWeight="bold">
//      Notifications
// </Typography>
// </Stack>
// </Box>
