import { createMutationHook } from '../../createMutationHook'
import { ApplicationForm } from '../../types'
import { useDashboardData, useRequiredDocuments, useUserDefaultApplicationForm } from '../queries'

export default createMutationHook<ApplicationForm, { success: boolean }>({
  httpMethod: 'POST',
  getEndpoint: () => 'app/application-forms/submit-application',
  getApiClientConfig: (payload) => {
    return {
      data: payload
    }
  },
  getQueryOptions: (queryClient) => ({
    onSuccess: (_d, payload) => {
      queryClient.invalidateQueries(useUserDefaultApplicationForm.getKey(undefined))
      queryClient.invalidateQueries(useDashboardData.getKey(undefined))
      queryClient.invalidateQueries(useRequiredDocuments.getKey({ applicationId: payload.id.toString() }))
    }
  })
})
