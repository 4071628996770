/* eslint-disable */

import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { BloomInput, BloomSelect, Header, Paper } from '../../components'
import { EMAIL_VALIDATION_PATTERN } from '../../utils/validations'
import { YesNo } from '../../utils/constants'
import { useReferenceForm, useSubmitReferenceForm } from '../../services/hooks'
import { ReferenceForm } from '../../services/types'

const HUNDREDFIFTY_WORDS_VALIDATION_PATTERN = /^\W*(?:\w+\b\W*){150,999}$/

const relationshipOptions = [
  'Manager',
  'Colleague',
  'Teacher',
  'Careers Advisor',
  'Other'
]

const howLongOptions = ['< 1 year', '1-2 years', '> 2 years']

const ReferenceFormComponent = () => {
  const { search } = useLocation()
  const values = search.split('&')

  const referenceId = values[0]?.split('id=')[1] || ''
  const code = values[1]?.split('code=')[1] || ''

  const { mutateAsync: submitReferenceForm  } = useSubmitReferenceForm()

  const { data, refetch, isFetched } = useReferenceForm({ id: referenceId, code })

  const [referenceFormRequestStatus, setReferenceFormRequestStatus] = useState('success')
  const [error, setError] = useState<string>()
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { isDirty, errors, isValid },
    watch
  } = useForm<Omit<ReferenceForm, 'id'>>({ mode: 'onChange', defaultValues: data })

  useEffect(() => {
    if (referenceId !== undefined && code !== undefined) {
      refetch()
    }
  }, [referenceId, code])

  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data])

  const relationship = watch('relationship')
  const isApplicantSuitable = watch('suitableForStudy')

  const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues, e) => {
    try {
      const typed = data as Omit<ReferenceForm, 'id'>

      e?.preventDefault()

      setReferenceFormRequestStatus('loading')

      // clean so BE doesn't throw
      if (typed.suitableForStudy === 'Yes') {
        typed.notSuitableForStudyDetails = ''
      } else {
        typed.otherComments = ''
      }

      await submitReferenceForm({ form: { id: parseInt(referenceId, 10), ...typed }, code })

      setReferenceFormRequestStatus('success')
      navigate('/login')
    } catch (e) {
      setReferenceFormRequestStatus('failed')
      setError('Reference form submission failed. Try again or contact administration.')
    }
  }

  useEffect(() => {
    if (isFetched && !data) {
      navigate('/login')
    }
  }, [isFetched, data])

  return (
      <Box sx={{ width: '100%', pt: 1 }}>
        <Typography
          sx={{
            mb: 2,
            position: 'relative',
            left: '0px',
            top: '0px',
            fontWeight: 700
          }}
          variant="body2"
          color="primary"
        >
          Reference Form
        </Typography>
        <Stack direction="row">
          <Stack direction="column" sx={{ flex: 1 }}>
            <Box sx={{ display: 'flex', flex: 1 }}>
              <Paper sx={{ mt: 4, width: '100%', position: 'relative' }}>
                {!data ? <CircularProgress
                    sx={{
                      top: '200px',
                      left: 0,
                      right: 0,
                      margin: '0 auto'
                    }}
                  /> : <form onSubmit={handleSubmit(onSubmit)}>
                  <Header sx={{ mt: 2 }}>Reference Information</Header>
                  <Controller
                    defaultValue={data?.firstName || ""}
                    control={control}
                    name="firstName"
                    rules={{
                      required: 'First name is required',
                      minLength: {
                        value: 3,
                        message: 'Minimum length is 3'
                      },
                      maxLength: {
                        value: 100,
                        message: 'Max length is 100'
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <BloomInput
                        required
                        maxLength={100}
                        handleChange={field.onChange}
                        label='First Name'
                        value={field.value}
                        name="firstName"
                        error={error || errors.firstName}
                        mb
                      />
                    )}
                  />
                  <Controller
                    defaultValue={data?.lastName || ""}
                    control={control}
                    name="lastName"
                    rules={{
                      required: 'Last name is required',
                      minLength: {
                        value: 3,
                        message: 'Minimum length is 3'
                      },
                      maxLength: {
                        value: 100,
                        message: 'Max length is 100'
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <BloomInput
                        required
                        maxLength={100}
                        handleChange={field.onChange}
                        label='Last Name'
                        value={field.value}
                        name="lastName"
                        error={error || errors.lastName}
                        mb
                      />
                    )}
                  />
                  <Controller
                    defaultValue={data?.email || ""}
                    control={control}
                    name="email"
                    rules={{
                      required: 'Email is required',
                      minLength: {
                        value: 3,
                        message: 'Minimum length is 3'
                      },
                      maxLength: {
                        value: 100,
                        message: 'Max length is 100'
                      },
                      pattern: {
                        value: EMAIL_VALIDATION_PATTERN,
                        message: 'Entered value does not match email format'
                      }
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <BloomInput
                        required
                        maxLength={100}
                        handleChange={field.onChange}
                        label='Email'
                        value={field.value}
                        name="email"
                        error={error || errors.email}
                        mb
                      />
                    )}
                  />
                  <Controller
                    defaultValue={data?.relationship || ""}
                    control={control}
                    name="relationship"
                    rules={{
                      required: 'Relationship is required',
                      minLength: {
                        value: 3,
                        message: 'Minimum length is 3'
                      },
                      maxLength: {
                        value: 100,
                        message: 'Max length is 100'
                      },
                    }}
                    render={({ field }) => (
                      <BloomSelect
                        required
                        handleSelectChange={field.onChange}
                        value={field.value}
                        labelId="relationship"
                        name="relationship"
                        label="Relationship to Applicant"
                        mb
                        options={relationshipOptions}
                      />
                    )}
                  />
                  {
                    relationship === "Other" && <>
                      <Controller
                        defaultValue={data?.relationshipDetails || ""}
                        control={control}
                        name="relationshipDetails"
                        rules={{
                          required: 'Relationship details is required',
                          maxLength: {
                            value: 500,
                            message: 'Max length is 500'
                          },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <BloomInput
                            required
                            maxLength={500}
                            handleChange={field.onChange}
                            label='Please state your relationship to the applicant.'
                            value={field.value}
                            name="relationship"
                            error={error || errors.relationship}
                            mb
                            multiline
                          />
                        )}
                      />
                    </>
                  }
                  <Header sx={{ mt: 2 }}>Your Organisation Details</Header>
                  <Controller
                    defaultValue={data?.organisationDetails || ""}
                    control={control}
                    name="organisationDetails"
                    rules={{
                      required: 'Organization details is required',
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <BloomInput
                        required
                        handleChange={field.onChange}
                        helperText="Please state below the name and address of your organisation (e.g. College or Company)"
                        label='Your organisation details'
                        value={field.value}
                        name="organisationDetails"
                        error={error || errors.organisationDetails}
                        mb
                        multiline
                      />
                    )}
                  />
                  <Controller
                    defaultValue={data?.occupation || ""}
                    control={control}
                    name="occupation"
                    rules={{
                      required: 'Position details is required',
                      minLength: {
                        value: 3,
                        message: 'Minimum length is 3'
                      },
                      maxLength: {
                        value: 500,
                        message: 'Max length is 500'
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <BloomInput
                        required
                        maxLength={500}
                        handleChange={field.onChange}
                        label='Your position'
                        helperText='Please state below your position in the above organisation'
                        value={field.value}
                        name="occupation"
                        error={error || errors.occupation}
                        mb
                      />
                    )}
                  />
                  <Controller
                    defaultValue={data?.qualifications || ""}
                    control={control}
                    name="qualifications"
                    rules={{
                      minLength: {
                        value: 3,
                        message: 'Minimum length is 3'
                      },
                      maxLength: {
                        value: 500,
                        message: 'Max length is 100'
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <BloomInput
                        maxLength={500}
                        handleChange={field.onChange}
                        label='Your academic and/or professional qualifications'
                        helperText='If applicable, please state below your academic and/or professional qualifications'
                        value={field.value}
                        name="qualifications"
                        error={error || errors.qualifications}
                        mb
                      />
                    )}
                  />
                  <Header sx={{ mt: 2 }}>Recommendation</Header>
                  <Controller
                    defaultValue={data?.howLongYouKnowApplicant || ""}
                    control={control}
                    name="howLongYouKnowApplicant"
                    rules={{
                      required: 'Value is required',
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <BloomSelect
                        required
                        handleSelectChange={field.onChange}
                        value={field.value}
                        labelId="howLongYouKnowApplicant"
                        name="howLongYouKnowApplicant"
                        label="How long have you known the applicant?"
                        mb
                        options={howLongOptions}
                      />
                    )}
                  />
                  <Controller
                    defaultValue={data?.suitableForStudy || ""}
                    control={control}
                    name="suitableForStudy"
                    rules={{
                      required: 'This value is required',
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <BloomSelect
                        required
                        handleSelectChange={field.onChange}
                        value={field.value}
                        labelId="suitableForStudy"
                        name="suitableForStudy"
                        label="Do you consider the applicant to be suitable for study at Bloomsbury Institute?"
                        mb
                        options={YesNo}
                      />
                    )}
                  />
                  { !!isApplicantSuitable && (isApplicantSuitable === 'Yes'
                    ? <Controller
                        defaultValue={data?.otherComments || ""}
                        control={control}
                        name="otherComments"
                        rules={{
                          required: 'Comment is required',
                          pattern: {
                            value: HUNDREDFIFTY_WORDS_VALIDATION_PATTERN,
                            message: 'Entered value has less than 150 words'
                          },
                          maxLength: {
                            value: 2000,
                            message: 'Max length is 2000'
                          },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <BloomInput
                            required
                            handleChange={field.onChange}
                            label='Why do you feel that the applicant would be suitable for higher education study?'
                            helperText='Please write a minimum of 150 words'
                            value={field.value}
                            name="otherComments"
                            error={error || errors.otherComments}
                            mb
                            multiline
                          />
                        )}
                      />
                    : <Controller
                        defaultValue={data?.notSuitableForStudyDetails || ""}
                        control={control}
                        name="notSuitableForStudyDetails"
                        rules={{
                          minLength: {
                            value: 50,
                            message: 'Minimum length is 50'
                          },
                          maxLength: {
                            value: 500,
                            message: 'Max length is 500'
                          },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <BloomInput
                            handleChange={field.onChange}
                            label='If you stated that the applicant is not suitable for study, please indicate why.'
                            value={field.value}
                            name="notSuitableForStudyDetails"
                            error={error || errors.notSuitableForStudyDetails}
                            mb
                            multiline
                          />
                        )}
                      />
                  )}
                  <Button
                    type="submit"
                    variant='contained'
                    sx={{ textTransform: 'none', mt: '16px', width: "fit-content" }}
                    disabled={referenceFormRequestStatus === 'loading' || !isDirty || !isValid}
                  >
                    {referenceFormRequestStatus === 'loading' ? 'Loading...' : 'Submit form'}
                  </Button>
                </form>
              }
              </Paper>
            </Box>
          </Stack>
        </Stack>
      </Box>
  )
}

export default ReferenceFormComponent
