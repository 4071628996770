import UpdateIcon from '@mui/icons-material/Update'
import { Button, CircularProgress, Stack } from '@mui/material'

import { BloomInput, Header, Paper } from '../../../components'
import { useCasSettings } from '../../../hooks/useCasSettings'
import { CasSettingsProvider } from '../../../providers'

const CasSettings = () => {
  const { casSettings, loadingCasSettings, updateCasSettingsField, saveCasSettings } = useCasSettings()

  return (
    <CasSettingsProvider>
      <Stack direction="column" sx={{ width: '100%' }}>
        <Stack direction="column" sx={{ pl: 6, width: '100%' }}>
          <Header>CAS Settings</Header>
          <Paper sx={{ width: '100%', height: '100%', minHeight: '221px', position: 'relative' }}>
            {
              loadingCasSettings
                // @ts-ignore
                ? <CircularProgress
                    sx={{
                      top: '200px',
                      left: 0,
                      right: 0,
                      position: 'absolute',
                      margin: '0 auto'
                    }}
                  />
                : casSettings
                  ? <Stack direction="column">
                      <BloomInput
                        label="Application Status"
                        handleChange={updateCasSettingsField}
                        value={casSettings?.applicationStatus}
                        name="applicationStatus"
                        mb
                      />
                      <BloomInput
                        label="Start Date"
                        handleChange={updateCasSettingsField}
                        value={casSettings?.startDate}
                        name="startDate"
                        mb
                      />
                      <BloomInput
                        label="Decision Status"
                        handleChange={updateCasSettingsField}
                        value={casSettings?.decisionStatus}
                        name="decisionStatus"
                        mb
                      />
                      <BloomInput
                        label="Publish Status"
                        handleChange={updateCasSettingsField}
                        value={casSettings?.publishStatus}
                        name="publishStatus"
                        mb
                      />
                      <Button
                        endIcon={<UpdateIcon />}
                        variant='contained'
                        sx={{ textTransform: 'none', mt: '16px', width: 'fit-content', alignSelf: 'flex-start' }}
                        onClick={saveCasSettings}
                      >
                        Update CAS settings
                      </Button>
                    </Stack>
                  : null
            }
          </Paper>
        </Stack>
      </Stack>
    </CasSettingsProvider>
  )
}

const WithProvider = () => {
  return (
    <CasSettingsProvider>
      <CasSettings />
    </CasSettingsProvider>
  )
}

export default WithProvider
